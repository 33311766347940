<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import productivityChart from "@/components/Charts/productivityChart.js";
import { formatDate } from "@/utils/formatDate";
import LineChart from "@/components/Charts/LineChart.vue";
import * as XLSX from "xlsx";

const { chartOptions, icons } = productivityChart;

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const productivity = ref({});
const predictionDate = ref();
const lastLabelDate = ref();
const isLoading = ref(true);

const clickedChartButton = computed(
  () => store.getters["clickedButton/getClickedChartButton"],
);

const data = {
  0: "productchartList",
  1: "ttkchartList",
  2: "packagechartList",
  3: "materialchartList",
};

const dateData = {
  0: "productpredictiondays",
  1: "ttkpredictiondays",
  2: "packagepredictiondays",
  3: "materialpredictiondays",
};

const chartData = ref({
  labels: [],
  datasets: [],
});

const updateChartData = async () => {
  const labels = productivity.value[data[clickedChartButton.value]].map((e) =>
    formatDate(e.date.seconds),
  );
  const plan = productivity.value[data[clickedChartButton.value]].map(
    (e) => e.plan,
  );
  const fact = productivity.value[data[clickedChartButton.value]].map(
    (e) => e.fact,
  );
  const deviation = productivity.value[data[clickedChartButton.value]].map(
    (e) => e.deviation,
  );
  const maxValue = Math.max(
    Math.max(...plan),
    Math.max(...fact),
    Math.max(...deviation),
  );

  chartData.value = {
    labels: [...new Set(labels)],
    datasets: [
      {
        label: "Plan",
        data: plan,
        borderColor: "#0093E5",
        backgroundColor: "#0093E5",
        pointBorderColor: "#0093E5",
        pointBackgroundColor: "#0093E5",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Fact",
        data: fact,
        borderColor: "#FFB800",
        backgroundColor: "#FFB800",
        pointBorderColor: "#FFB800",
        pointBackgroundColor: "#FFB800",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Fact",
        data: deviation,
        borderColor: "#FF0000",
        backgroundColor: "#FF0000",
        pointBorderColor: "#FF0000",
        pointBackgroundColor: "#FF0000",
        fill: false,
        tension: 0.1,
      },
    ],
  };
  chartOptions.scales.y.suggestedMax = maxValue * 1.1;
  lastLabelDate.value = labels[labels.length - 1];
  predictionDate.value = productivity.value[dateData[clickedChartButton.value]];
  await store.dispatch(
    "appData/updateProductionPredictionDate",
    predictionDate.value,
  );
  await store.dispatch(
    "appData/updateProductionLastLabelDate",
    lastLabelDate.value,
  );
};

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const getProductionCollapseData = await new Promise((resolve, reject) => {
      client.getProductionCollapse(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });
    // console.log(getProductionCollapseData);
    productivity.value = getProductionCollapseData;
    isLoading.value = false;
    updateChartData();
  } catch (error) {
    console.error(error);
    // throw error;
  }
  isLoading.value = false;
};
onMounted(() => {
  fetchDataFromGrpc();
});

watch(clickedChartButton, updateChartData);

const exportChartDataToExcel = () => {
  const chartDataMap = {
    0: productivity.value.productchartList,
    1: productivity.value.ttkchartList,
    2: productivity.value.packagechartList,
    3: productivity.value.materialchartList,
  };

  // Get the corresponding data based on clickedChartButton
  const chartList = chartDataMap[clickedChartButton.value];

  const chartDataHeader = ["Продукт", "ТТК", "Тара", "Материал"];

  // Get the corresponding data based on clickedChartButton
  const charHeader = chartDataHeader[clickedChartButton.value];

  if (!chartList || chartList.length === 0) {
    console.error("No chart data available for export.");
    return;
  }

  // Define headers
  const headersTitle = ["", "", charHeader, ""];

  // Define headers
  const headers = ["Date", "Plan", "Fact", "Deviation"];

  // Prepare rows with data transformation
  const rows = chartList.map((item) => {
    return [
      item.date && item.date.seconds
        ? new Date(item.date.seconds * 1000).toLocaleDateString("en-GB")
        : "",
      item.plan ?? "",
      item.fact ?? "",
      item.deviation ?? "",
    ];
  });

  // Combine headers and data rows
  const worksheetData = [headersTitle, headers, ...rows];

  // Create worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Style the headers
  headers.forEach((_, colIdx) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIdx });
    if (!worksheet[cellAddress]) return;
    worksheet[cellAddress].s = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "D9EAD3" } },
    };
  });

  // Set column widths
  worksheet["!cols"] = headers.map(() => ({ wpx: 150 }));

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Отчет");

  // Generate filename and save
  const date = new Date();
  const dateString = date.toLocaleDateString("en-GB").replace(/\//g, "_");
  const fileName = `${dateString}_мултикейс_${charHeader}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};
</script>

<template>
  <v-container fluid class="pa-0 mb-5">
    <!-- TODO data -->
    <v-row align="center" class="mx-auto" style="width: 50%">
      <v-col v-if="isLoading" cols="7">
        <v-card-text align="center" class="date">К ДАТЕ: </v-card-text>
      </v-col>
      <v-col v-else cols="7">
        <v-card-text align="center" class="date"
          >К ДАТЕ: {{ lastLabelDate }}</v-card-text
        >
      </v-col>
      <v-col v-if="!isLoading" cols="5">
        <v-card class="red-card" width="199">
          <span class="red-card-text">{{ predictionDate }} дн.</span>
          <span class="legend-text ml-3">ПРОГНОЗ</span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <LineChart
    class="chart"
    height="55"
    :chartData="chartData"
    :chartOptions="chartOptions"
    :icons="icons"
    :isLoading="isLoading"
  />

  <v-row class="mt-5" style="width: 100%">
    <v-col></v-col>
    <v-col>
      <v-row align="center">
        <v-col cols="3">
          <v-img src="@/assets/icons/blue_line.svg" height="7" />
        </v-col>
        <v-col class="px-0">
          <v-card-text
            align="left"
            style="font-size: 18px; color: #0093e5"
            class="px-0"
          >
            ПЛАН
          </v-card-text>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row align="center">
        <v-col cols="3">
          <v-img src="@/assets/icons/orange_line.svg" height="7" />
        </v-col>
        <v-col class="px-0">
          <v-card-text
            align="left"
            style="font-size: 18px; color: #ffb800"
            class="px-0"
          >
            ФАКТ
          </v-card-text>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row align="center">
        <v-col cols="3">
          <v-img src="@/assets/icons/red_line.svg" height="7" />
        </v-col>
        <v-col class="px-0">
          <v-card-text
            align="left"
            style="font-size: 18px; color: #ff0000"
            class="px-0"
          >
            ОТКЛОНЕНИЕ
          </v-card-text>
        </v-col>
      </v-row>
    </v-col>
    <v-col align="end">
      <v-btn
        @click="exportChartDataToExcel"
        rounded="lg"
        height="3vw"
        style="font-size: 0.8vw"
      >
        ЭКСПОРТ</v-btn
      >
    </v-col>
  </v-row>
</template>

<style scoped>
.date {
  font-size: 1.5vw;
  color: #00a3ff;
  font-weight: bold;
  padding: 0;
}
.title {
  color: white;
  background: linear-gradient(#006aa6 0%, #002940);
  font-size: 1.5vw;
  border-radius: 8px;
}
.legend-text {
  font-size: 0.7vw;
}
.red-card {
  background-color: red;
}
.red-card-text {
  font-size: 1.6vw;
}
@media (max-height: 760px) {
  .chart {
    height: 50vh;
  }
}
</style>
