/**
 * @fileoverview gRPC-Web generated client stub for ASIC.Contracts.ServiceContracts.MulticaseLayer
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.0-rc1
// source: IMultiStatisticsService-v5.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.ASIC = {};
proto.ASIC.Contracts = {};
proto.ASIC.Contracts.ServiceContracts = {};
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer = require('./IMultiStatisticsService-v5_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult>}
 */
const methodDescriptor_MultiStatisticsService_GetContractorsDynamics = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetContractorsDynamics',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getContractorsDynamics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetContractorsDynamics',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetContractorsDynamics,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getContractorsDynamics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetContractorsDynamics',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetContractorsDynamics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult>}
 */
const methodDescriptor_MultiStatisticsService_GetDetailedStructureDeviations = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDetailedStructureDeviations',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getDetailedStructureDeviations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDetailedStructureDeviations',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetDetailedStructureDeviations,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getDetailedStructureDeviations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDetailedStructureDeviations',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetDetailedStructureDeviations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult>}
 */
const methodDescriptor_MultiStatisticsService_GetDetailedStructureExecution = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDetailedStructureExecution',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getDetailedStructureExecution =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDetailedStructureExecution',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetDetailedStructureExecution,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getDetailedStructureExecution =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDetailedStructureExecution',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetDetailedStructureExecution);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult>}
 */
const methodDescriptor_MultiStatisticsService_GetDeviationsDynamics = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDeviationsDynamics',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getDeviationsDynamics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDeviationsDynamics',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetDeviationsDynamics,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getDeviationsDynamics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetDeviationsDynamics',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetDeviationsDynamics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult>}
 */
const methodDescriptor_MultiStatisticsService_GetGprDynamics = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetGprDynamics',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getGprDynamics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetGprDynamics',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetGprDynamics,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getGprDynamics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetGprDynamics',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetGprDynamics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult>}
 */
const methodDescriptor_MultiStatisticsService_GetProductionCollapse = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetProductionCollapse',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getProductionCollapse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetProductionCollapse',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetProductionCollapse,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getProductionCollapse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetProductionCollapse',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetProductionCollapse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult>}
 */
const methodDescriptor_MultiStatisticsService_GetStructuresExecutionTree = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetStructuresExecutionTree',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getStructuresExecutionTree =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetStructuresExecutionTree',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetStructuresExecutionTree,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getStructuresExecutionTree =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetStructuresExecutionTree',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetStructuresExecutionTree);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult>}
 */
const methodDescriptor_MultiStatisticsService_GetTopDeviations = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetTopDeviations',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServiceClient.prototype.getTopDeviations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetTopDeviations',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetTopDeviations,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsServicePromiseClient.prototype.getTopDeviations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiStatisticsService/GetTopDeviations',
      request,
      metadata || {},
      methodDescriptor_MultiStatisticsService_GetTopDeviations);
};


module.exports = proto.ASIC.Contracts.ServiceContracts.MulticaseLayer;

