<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { formatDate } from "@/utils/formatDate";
import LocationsProgress from "../data/LocationsProgress.vue";
import CallendarProgress from "../data/CalendarBar.vue";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});

const router = useRouter();

function isFuture(timestampInSeconds) {
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

  return timestampInSeconds > currentTimestampInSeconds;
}

// Computed property to determine text highlight class
const text_highlight = computed(() => {
  if (props.data.isfactadded) {
    return "text_highlight_green";
  } else {
    return "text_highlight_info";
  }
});

function goToDetails(id) {
  router.push({ name: "PropertyDetails", params: { id } });
}

const generateItemImage = (multiply, itemSrc) => {
  const base = 280;
  return itemSrc + `/S-${base * multiply}`;
};
</script>

<template>
  <v-card
    :loading="loading"
    v-bind="props"
    @click="goToDetails(props.data.id)"
    class="param-card card_border pa-3"
    color="card_background"
    max-width="100%"
    min-height="300px"
    max-height="380px"
  >
    <v-card-item class="mb-0 mt-0 marquee-container">
      <div :class="text_highlight" class="d-flex">
        <span
          class="text-white text_title text-center marquee pr-7"
          :style="{
            whiteSpace: 'nowrap',
          }"
        >
          {{ props.data.name }}
        </span>
        <span
          class="text-white text_title text-center marquee pr-7"
          :style="{
            whiteSpace: 'nowrap',
          }"
        >
          {{ props.data.name }}
        </span>
      </div>
    </v-card-item>

    <!-- <v-card-item class=""> -->
    <v-row class="pa-4 fill-height">
      <v-col cols="6" class="d-flex align-top">
        <v-img
          :src="generateItemImage(1.5, props.data.imagesource)"
          cover
          aspect-ratio="1"
          class="mb-0"
          style="height: 280px; width: 100%; border-radius: 10px"
          rounded="10"
        ></v-img>
      </v-col>

      <v-col cols="6">
        <v-row class="fill-height">
          <v-col class="scroll_data">
            <div
              v-for="item in props.data.sectionsplanfactList"
              :key="item.name"
            >
              <LocationsProgress
                v-if="!isFuture(item.startdate.seconds)"
                :title="item.name"
                :percentCalendar="item.factpercentage"
                :percentConstruction="item.planpercentage"
                :percentBarrier="item.deviationpercentage"
              />
              <CallendarProgress
                v-else
                :key="item.id"
                :title="item.name"
                :date="formatDate(item.startdate.seconds)"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  <!-- </v-card-item> -->
  </v-card>
</template>

<style scoped>
.fill-height {
  height: 100%;
}

.param-card {
  box-shadow: 0px 0px 23px 1px #00a3ff80 inset;
  transition: box-shadow 0.3s ease;
}

.card_border {
  border: 1px solid #00a3ff;
}

.hovered-card {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Custom hover shadow */
  background-color: #f0f0f0; /* Custom hover background color */
}

/* Ensure the card fills 100% width */
.v-card {
  width: 100% !important;
}

.text_title {
  /* font-family: Neo Sans Pro; */
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
}
.text_dimension {
  /* font-family: Neo Sans Pro; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}
.counter {
  margin-right: 1rem;
  background-color: #00466e;
  padding: 0px;
  height: 100%;
  width: 13px !important;
}
.counter_text {
  /* font-family: Neo Sans Pro; */
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
}

.text-green {
  color: #1bc200; /* Green */
  font-size: 1rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: right;
}

.text-yellow {
  color: #ffb800; /* Yellow */
  font-size: 1rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: right;
}

.text-red {
  color: #ff0000; /* Red */
  font-size: 1rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: right;
}

.align-right {
  width: 60px; /* Adjust width as needed for alignment */
  text-align: right;
}
.pill-progress {
  border-radius: 5px; /* To make it pill-shaped */
  overflow: hidden; /* Ensure the border-radius is applied to the inner bar */
}
.text_highlight_green {
  background: radial-gradient(
    rgba(27, 194, 0, 1) 0%,
    rgba(27, 194, 0, 0.1) 71%,
    rgba(0, 24, 37, 0) 100%
  );
  padding: 0.5rem; /* Add some padding to prevent the text from touching the edges */
  border-radius: 5px; /* Optional: Add border radius if you want rounded corners */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
  max-width: 100%; /* Ensure it doesn't overflow the card */
  word-wrap: break-word; /* Ensure long words break to the next line */
}

.text_highlight_red {
  background: radial-gradient(
    #ff0000 0%,
    rgba(255, 0, 0, 0.1) 71%,
    rgba(0, 24, 37, 0) 100%
  );
  padding: 0.5rem; /* Add some padding to prevent the text from touching the edges */
  border-radius: 5px; /* Optional: Add border radius if you want rounded corners */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
  max-width: 100%; /* Ensure it doesn't overflow the card */
  word-wrap: break-word; /* Ensure long words break to the next line */
}

.text_highlight_info {
  background: radial-gradient(
    #757575 0%,
    #7575750a 71%,
    rgba(0, 24, 37, 0.3) 100%
  );
  padding: 0.5rem; /* Add some padding to prevent the text from touching the edges */
  border-radius: 5px; /* Optional: Add border radius if you want rounded corners */
  box-sizing: border-box; /* Ensure padding is included in the width/height */
  max-width: 100%; /* Ensure it doesn't overflow the card */
  word-wrap: break-word; /* Ensure long words break to the next line */
}

.scroll_data {
  overflow-y: auto; /* Show scrollbar only when content overflows */
  max-height: 100%; /* Set a max height for the container */
}
/* For WebKit browsers (Chrome, Safari, Edge) */
.scroll_data::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.scroll_data::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color (the background) */
  border-radius: 5px; /* Rounds the track */
}

.scroll_data::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 5px; /* Makes the scrollbar thumb rounded */
}

.scroll_data::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the scrollbar */
}
</style>

<style scoped>
.fill-height {
  height: 100%;
}

.scroll_data {
  overflow-y: auto; /* Show scrollbar only when content overflows */
  max-height: 300px; /* Set a max height for the container */
  padding-right: 10px; /* Add padding to avoid content being cut off by scrollbar */
}

/* width */
.scroll_data::-webkit-scrollbar {
  width: 12px;
}
/* Track */
.scroll_data::-webkit-scrollbar-track {
  background-color: #00466f;
  border-radius: 5px;
  margin: 15px;
}
/* Handle */
.scroll_data::-webkit-scrollbar-thumb {
  background: #00a3ff;
  border-radius: 10px;
  border: 4px solid #00466f;
}
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}
.marquee {
  display: inline-block; /* Allow horizontal scrolling */
  animation: marquee 15s linear infinite; /* Adjust duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start position */
  }
  100% {
    transform: translateX(-100%); /* End of scroll */
  }
}
</style>
