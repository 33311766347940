<template>
  <v-container>
    <v-row fluid align="center" justify="center">
      <v-col cols="12" sm="12" md="12" lg="9" xl="6" xxl="4">
        <v-img
          lazy-src="@/assets/bg/bg-2.0.png"
          src="@/assets/bg/bg-2.0.png"
          aspect-ratio="1/1"
          class="d-none d-md-flex align-center justify-center img-shadow"
          :style="borderImgColor"
          contain
        >
          <v-fade-transition>
            <v-card
              :loading="loading"
              max-width="50%"
              max-xl-width="70%"
              class="mx-auto pa-lg-8 pa-md-4"
              elevation="0"
              color="transparent"
            >
              <template v-slot:loader="{ isActive }">
                <v-progress-linear
                  :active="isActive"
                  color="white"
                  height="0"
                  indeterminate
                ></v-progress-linear>
              </template>

              <form @submit.prevent="loginUser" v-show="renderForm">
                <v-img
                  contain
                  height="77"
                  class="mb-12 mt-4"
                  src="@/assets/logo/logo-asik.svg"
                ></v-img>

                <v-card-item class="mb-8">
                  <v-card-title
                    class="text-primary_light text_title text-center"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Auth_form_welcome_title
                    }}
                  </v-card-title>
                  <v-card-subtitle
                    class="mt-1 text_subtitle text-center"
                    style="font-size: 14px"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Auth_form_welcome_subtitle
                    }}
                  </v-card-subtitle>
                </v-card-item>

                <v-card-item>
                  <!-- Login -->
                  <v-text-field
                    :readonly="loading"
                    :loading="loading"
                    v-model="email.value.value"
                    :label="
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Auth_form_email_label
                    "
                    :placeholder="
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Auth_form_email_placeholder
                    "
                    clearable
                    bg-color="form_background"
                    base-color="primary"
                    :color="colorElement"
                    variant="solo"
                    rounded="10"
                    density="compact"
                  >
                    <template v-slot:prepend-inner>
                      <v-fade-transition duration="500" mode="out-in">
                        <v-img
                          v-if="!alarm_status"
                          src="@/assets/icons/login.svg"
                          contain
                          width="24"
                          height="24"
                        />
                        <v-img
                          v-else
                          src="@/assets/icons/login_failed.svg"
                          contain
                          width="24"
                          height="24"
                        />
                      </v-fade-transition>
                    </template>
                  </v-text-field>
                  <!-- Password -->
                  <v-text-field
                    :readonly="loading"
                    :loading="loading"
                    v-model="password.value.value"
                    :error-messages="password.errorMessage.value"
                    :label="
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Auth_form_password_label
                    "
                    :placeholder="
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Auth_form_password_placeholder
                    "
                    clearable
                    :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append-inner="show1 = !show1"
                    bg-color="form_background"
                    base-color="primary"
                    :color="colorElement"
                    variant="solo"
                    rounded="10"
                    density="compact"
                  >
                    <template v-slot:prepend-inner>
                      <v-fade-transition duration="500" mode="out-in">
                        <v-img
                          v-if="!alarm_status"
                          src="@/assets/icons/password.svg"
                          contain
                          width="24"
                          height="24"
                        />
                        <v-img
                          v-else
                          src="@/assets/icons/password_failed.svg"
                          contain
                          width="24"
                          height="24"
                        />
                      </v-fade-transition>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-if="serverMode"
                    class="mb-2"
                    :loading="loading"
                    append-inner-icon="mdi-arrow-right"
                    density="compact"
                    label="change_system_server"
                    v-model="jsonData.serverUrl"
                    hide-details
                    single-line
                    @click:append-inner="updateServer"
                  ></v-text-field>

                  <v-container class="ma-0 pa-0">
                    <!-- Login -->
                    <v-row>
                      <v-col>
                        <v-btn
                          :loading="loading"
                          rounded="10"
                          type="submit"
                          color="primary"
                          :class="btn_gradient_control"
                          block
                        >
                          {{ loginLabel }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Recover User -->
                    <v-row class="mt-0">
                      <v-col>
                        <v-btn
                          :loading="loading"
                          rounded="10"
                          size="small"
                          color="secondary"
                          variant="text"
                          to="/recover"
                          block
                          style="font-size: 10px"
                        >
                          {{
                            $vuetify.locale.messages[$vuetify.locale.current]
                              .Auth_form_button_recovery
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-item>

                <!-- Possible reimplement of show-hide
                                 :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append-inner="show1 = !show1" -->

                <!-- Alerts -->
                <!-- <v-fade-transition>
              <v-card-item v-show='alarm_status'>
                <v-alert :type="alarm_type"> 
                  {{ alarm_message }}
                </v-alert>
              </v-card-item>
            </v-fade-transition> -->
              </form>

              <!-- Outdated DB -->
              <div v-show="renderInvalidDB">
                <v-img
                  contain
                  height="100"
                  src="@/assets/icons/critical_warning.svg"
                  class="mb-8"
                ></v-img>

                <v-card-item class="mb-8">
                  <v-card-title
                    class="text-primary_light responsive-txt text_title text-center"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .DB_Outdated
                    }}
                  </v-card-title>
                  <v-card-subtitle
                    class="mt-2 responsive-txt text_subtitle text-center"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .DB_ConfirmUpdate
                    }}
                  </v-card-subtitle>
                </v-card-item>

                <v-card-item class="mb-2">
                  <v-row class="mt-0">
                    <v-col>
                      <v-btn
                        :loading="loading"
                        rounded="10"
                        type="submit"
                        color="primary btn_gradient_update"
                        block
                        @click="updateDB()"
                      >
                        {{
                          $vuetify.locale.messages[$vuetify.locale.current]
                            .DB_Confirm_Yes
                        }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        :loading="loading"
                        rounded="10"
                        type="submit"
                        color="primary btn_gradient_update"
                        block
                        @click="goHome()"
                      >
                        {{
                          $vuetify.locale.messages[$vuetify.locale.current]
                            .DB_Confirm_No
                        }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-item>
              </div>

              <!-- Updated DB -->
              <div v-show="renderUpdatedDB">
                <v-img
                  contain
                  height="100"
                  src="@/assets/icons/success.svg"
                  class="mb-8"
                ></v-img>
                <v-card-item class="mb-8">
                  <v-card-title
                    class="mb-4 text-h4 text-center responsive-txt text-shadow text-normal"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Updated_DB
                    }}
                  </v-card-title>
                </v-card-item>
              </div>

              <!-- Valid DB -->
              <div v-show="renderValidDB">
                <v-img
                  contain
                  height="100"
                  src="@/assets/icons/success.svg"
                  class="mb-8"
                ></v-img>
                <v-card-item class="mb-8">
                  <v-card-title
                    class="mb-4 text-h4 text-center responsive-txt text-shadow text-normal"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Valid_DB_toDate
                    }}
                  </v-card-title>
                </v-card-item>
              </div>
            </v-card>
          </v-fade-transition>
        </v-img>

        <!-- Mobile -->
        <v-card
          :loading="loading"
          class="d-block d-md-none mx-auto pa-4"
          elevation="0"
          color="transparent"
        >
          <template v-slot:loader="{ isActive }">
            <v-progress-linear
              :active="isActive"
              color="white"
              height="0"
              indeterminate
            ></v-progress-linear>
          </template>

          <form @submit.prevent="loginUser">
            <v-img
              contain
              height="97"
              src="@/assets/logo/logo-asik.svg"
              class="mb-8"
            ></v-img>

            <v-card-item class="mb-8">
              <v-card-title class="text-primary_light text_title text-center">
                {{
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_welcome_title
                }}
              </v-card-title>
              <v-card-subtitle class="mt-1 text_subtitle text-center">
                {{
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_welcome_subtitle
                }}
              </v-card-subtitle>
            </v-card-item>

            <v-card-item>
              <!--                   :error-messages="email.errorMessage.value" -->

              <v-text-field
                :readonly="loading"
                :loading="loading"
                v-model="email.value.value"
                :label="
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_email_label
                "
                :placeholder="
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_email_placeholder
                "
                clearable
                bg-color="form_background"
                base-color="primary"
                variant="solo"
                rounded="10"
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    src="@/assets/icons/login.svg"
                    contain
                    width="24"
                    height="24"
                    color="primary"
                  />
                </template>
              </v-text-field>

              <!--                                     :error-messages="password.errorMessage.value" -->

              <v-text-field
                :readonly="loading"
                :loading="loading"
                v-model="password.value.value"
                :label="
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_password_label
                "
                :placeholder="
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_password_placeholder
                "
                clearable
                :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append-inner="show1 = !show1"
                prepend-inner-icon="mdi-map-marker"
                bg-color="form_background"
                base-color="primary"
                color="primary"
                variant="solo"
                rounded="10"
              ></v-text-field>

              <v-container class="ma-0 pa-0">
                <v-row>
                  <v-col>
                    <v-btn
                      :loading="loading"
                      rounded="10"
                      type="submit"
                      color="primary"
                      class="gradient-btn"
                      block
                    >
                      {{
                        $vuetify.locale.messages[$vuetify.locale.current]
                          .Auth_form_button_login
                      }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col>
                    <v-btn
                      :loading="loading"
                      rounded="10"
                      size="small"
                      color="secondary"
                      variant="text"
                      to="/recover"
                      block
                    >
                      {{
                        $vuetify.locale.messages[$vuetify.locale.current]
                          .Auth_form_button_recovery
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-item>

            <!-- Possible reimplement of show-hide
                                 :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append-inner="show1 = !show1" -->

            <!-- Alerts -->
            <!-- <v-fade-transition>
              <v-card-item v-show='alarm_status'>
                <v-alert :type="alarm_type"> 
                  {{ alarm_message }}
                </v-alert>
              </v-card-item>
            </v-fade-transition> -->
          </form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { ref } from 'vue'
import store from "@/store";
import { useField, useForm } from "vee-validate";
// import require from '@/utils/require';

const { Guid } = require("../proto-out/protobuf-net/bcl_pb");
const { LoginRequest } = require("../proto-out/IMultiUserService_pb");
const {
  MultiUserServiceClient,
} = require("../proto-out/IMultiUserService_grpc_web_pb");

export default {
  name: "LoginPage",

  components: {},

  data: () => ({
    loading: false,
    show1: false,
    jsonData: {
      serverUrl: "https://novgorod.grpc.ibpspb.ru/",
    },
    serverMode: false,
    alarm_status: false,
    alarm_message: "",
    alarm_type: "",

    showForm: true,
    showValidDb: false,
    showInvalidDB: false,
    showUpdatedDB: false,
  }),

  setup() {
    const { handleSubmit, handleReset } = useForm({
      validationSchema: {
        email(value) {
          // if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true;
          if (value?.length === 0) return true;
          return "Должен иметь действующий адрес электронной почты или имя пользователя.";
        },
        password(value) {
          if (value?.length === 0) return true;
          if (value?.length >= 8) return true;
          return "Пароль должен быть не менее 8 символов.";
        },
      },
    });
    const email = useField("email");
    const password = useField("password");

    const submit = handleSubmit((values) => {
      // console.log(values);
      alert(JSON.stringify(values, null, 2));
    });
    return { email, password, submit, handleReset };
  },

  computed: {
    loginLabel() {
      const currentLocale = this.$vuetify.locale.current;
      const messages = this.$vuetify.locale.messages[currentLocale];
      return this.alarm_status
        ? messages.Auth_form_button_failed
        : messages.Auth_form_button_login;
    },
    btn_gradient_control() {
      return this.alarm_status ? "gradient-btn-error" : "gradient-btn";
    },
    login_icon_control() {
      return this.alarm_status
        ? "@/assets/icons/login_failed.svg"
        : "@/assets/icons/login.svg";
    },
    password_icon_control() {
      return this.alarm_status
        ? "@/assets/icons/password_failed.svg"
        : "@/assets/icons/password.svg";
    },
    colorElement() {
      return this.alarm_status ? "error" : "primary";
    },

    renderForm() {
      return this.showForm;
    },
    renderValidDB() {
      return this.showValidDb;
    },
    renderInvalidDB() {
      return this.showInvalidDB;
    },
    renderUpdatedDB() {
      return this.showUpdatedDB;
    },
    borderImgColor() {
      if (
        !this.renderValidDB &&
        !this.renderInvalidDB &&
        !this.renderUpdatedDB
      ) {
        // console.log("case 1");
        return "outline: 7px solid #72BADE";
      } else if (this.renderValidDB || this.renderUpdatedDB) {
        // console.log("case 2");
        return "outline: 7px solid green";
      } else {
        // console.log("case3 ");
        return "outline: 7px solid darkred";
      }
    },
  },

  methods: {
    async loginUser() {
      if (this.email.value.value == "change_server_url") {
        this.serverMode = true;
      } else {
        this.loading = true;

        this.showForm = false;

        try {
          var client = new MultiUserServiceClient(this.jsonData.serverUrl);
          var licencse = new Guid();
          licencse.setHi("450");
          licencse.setLo("851017");
          var request = new LoginRequest();
          request.setUsername(this.email.value.value);
          request.setHashedpassword(this.password.value.value);
          request.setLicenseid(licencse);
          client.multiLogin(request, {}, (err, response) => {
            if (err) {
              console.error("Error:", err.message);
              let token =
                "yJhbGciOiJkaXIiLCJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwidHlwIjoiSldUIiwiY3R5IjoiSldUIn0..0H_Sou6TJOoa36QYymTksg.01aIc6gisAiG5t8VUZFVliiHMNfz00qhr2nT861I5A7Luq4TWOXA9btPtwIvH9wYkFHRHvVrQn9CO0NLJmkYfQi9dWTSfr_EeWDjg8miPqlgYHr0lxJz76py20XSOKtdyY3SiSuYTjVGN2faCmaLBcTYeoJDXL5qKXaHneZ6u4shmOErxQZ6JNM5PCf7IL0l4yE5nLm9Lz9LY9d2dZpdKfgMJXx-eorY7B9XRpxs_5aqT1O6E1GbC7cIB4lHkPMTn0Cny5bXYToxQX8jBmsg3pwMOJAFMqygfKqcmMmERekQqkE9sIzwjoCnfMqO5fLR5MRc5vsv4hp0MiFh5iJQA3XIA_wzciBSsEETm294RgqRhyWGNoZ7vfRm_-M0SgSQ-y0bXwX09fZ0iDW2JMT6-nAwMeFBtpBjHvKK0lPZiphVU8wS1a7-fGI9hMJssO02.BRAvpkfBebS2_tzT1d9ipILjBciB_w62GBciwcYSXSc";
              this.mapToken(token);
            } else {
              // console.log("Response:", response.toObject());
              const responseObject = response.toObject();
              if (
                responseObject.error != "" ||
                responseObject.error == "Пользователь не найден"
              ) {
                this.alarm_status = true;
                this.loading = false;
                this.showForm = true;
              } else if (responseObject.accesstoken) {
                // console.log("Access Token:", responseObject.accesstoken);
                document.cookie = responseObject.accesstoken;
                this.mapToken(
                  responseObject.accesstoken,
                  responseObject.accesstokenexpires.seconds,
                );
              } else if (responseObject.u[0]) {
                // console.log("Access Token:", responseObject.u[0]);
                document.cookie = responseObject.u[0];
                this.mapToken(responseObject.u[0]);
              }
            }
          });
        } catch (e) {
          console.log(e);
          this.alarm_status = true;
          this.loading = false;
          this.showForm = true;
        }
      }
    },

    mapToken(token, expires) {
      store.commit("users/setToken", token);
      localStorage.setItem("userToken", token);
      localStorage.setItem("userTokenExpirency", expires);
      this.alarm_status = false;
      this.showValidDb = true;
      setTimeout(() => this.goHome(), 500);
    },

    updateDB() {
      this.alarm_status = false;
      this.showValidDb = false;
      this.showInvalidDB = false;
      this.showUpdatedDB = true;
      setTimeout(() => this.goHome(), 500);
    },
    goHome() {
      const isAuthenticated = store.getters["users/isAuthenticated"];
      if (isAuthenticated) {
        setTimeout(() => this.$router.push({ name: "Home" }), 0);
      } else {
        this.alarm_status = true;
      }
    },

    async updateServer() {
      store.commit("appData/setUrl", this.jsonData.serverUrl);
      this.serverMode = false;
    },
  },

  async created() {
    // Dynamically import the JSON file
    const data = await import("../../public/data/system.json");
    this.jsonData = data.default;
    store.commit("appData/setUrl", this.jsonData.serverUrl);
  },
};
</script>
<style scoped>
.gradient-btn {
  background: linear-gradient(to right, #006aa6, #004b76);
  color: white; /* Ensure the text color is visible on the gradient */
}

.btn_gradient_update {
  background: linear-gradient(to right, #0092e3, #00507d);
  color: white; /* Ensure the text color is visible on the gradient */
}

/* Remove the default background color applied by Vuetify */
.gradient-btn.v-btn {
  background-color: transparent !important;
}

.gradient-btn-error {
  background: linear-gradient(to right, #ff0000, #990000);
  color: white; /* Ensure the text color is visible on the gradient */
}

/* Remove the default background color applied by Vuetify */
.gradient-btn-error.v-btn {
  background-color: transparent !important;
}

.img-shadow {
  outline-offset: -7em;
  border-radius: 50%;
  box-shadow:
    0 0 7em #72bade,
    20em 0px 100px rgba(0, 153, 255, 0.2),
    -20em 0px 100px rgba(0, 153, 255, 0.2);
}

@media (min-width: 1920px) {
  .img-shadow {
    outline-offset: -8em; /* example of changing the offset */
    box-shadow:
      0 0 10em #72bade,
      30em 0px 150px rgba(0, 153, 255, 0.3),
      -30em 0px 150px rgba(0, 153, 255, 0.3);
  }
}

@media (min-width: 2560px) {
  .img-shadow {
    outline-offset: -7em; /* example of changing the offset */
    box-shadow:
      0 0 10em #72bade,
      30em 0px 150px rgba(0, 153, 255, 0.3),
      -30em 0px 150px rgba(0, 153, 255, 0.3);
  }
}

.responsive-txt {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.text-title {
  font-size: 26px;
}

.text-shadow {
  text-shadow: 0px 0px 10px black;
}
.circle-img {
  outline-offset: -6em;
  border-radius: 50%;
  box-shadow:
    0px 0px 90px 0px rgba(189, 234, 255),
    15em 0px 100px rgba(83, 201, 255, 0.1),
    -18em 0px 100px rgb(83, 201, 255, 0.1);
}
</style>
