<script setup>
import SiteParameterDisplay from "@/components/cards/SiteParameterDisplay.vue";
import Map from "@/components/Map.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

const isFirstColumnVisible = ref(true);
const isLoading = ref(true);
const mapCard = ref(null);
const mapResize = ref(null);
const error = ref(null);

const store = useStore();

const onMapMounted = (resizeFunction) => {
  mapResize.value = resizeFunction;
};

onMounted(() => {
  if (mapResize.value) {
    mapResize.value();
  }
});

const arrowIconSrc = computed(() =>
  isFirstColumnVisible.value
    ? new URL("@/assets/icons/arrow_drawer.svg", import.meta.url).href
    : new URL("@/assets/icons/arrow_drawer_close.svg", import.meta.url).href,
);

const constructions = computed(() => {
  const data = store.getters["locations/constructions"] || [];
  return data;
});

let constructionItems = computed(() => {
  const data = constructions.value.length;
  return data || 0;
});

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const {
  MultiConstructionServiceClient,
} = require("../proto-out/IMultiConstructionService-v4_grpc_web_pb");

const fetchDataFromGrpc = async () => {
  const client = new MultiConstructionServiceClient(serverUrl.value);
  const metadata = { Authorization: `Bearer ${authToken.value}` };

  return new Promise((resolve, reject) => {
    client.getAvailableConstructions(new Empty(), metadata, (err, response) => {
      if (err) {
        console.error("gRPC Error:", err.message);
        reject(new Error("Failed to fetch data from gRPC"));
      } else {
        const responseObject = response.toObject();
        store.dispatch("locations/setData", responseObject.valuesList);
        resolve(responseObject.valuesList);
      }
    });
  });
};

const fetchData = async () => {
  try {
    if (!constructions.value.length) {
      await fetchDataFromGrpc();
    }
  } catch (err) {
    error.value = "Failed to load data: " + err.message;
    console.error(error.value);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => {
  await fetchData();
});

const toggleFirstColumn = () => {
  isFirstColumnVisible.value = !isFirstColumnVisible.value;
  if (mapCard.value) {
    mapCard.value.invalidateMapSize();
  }
  setTimeout(() => {
    if (mapResize.value) {
      mapResize.value();
    }
  }, 0);
};
</script>

<template>
  <v-responsive class="align-center mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0">
    <v-overlay :model-value="isLoading" class="align-center justify-center">
      <v-progress-circular
        style="margin: 10px"
        size="120"
        width="7"
        indeterminate
        color="primary"
      >
      </v-progress-circular>
    </v-overlay>
    <v-fade-transition>
      <v-row v-if="!isLoading && constructionItems" fluid dense>
        <!-- First Column (conditionally visible) -->
        <v-col md="3" lg="3" v-if="isFirstColumnVisible">
          <v-row dense>
            <v-col>
              <v-container>
                <v-row class="mb-2">
                  <SiteParameterDisplay
                    icon="1"
                    title="Динамика отклонений по выполнению"
                    titleDimension="дни"
                    :data="constructions"
                  />
                </v-row>
                <v-row class="mb-5 mb-lg-2">
                  <SiteParameterDisplay
                    icon="2"
                    title="Динамика отклонений по качеству"
                    titleDimension="шт."
                    :data="constructions"
                  />
                </v-row>
                <v-row>
                  <SiteParameterDisplay
                    icon="3"
                    title="Динамика отклонений по освоению бюджета"
                    titleDimension="%"
                    :data="constructions"
                  />
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>

        <!-- Toggle Button -->
        <v-col cols="1" style="max-width: 2em">
          <v-btn
            @click="toggleFirstColumn()"
            class="thin-btn fill-height"
            :style="{ height: '93%' }"
            color="#2E414C"
            icon="$vuetify"
            elevation="4"
            rounded="sm"
          >
            <v-img :src="arrowIconSrc" contain width="12" height="16" />
          </v-btn>
        </v-col>

        <!-- Second Column -->
        <v-col class="fill-height">
          <Map
            v-if="!isLoading"
            :data="constructions"
            @map-mounted="onMapMounted"
          />
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-responsive>
</template>

<style scoped>
.fill-height {
  height: 100%;
}
.thin-btn {
  min-width: 16px;
  max-width: 16px;
  margin-top: 5px;
  padding: 8px;
  outline: none;
}
</style>
