// const maxValue = Math.max(Math.max(...plan), Math.max(...fact));

const chartOptions = {
  scales: {
    x: {
      title: {
        display: true,
        text: "Дата",
        color: "#4285F4",
        align: "start",
      },
      border: {
        color: "#FFFFFF",
      },
      ticks: {
        color: "#fff",
      },
      grid: {
        display: false,
      },
    },
    y: {
      title: {
        display: true,
        text: "Количество",
        color: "#4285F4",
        align: "start",
        padding: {
          bottom: 20,
        },
      },
      ticks: {
        display: false,
      },
      grid: {
        color: "#666",
      },
      // suggestedMax: maxValue * 1.1,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: (context) => context.dataset.borderColor,
      anchor: "top",
      align: "top",
      font: {
        weight: "bold",
        size: 12,
      },
      formatter: (() => {
        let lastValue = null;
        return function (value) {
          let output;
          if (lastValue === null || lastValue < value) {
            output = `${value.toFixed(0)} ▲`;
          } else {
            output = `${value.toFixed(0)} ▼`;
          }
          lastValue = value;
          return output;
        };
      })(),
    },
  },
};

export default {
  chartOptions,
};
