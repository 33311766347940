const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      title: {
        display: true,
        text: "Дата",
        color: "#4285F4",
        align: "start",
      },
      border: {
        color: "#FFFFFF",
      },
      ticks: {
        color: "#fff",
      },
      grid: {
        display: false,
      },
    },
    y: {
      title: {
        display: true,
        text: "% по ГЛГ",
        color: "#4285F4",
        align: "start",
        padding: {
          bottom: 20,
        },
      },
      ticks: {
        display: false,
      },
      grid: {
        color: "#666",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: (context) => context.dataset.borderColor,
      anchor: "top",
      align: "top",
      font: {
        weight: "bold",
        size: 12,
      },
      formatter: (() => {
        let lastValue = null;
        return function (value) {
          let output;
          if (lastValue === null || lastValue < value) {
            output = `${value.toFixed(2)}% ▲`;
          } else {
            output = `${value.toFixed(2)}% ▼`;
          }
          lastValue = value;
          return output;
        };
      })(),
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: "y",
      },
      pan: {
        enabled: true,
        mode: "y",
      },
    },
  },
};

export default {
  chartOptions,
};
