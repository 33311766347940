import { onMounted, createApp } from "vue";
import { useRouter } from "vue-router";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export const useMap = (
  mapElementId,
  latitude,
  longitude,
  popupComponent,
  iconPathGreen,
  iconPathRed,
  iconPathGray,
  data,
) => {
  let map;
  const router = useRouter();
  function goToDetails(id) {
    router.push({ name: "PropertyDetails", params: { id } });
  }
  //TODO add red
  function iconPath(factAdded) {
    if (factAdded) return iconPathGreen;
    else return iconPathGray;
  }
  onMounted(() => {
    try {
      map = L.map(mapElementId).setView([latitude, longitude], 13);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attributionControl: false,
      }).addTo(map);
      const calculateIconSize = () => {
        const baseWidth = 101; // Base icon width
        const baseHeight = 82; // Base icon height
        const scaleFactor = Math.max(window.innerWidth / 1920, 0.5); // Scale factor based on window width
        return [baseWidth * scaleFactor, baseHeight * scaleFactor];
      };

      data.forEach((location) => {
        const popupContainer = document.createElement("div");
        const app = createApp(popupComponent, { data: location });
        app.mount(popupContainer);

        const [iconWidth, iconHeight] = calculateIconSize();

        const icon = L.icon({
          iconUrl: iconPath(location.isfactadded),
          iconSize: [iconWidth, iconHeight],
          iconAnchor: [50, 20],
          popupAnchor: [0, -10],
        });

        const marker = L.marker([location?.latitude, location?.longitude], {
          icon,
        }).addTo(map);

        marker.on("mouseover", () => {
          marker.bindPopup(popupContainer).openPopup();
        });

        marker.on("mouseout", () => {
          marker.closePopup();
        });

        marker.on("click", () => {
          goToDetails(location.id);
        });
      });
    } catch (error) {
      console.error("Error initializing map:", error);
    }
  });

  const invalidateSize = () => {
    if (map) {
      map.invalidateSize();
    }
  };

  return { map, invalidateSize };
};
