<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import store from "@/store";
export default {
  name: "App",

  components: {},

  async created() {
        // Dynamically import the JSON file
    const data = await import('../public/data/system.json');
    this.jsonData = data.default; 
    store.commit("appData/setUrl", this.jsonData.serverUrl);

    const token = localStorage.getItem("userToken");
    if (token) {
      const tokenExpriency = localStorage.getItem("userTokenExpirency");
      const currentTime = Date.now() / 1000;

      if (currentTime < tokenExpriency) {
        store.commit("users/setToken", token);
        this.$router.push({ name: "Home" })
      } else {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userTokenExpirency");
        this.$router.push({ name: "Login" })
      }

    }
  }

};
</script>

<style lang="css">
.text_title {
font-size: 2rem;
line-height: 2.2rem;
src: url('@/assets/fonts/NeoSansProRegular.OTF') format('opentype');
font-weight: normal;
font-style: normal;
text-overflow: unset;
}

.text_subtitle {
  font-size: 1.25rem;
  line-height: 1.3rem;
  src: url('@/assets/fonts/NeoSansProRegular.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
  text-overflow: unset;
}

.scroll_data {
  overflow-y: auto;
  max-height: 300px;
  padding-right: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: #00466f;
  border-radius: 5px;
  margin: 15px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00a3ff;
  border-radius: 10px;
  border: 4px solid #00466f;
}

.leaflet-control-attribution {
  display: none !important;
}
</style>
