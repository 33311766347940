<script setup>
import { ref } from "vue";
import { formatDate } from "@/utils/formatDate";
import { computed } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const generateItemImage = (multiply, itemSrc) => {
  const base = 69;
  return itemSrc + `/S-${base * multiply}`;
};
//TODO add red
const titleColor = () => {
  if (props.data.isfactadded) return "green";
  else return "gray";
};
const progressWidth = (percent) => {
  return percent > 0 ? `${percent}%` : "0%";
};
</script>

<template>
  <div class="custom-card">
    <!-- Title Section -->
    <div class="title-section" :class="titleColor()" style="overflow: hidden">
      <div class="d-flex">
        <h3 class="title marquee pr-7" style="white-space: nowrap">
          {{ props.data.name }}
        </h3>
        <h3 class="title marquee pr-7" style="white-space: nowrap">
          {{ props.data.name }}
        </h3>
      </div>
    </div>

    <!-- Content Section -->
    <template v-if="props.data.isfactadded">
      <div class="content-section">
        <!-- Left Image Section -->
        <div class="image-section">
          <img
            :src="generateItemImage(3, props.data.imagesource)"
            class="building-image"
            alt="Building"
          />
        </div>

        <!-- Right Icon and Progress Section -->
        <div class="progress-section">
          <!-- Icon and Progress 1 -->
          <div class="progress-row">
            <img src="@/assets/bg/calendar.png" class="icon" alt="Calendar" />
            <div class="progress-bar">
              <div
                class="progress-fill blue"
                :style="{
                  width: progressWidth(
                    props.data.constructionplanfact.planpercentage,
                  ),
                }"
              ></div>
            </div>
            <span class="progress-value percent-blue"
              >{{
                props.data.constructionplanfact.planpercentage.toFixed(2)
              }}%</span
            >
          </div>

          <!-- Icon and Progress 2 -->
          <div class="progress-row">
            <img
              src="@/assets/bg/hammer-and-pick.png"
              class="icon"
              alt="Hammer"
            />
            <div class="progress-bar">
              <div
                class="progress-fill yellow"
                :style="{
                  width: progressWidth(
                    props.data.constructionplanfact.factpercentage,
                  ),
                }"
              ></div>
            </div>
            <span class="progress-value percent-yellow"
              >{{
                props.data.constructionplanfact.factpercentage.toFixed(2)
              }}%</span
            >
          </div>

          <!-- Icon and Progress 3 -->
          <div class="progress-row">
            <img src="@/assets/bg/barrier.png" class="icon" alt="Road" />
            <div class="progress-bar">
              <div
                class="progress-fill red"
                :style="{
                  width: progressWidth(
                    props.data.constructionplanfact.deviationpercentage,
                  ),
                }"
              ></div>
            </div>
            <span class="progress-value percent-red"
              >{{
                props.data.constructionplanfact.deviationpercentage.toFixed(2)
              }}%</span
            >
          </div>
        </div>
      </div>
    </template>

    <!-- Future Content Section  -->
    <template v-else>
      <div class="content-section">
        <!-- Left Image Section -->
        <div class="image-section">
          <img
            :src="generateItemImage(3, props.data.imagesource)"
            class="building-image"
            alt="Building"
          />
        </div>

        <!-- Right Icon and Progress Section -->
        <div class="future-progress-section">
          <div class="text-center mb-0">
            <img src="@/assets/bg/calendar.png" class="icon" alt="Calendar" />
          </div>
          <div>
            <div class="future-progress-bar">
              <div class="future-progress my-auto" style="width: 100%">
                {{
                  formatDate(props.data.constructionplanfact.startdate.seconds)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
.custom-card {
  display: flex;
  flex-direction: column;
  width: 243px;
  border-radius: 10px;
  color: white;
  padding: 5px 0;
}

.title-section {
  text-align: center;
  padding: 2px 0;
}

.title {
  font-size: 22px;
  padding: 3px;
  margin: 0;
}

.content-section {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
}

.image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.building-image {
  width: 69px;
  height: 72px;
  object-fit: cover;
  border-radius: 5px;
}

.progress-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.future-progress-section {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.progress-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.icon {
  margin-right: 10px;
  margin-left: 5px;
}

.progress-bar,
.future-progress-bar {
  flex: 1;
  height: 7px;
  background-color: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 15px;
  border: 1px solid white;
}

.progress-fill {
  height: 100%;
  border-radius: 3px;
}
.future-progress-bar {
  height: 18px;
  color: #0093e5;
  border-radius: 11px;
  text-align: center;
  background-color: white;
}
.future-progress {
  width: 100%;
}
.blue {
  background-color: #0093e5;
}

.yellow {
  background-color: #ffb800;
}

.red {
  background-color: #ff0000;
}

.percent-blue {
  color: #0093e5;
}
.percent-yellow {
  color: #ffb800;
}
.percent-red {
  color: #ff0000;
}

.progress-value {
  font-weight: bold;
  width: 25px;
}

.title-section {
  overflow: hidden;
  width: 100%;
}
.green {
  background: radial-gradient(closest-side, #1bc200, transparent);
}
.gray {
  background: radial-gradient(closest-side, #757575, transparent);
}
.marquee {
  display: inline-block; /* Allow horizontal scrolling */
  animation: marquee 15s linear infinite; /* Adjust duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start position */
  }
  100% {
    transform: translateX(-100%); /* End of scroll */
  }
}
@media (max-height: 810px) {
  .title {
    font-size: 18px;
  }
}
</style>
