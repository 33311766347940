const state = {
  clickedButton: "СРОКИ",
  clickedButtonTopTen: "technologyCards",
  clickedChartButton: 0,
  selectedItem: null,
};

const getters = {
  getClickedButton: (state) => state.clickedButton,
  getClickedButtonTopTen: (state) => state.clickedButtonTopTen,
  getClickedChartButton: (state) => state.clickedChartButton,
  getSelectedItem: (state) => state.selectedItem,
};

const actions = {
  updateClickedButton({ commit }, value) {
    commit("setClickedButton", value);
  },
  updateClickedButtonTopTen({ commit }, value) {
    commit("setClickedButtonTopTen", value);
  },
  updateClickedChartButton({ commit }, value) {
    commit("setClickedChartButton", value);
  },
  updateSelectedItem({ commit }, value) {
    commit("setSelectedItem", value);
  },
};

const mutations = {
  setClickedButton(state, value) {
    state.clickedButton = value;
  },
  setClickedButtonTopTen(state, value) {
    state.clickedButtonTopTen = value;
  },
  setClickedChartButton(state, value) {
    state.clickedChartButton = value;
  },
  setSelectedItem(state, value) {
    state.selectedItem = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
