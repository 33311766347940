// Import middleware
// import auth from '@/middleware/auth'
// import guest from '@/middleware/guest'

// Views/Layouts
import AuthLayout from '../views/AuthView.vue'
import DashboardLayout from '../views/DashboardView.vue'
import PropertyLayout from "../views/PropertyLayout.vue";

// Pages
import Login from '../pages/Login.vue'
import Register from '../pages/RecoverLogin.vue'
import Dashboard from '../pages/DashboardPage.vue'
// ---
import Locations from '../pages/LocationsPage.vue'
import PropertyDetails from "../pages/PropertyDetails.vue";
// import PropertyActions from '@/pages/PropertyActions.vue';
// ---

const authPages = {
  path: '/',
  redirect: '/login',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      // meta: { middleware: guest }
    },
    {
      path: '/recover',
      name: 'Register',
      component: Register,
      // meta: { middleware: guest }
    },
  ]
}

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: '/home',
    component: DashboardLayout,
    meta: { requiresAuth: true },
    // meta: { middleware: auth },
    children: [
      {
        path: '/home',
        name: 'Home',
        components: { default: Dashboard },
        meta: { requiresAuth: true },
        // meta: { middleware: auth }
      },
      {
        path: '/locations',
        name: 'Locations',
        components: { default: Locations },
        meta: { requiresAuth: true },
        // meta: { middleware: auth },
      }
    ]
  },
  {
    path: "/location",
    component: PropertyLayout,
    meta: { requiresAuth: true },
    // meta: { middleware: auth },
    children: [
      {
        path: ":id",
        name: "PropertyDetails",
        component: PropertyDetails,
        meta: { requiresAuth: true },
        // meta: { middleware: auth },
      }
    ],
  },
  authPages
]

export default routes
