<template>
  <div class="ma-0 pa-0">
    <TopNavigationBar />

    <router-view class="mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0" v-slot="{ Component }">
      <v-fade-transition duration="500" mode="out-in">
        <component :is="Component"></component>
      </v-fade-transition>
    </router-view>

    <Footer />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import TopNavigationBar from "@/components/navigation/TopNaviationBar.vue";
import Footer from "@/components/navigation/AppFooter.vue";

export default defineComponent({
  name: "DashboardView",

  components: {
    TopNavigationBar,
    Footer,
  },
});
</script>

<style scoped>
.fill-height {
  height: 100%;
}
.app_screen {
  height: 100%;
}
</style>
