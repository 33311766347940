<script setup>
import { formatDate } from "@/utils/formatDate";
import tableHeaders from "@/utils/tableHeaders.js";
const { deadlineChartHeaders } = tableHeaders;

defineProps({
  data: Object,
  isLoading: Boolean,
});
</script>

<template class="custom-table">
  <v-container class="custom-table">
    <v-data-table
      :headers="deadlineChartHeaders"
      :items="data.chartList"
      height="150px"
      fixed-header
      hide-default-footer
      :loading="isLoading"
      :items-per-page="-1"
      loading-text="Загрузка данных..."
      density="compact"
    >
      <template v-slot:no-data>
        <div class="text-center">
          <p>Не найдено ни одного элемента</p>
        </div>
      </template>
      <template v-slot:[`item.num`]="{ index }">
        <span> {{ index + 1 }} </span>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span> {{ formatDate(item.date.seconds) }}</span>
      </template>
      <template v-slot:[`item.plan`]="{ item }">
        <span> {{ item.plan?.toFixed(2) }}% </span>
      </template>
      <template v-slot:[`item.fact`]="{ item }">
        <span :class="{ 'fact-red': item.fact < 100 }">
          {{ item.fact?.toFixed(2) }}%
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
/* TODO remove border rows */
:deep(.v-data-table__td:first-child) {
  border-radius: 10px 0 0 10px !important;
}
:deep(.v-data-table__td:last-child) {
  border-radius: 0 10px 10px 0 !important;
}
:deep(::-webkit-scrollbar-track) {
  margin-top: 50px;
  margin-bottom: 10px;
}
:deep(.v-data-table thead th) {
  background: #006aa6 !important;
  text-align: center !important;
}
:deep(.v-data-table thead th:first-child) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
:deep(.v-data-table thead th:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.v-data-table {
  background-color: transparent;
  font-size: 0.8vw;
}
:deep(.v-data-table tbody tr):hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.fact-red {
  color: red;
  font-weight: bold;
}
.v-data-table__wrapper {
  max-height: 150px;
  overflow-y: auto;
}
</style>
