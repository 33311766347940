const state = {
  productivityData: {
    ProductChart: [
      {
        Date: {
          seconds: "1693267200",
          nanos: 0,
        },
        Plan: 100,
        Fact: 0,
        Deviation: 100,
      },
      {
        Date: {
          seconds: "1693353600",
          nanos: 0,
        },
        Plan: 48.49,
        Fact: 0,
        Deviation: 48.49,
      },
      {
        Date: {
          seconds: "1693440000",
          nanos: 0,
        },
        Plan: 48.69,
        Fact: 3.97,
        Deviation: 44.72,
      },
      {
        Date: {
          seconds: "1693526400",
          nanos: 0,
        },
        Plan: 48.89,
        Fact: 10.13,
        Deviation: 38.76,
      },
      {
        Date: {
          seconds: "1693785600",
          nanos: 0,
        },
        Plan: 49.49,
        Fact: 10.9,
        Deviation: 38.59,
      },
      {
        Date: {
          seconds: "1693872000",
          nanos: 0,
        },
        Plan: 49.69,
        Fact: 10.9,
        Deviation: 38.79,
      },
      {
        Date: {
          seconds: "1694390400",
          nanos: 0,
        },
        Plan: 50.9,
        Fact: 10.91,
        Deviation: 39.99,
      },
      {
        Date: {
          seconds: "1694476800",
          nanos: 0,
        },
        Plan: 51.1,
        Fact: 10.9,
        Deviation: 40.2,
      },
      {
        Date: {
          seconds: "1694563200",
          nanos: 0,
        },
        Plan: 51.3,
        Fact: 8.05,
        Deviation: 43.25,
      },
      {
        Date: {
          seconds: "1694649600",
          nanos: 0,
        },
        Plan: 51.5,
        Fact: 20.38,
        Deviation: 31.12,
      },
      {
        Date: {
          seconds: "1694736000",
          nanos: 0,
        },
        Plan: 51.71,
        Fact: 22.65,
        Deviation: 29.06,
      },
      {
        Date: {
          seconds: "1694995200",
          nanos: 0,
        },
        Plan: 52.31,
        Fact: 22.65,
        Deviation: 29.66,
      },
      {
        Date: {
          seconds: "1695081600",
          nanos: 0,
        },
        Plan: 52.51,
        Fact: 22.65,
        Deviation: 29.86,
      },
      {
        Date: {
          seconds: "1695168000",
          nanos: 0,
        },
        Plan: 52.71,
        Fact: 22.65,
        Deviation: 30.06,
      },
      {
        Date: {
          seconds: "1695686400",
          nanos: 0,
        },
        Plan: 45.8,
        Fact: 16.08,
        Deviation: 29.72,
      },
      {
        Date: {
          seconds: "1695772800",
          nanos: 0,
        },
        Plan: 46,
        Fact: 30.15,
        Deviation: 15.85,
      },
      {
        Date: {
          seconds: "1695859200",
          nanos: 0,
        },
        Plan: 47.42,
        Fact: 28.83,
        Deviation: 18.59,
      },
      {
        Date: {
          seconds: "1695945600",
          nanos: 0,
        },
        Plan: 47.61,
        Fact: 28.83,
        Deviation: 18.78,
      },
      {
        Date: {
          seconds: "1696204800",
          nanos: 0,
        },
        Plan: 48.19,
        Fact: 28.83,
        Deviation: 19.36,
      },
      {
        Date: {
          seconds: "1696291200",
          nanos: 0,
        },
        Plan: 48.38,
        Fact: 28.83,
        Deviation: 19.55,
      },
      {
        Date: {
          seconds: "1696377600",
          nanos: 0,
        },
        Plan: 48.57,
        Fact: 28.83,
        Deviation: 19.74,
      },
      {
        Date: {
          seconds: "1696464000",
          nanos: 0,
        },
        Plan: 48.76,
        Fact: 28.83,
        Deviation: 19.93,
      },
      {
        Date: {
          seconds: "1696550400",
          nanos: 0,
        },
        Plan: 48.95,
        Fact: 30.46,
        Deviation: 18.49,
      },
      {
        Date: {
          seconds: "1696809600",
          nanos: 0,
        },
        Plan: 49.52,
        Fact: 31.75,
        Deviation: 17.77,
      },
      {
        Date: {
          seconds: "1696896000",
          nanos: 0,
        },
        Plan: 49.71,
        Fact: 32.66,
        Deviation: 17.05,
      },
      {
        Date: {
          seconds: "1696982400",
          nanos: 0,
        },
        Plan: 48.73,
        Fact: 32.91,
        Deviation: 15.82,
      },
      {
        Date: {
          seconds: "1697068800",
          nanos: 0,
        },
        Plan: 48.92,
        Fact: 32.93,
        Deviation: 15.99,
      },
      {
        Date: {
          seconds: "1697155200",
          nanos: 0,
        },
        Plan: 49.12,
        Fact: 32.93,
        Deviation: 16.19,
      },
      {
        Date: {
          seconds: "1697414400",
          nanos: 0,
        },
        Plan: 49.71,
        Fact: 34.55,
        Deviation: 15.16,
      },
      {
        Date: {
          seconds: "1697500800",
          nanos: 0,
        },
        Plan: 49.9,
        Fact: 34.55,
        Deviation: 15.35,
      },
      {
        Date: {
          seconds: "1697587200",
          nanos: 0,
        },
        Plan: 50.09,
        Fact: 33.7,
        Deviation: 16.39,
      },
      {
        Date: {
          seconds: "1697673600",
          nanos: 0,
        },
        Plan: 50.29,
        Fact: 33.7,
        Deviation: 16.59,
      },
      {
        Date: {
          seconds: "1697760000",
          nanos: 0,
        },
        Plan: 50.48,
        Fact: 34.21,
        Deviation: 16.27,
      },
      {
        Date: {
          seconds: "1698019200",
          nanos: 0,
        },
        Plan: 51.07,
        Fact: 35.1,
        Deviation: 15.97,
      },
      {
        Date: {
          seconds: "1698105600",
          nanos: 0,
        },
        Plan: 51.26,
        Fact: 35.1,
        Deviation: 16.16,
      },
      {
        Date: {
          seconds: "1698192000",
          nanos: 0,
        },
        Plan: 51.46,
        Fact: 34.62,
        Deviation: 16.84,
      },
      {
        Date: {
          seconds: "1698278400",
          nanos: 0,
        },
        Plan: 51.65,
        Fact: 34.62,
        Deviation: 17.03,
      },
      {
        Date: {
          seconds: "1698364800",
          nanos: 0,
        },
        Plan: 51.85,
        Fact: 34.54,
        Deviation: 17.31,
      },
      {
        Date: {
          seconds: "1698624000",
          nanos: 0,
        },
        Plan: 52.43,
        Fact: 35.7,
        Deviation: 16.73,
      },
      {
        Date: {
          seconds: "1698710400",
          nanos: 0,
        },
        Plan: 52.63,
        Fact: 35.7,
        Deviation: 16.93,
      },
      {
        Date: {
          seconds: "1698796800",
          nanos: 0,
        },
        Plan: 52.82,
        Fact: 35.7,
        Deviation: 17.12,
      },
      {
        Date: {
          seconds: "1698883200",
          nanos: 0,
        },
        Plan: 53.02,
        Fact: 36.35,
        Deviation: 16.67,
      },
      {
        Date: {
          seconds: "1698969600",
          nanos: 0,
        },
        Plan: 53.21,
        Fact: 37.03,
        Deviation: 16.18,
      },
      {
        Date: {
          seconds: "1699228800",
          nanos: 0,
        },
        Plan: 53.8,
        Fact: 37.64,
        Deviation: 16.16,
      },
      {
        Date: {
          seconds: "1699315200",
          nanos: 0,
        },
        Plan: 53.99,
        Fact: 37.64,
        Deviation: 16.35,
      },
      {
        Date: {
          seconds: "1699401600",
          nanos: 0,
        },
        Plan: 54.19,
        Fact: 37.64,
        Deviation: 16.55,
      },
      {
        Date: {
          seconds: "1699488000",
          nanos: 0,
        },
        Plan: 54.38,
        Fact: 39.01,
        Deviation: 15.37,
      },
      {
        Date: {
          seconds: "1699574400",
          nanos: 0,
        },
        Plan: 54.58,
        Fact: 39.07,
        Deviation: 15.51,
      },
      {
        Date: {
          seconds: "1699833600",
          nanos: 0,
        },
        Plan: 55.16,
        Fact: 39.66,
        Deviation: 15.5,
      },
      {
        Date: {
          seconds: "1699920000",
          nanos: 0,
        },
        Plan: 55.36,
        Fact: 39.66,
        Deviation: 15.7,
      },
      {
        Date: {
          seconds: "1700006400",
          nanos: 0,
        },
        Plan: 55.55,
        Fact: 39.66,
        Deviation: 15.89,
      },
      {
        Date: {
          seconds: "1700092800",
          nanos: 0,
        },
        Plan: 55.75,
        Fact: 39.66,
        Deviation: 16.09,
      },
      {
        Date: {
          seconds: "1700179200",
          nanos: 0,
        },
        Plan: 55.94,
        Fact: 40,
        Deviation: 15.94,
      },
      {
        Date: {
          seconds: "1700438400",
          nanos: 0,
        },
        Plan: 56.53,
        Fact: 40.76,
        Deviation: 15.77,
      },
      {
        Date: {
          seconds: "1700524800",
          nanos: 0,
        },
        Plan: 56.72,
        Fact: 40.76,
        Deviation: 15.96,
      },
      {
        Date: {
          seconds: "1700611200",
          nanos: 0,
        },
        Plan: 56.92,
        Fact: 40.76,
        Deviation: 16.16,
      },
      {
        Date: {
          seconds: "1700697600",
          nanos: 0,
        },
        Plan: 57.11,
        Fact: 40.76,
        Deviation: 16.35,
      },
      {
        Date: {
          seconds: "1700784000",
          nanos: 0,
        },
        Plan: 57.3,
        Fact: 40.76,
        Deviation: 16.54,
      },
      {
        Date: {
          seconds: "1701043200",
          nanos: 0,
        },
        Plan: 57.89,
        Fact: 40.76,
        Deviation: 17.13,
      },
      {
        Date: {
          seconds: "1701129600",
          nanos: 0,
        },
        Plan: 58.08,
        Fact: 40.76,
        Deviation: 17.32,
      },
      {
        Date: {
          seconds: "1701216000",
          nanos: 0,
        },
        Plan: 100,
        Fact: 34.29,
        Deviation: 65.71,
      },
      {
        Date: {
          seconds: "1701302400",
          nanos: 0,
        },
        Plan: 100,
        Fact: 35.23,
        Deviation: 64.77,
      },
      {
        Date: {
          seconds: "1701388800",
          nanos: 0,
        },
        Plan: 100,
        Fact: 35.48,
        Deviation: 64.52,
      },
      {
        Date: {
          seconds: "1701648000",
          nanos: 0,
        },
        Plan: 100,
        Fact: 40.99,
        Deviation: 59.01,
      },
      {
        Date: {
          seconds: "1701734400",
          nanos: 0,
        },
        Plan: 100,
        Fact: 40.99,
        Deviation: 59.01,
      },
      {
        Date: {
          seconds: "1701820800",
          nanos: 0,
        },
        Plan: 61.23,
        Fact: 41.04,
        Deviation: 20.19,
      },
      {
        Date: {
          seconds: "1701907200",
          nanos: 0,
        },
        Plan: 61.42,
        Fact: 41.06,
        Deviation: 20.36,
      },
      {
        Date: {
          seconds: "1701993600",
          nanos: 0,
        },
        Plan: 61.61,
        Fact: 42.42,
        Deviation: 19.19,
      },
      {
        Date: {
          seconds: "1702252800",
          nanos: 0,
        },
        Plan: 62.17,
        Fact: 42.42,
        Deviation: 19.75,
      },
      {
        Date: {
          seconds: "1702339200",
          nanos: 0,
        },
        Plan: 62.35,
        Fact: 43.21,
        Deviation: 19.14,
      },
      {
        Date: {
          seconds: "1702425600",
          nanos: 0,
        },
        Plan: 62.54,
        Fact: 49.71,
        Deviation: 12.83,
      },
      {
        Date: {
          seconds: "1702512000",
          nanos: 0,
        },
        Plan: 62.73,
        Fact: 49.71,
        Deviation: 13.02,
      },
      {
        Date: {
          seconds: "1702598400",
          nanos: 0,
        },
        Plan: 62.92,
        Fact: 49.71,
        Deviation: 13.21,
      },
      {
        Date: {
          seconds: "1702857600",
          nanos: 0,
        },
        Plan: 63.48,
        Fact: 49.71,
        Deviation: 13.77,
      },
      {
        Date: {
          seconds: "1702944000",
          nanos: 0,
        },
        Plan: 63.67,
        Fact: 49.71,
        Deviation: 13.96,
      },
      {
        Date: {
          seconds: "1703030400",
          nanos: 0,
        },
        Plan: 63.85,
        Fact: 49.71,
        Deviation: 14.14,
      },
      {
        Date: {
          seconds: "1703116800",
          nanos: 0,
        },
        Plan: 64.04,
        Fact: 52.29,
        Deviation: 11.75,
      },
      {
        Date: {
          seconds: "1703203200",
          nanos: 0,
        },
        Plan: 64.23,
        Fact: 53.16,
        Deviation: 11.07,
      },
      {
        Date: {
          seconds: "1703462400",
          nanos: 0,
        },
        Plan: 64.79,
        Fact: 53.16,
        Deviation: 11.63,
      },
      {
        Date: {
          seconds: "1703548800",
          nanos: 0,
        },
        Plan: 64.98,
        Fact: 53.91,
        Deviation: 11.07,
      },
      {
        Date: {
          seconds: "1703635200",
          nanos: 0,
        },
        Plan: 65.16,
        Fact: 54.04,
        Deviation: 11.12,
      },
      {
        Date: {
          seconds: "1703721600",
          nanos: 0,
        },
        Plan: 65.35,
        Fact: 54.79,
        Deviation: 10.56,
      },
      {
        Date: {
          seconds: "1703808000",
          nanos: 0,
        },
        Plan: 65.54,
        Fact: 54.79,
        Deviation: 10.75,
      },
      {
        Date: {
          seconds: "1704067200",
          nanos: 0,
        },
        Plan: 66.1,
        Fact: 54.79,
        Deviation: 11.31,
      },
      {
        Date: {
          seconds: "1704153600",
          nanos: 0,
        },
        Plan: 66.29,
        Fact: 54.79,
        Deviation: 11.5,
      },
      {
        Date: {
          seconds: "1704240000",
          nanos: 0,
        },
        Plan: 66.47,
        Fact: 54.79,
        Deviation: 11.68,
      },
      {
        Date: {
          seconds: "1704326400",
          nanos: 0,
        },
        Plan: 66.66,
        Fact: 54.79,
        Deviation: 11.87,
      },
      {
        Date: {
          seconds: "1704412800",
          nanos: 0,
        },
        Plan: 66.85,
        Fact: 54.79,
        Deviation: 12.06,
      },
      {
        Date: {
          seconds: "1704672000",
          nanos: 0,
        },
        Plan: 67.41,
        Fact: 55.43,
        Deviation: 11.98,
      },
      {
        Date: {
          seconds: "1704758400",
          nanos: 0,
        },
        Plan: 67.6,
        Fact: 55.43,
        Deviation: 12.17,
      },
      {
        Date: {
          seconds: "1704844800",
          nanos: 0,
        },
        Plan: 67.79,
        Fact: 55.63,
        Deviation: 12.16,
      },
      {
        Date: {
          seconds: "1704931200",
          nanos: 0,
        },
        Plan: 67.97,
        Fact: 56.39,
        Deviation: 11.58,
      },
      {
        Date: {
          seconds: "1705017600",
          nanos: 0,
        },
        Plan: 68.16,
        Fact: 56.39,
        Deviation: 11.77,
      },
      {
        Date: {
          seconds: "1705276800",
          nanos: 0,
        },
        Plan: 68.72,
        Fact: 56.39,
        Deviation: 12.33,
      },
      {
        Date: {
          seconds: "1705363200",
          nanos: 0,
        },
        Plan: 68.91,
        Fact: 56.39,
        Deviation: 12.52,
      },
      {
        Date: {
          seconds: "1705449600",
          nanos: 0,
        },
        Plan: 69.1,
        Fact: 57,
        Deviation: 12.1,
      },
      {
        Date: {
          seconds: "1705536000",
          nanos: 0,
        },
        Plan: 69.28,
        Fact: 58.28,
        Deviation: 11,
      },
      {
        Date: {
          seconds: "1705622400",
          nanos: 0,
        },
        Plan: 69.47,
        Fact: 57.65,
        Deviation: 11.82,
      },
      {
        Date: {
          seconds: "1705881600",
          nanos: 0,
        },
        Plan: 70.03,
        Fact: 57.65,
        Deviation: 12.38,
      },
      {
        Date: {
          seconds: "1705968000",
          nanos: 0,
        },
        Plan: 70.22,
        Fact: 57.65,
        Deviation: 12.57,
      },
      {
        Date: {
          seconds: "1706054400",
          nanos: 0,
        },
        Plan: 70.41,
        Fact: 58.77,
        Deviation: 11.64,
      },
      {
        Date: {
          seconds: "1706140800",
          nanos: 0,
        },
        Plan: 70.59,
        Fact: 59.63,
        Deviation: 10.96,
      },
      {
        Date: {
          seconds: "1706227200",
          nanos: 0,
        },
        Plan: 70.78,
        Fact: 59.63,
        Deviation: 11.15,
      },
      {
        Date: {
          seconds: "1706486400",
          nanos: 0,
        },
        Plan: 71.34,
        Fact: 59.63,
        Deviation: 11.71,
      },
      {
        Date: {
          seconds: "1706572800",
          nanos: 0,
        },
        Plan: 71.53,
        Fact: 59.63,
        Deviation: 11.9,
      },
      {
        Date: {
          seconds: "1706659200",
          nanos: 0,
        },
        Plan: 71.72,
        Fact: 60.97,
        Deviation: 10.75,
      },
      {
        Date: {
          seconds: "1706745600",
          nanos: 0,
        },
        Plan: 71.91,
        Fact: 62.2,
        Deviation: 9.71,
      },
      {
        Date: {
          seconds: "1706832000",
          nanos: 0,
        },
        Plan: 72.09,
        Fact: 62.2,
        Deviation: 9.89,
      },
      {
        Date: {
          seconds: "1707091200",
          nanos: 0,
        },
        Plan: 72.65,
        Fact: 62.2,
        Deviation: 10.45,
      },
      {
        Date: {
          seconds: "1707177600",
          nanos: 0,
        },
        Plan: 72.84,
        Fact: 62.2,
        Deviation: 10.64,
      },
      {
        Date: {
          seconds: "1707264000",
          nanos: 0,
        },
        Plan: 73.03,
        Fact: 63.46,
        Deviation: 9.57,
      },
      {
        Date: {
          seconds: "1707350400",
          nanos: 0,
        },
        Plan: 73.22,
        Fact: 65.24,
        Deviation: 7.98,
      },
      {
        Date: {
          seconds: "1707436800",
          nanos: 0,
        },
        Plan: 73.4,
        Fact: 65.24,
        Deviation: 8.16,
      },
      {
        Date: {
          seconds: "1707696000",
          nanos: 0,
        },
        Plan: 73.97,
        Fact: 65.24,
        Deviation: 8.73,
      },
      {
        Date: {
          seconds: "1707782400",
          nanos: 0,
        },
        Plan: 74.15,
        Fact: 65.24,
        Deviation: 8.91,
      },
      {
        Date: {
          seconds: "1707868800",
          nanos: 0,
        },
        Plan: 74.34,
        Fact: 66.29,
        Deviation: 8.05,
      },
      {
        Date: {
          seconds: "1707955200",
          nanos: 0,
        },
        Plan: 74.53,
        Fact: 67.36,
        Deviation: 7.17,
      },
      {
        Date: {
          seconds: "1708041600",
          nanos: 0,
        },
        Plan: 74.71,
        Fact: 67.36,
        Deviation: 7.35,
      },
      {
        Date: {
          seconds: "1708300800",
          nanos: 0,
        },
        Plan: 75.28,
        Fact: 67.36,
        Deviation: 7.92,
      },
      {
        Date: {
          seconds: "1708387200",
          nanos: 0,
        },
        Plan: 75.46,
        Fact: 67.36,
        Deviation: 8.1,
      },
      {
        Date: {
          seconds: "1708473600",
          nanos: 0,
        },
        Plan: 75.65,
        Fact: 68.62,
        Deviation: 7.03,
      },
      {
        Date: {
          seconds: "1708560000",
          nanos: 0,
        },
        Plan: 75.84,
        Fact: 69.58,
        Deviation: 6.26,
      },
      {
        Date: {
          seconds: "1708646400",
          nanos: 0,
        },
        Plan: 76.02,
        Fact: 69.58,
        Deviation: 6.44,
      },
      {
        Date: {
          seconds: "1708905600",
          nanos: 0,
        },
        Plan: 76.59,
        Fact: 69.58,
        Deviation: 7.01,
      },
      {
        Date: {
          seconds: "1708992000",
          nanos: 0,
        },
        Plan: 74.14,
        Fact: 69.41,
        Deviation: 4.73,
      },
      {
        Date: {
          seconds: "1709078400",
          nanos: 0,
        },
        Plan: 74.32,
        Fact: 69.86,
        Deviation: 4.46,
      },
      {
        Date: {
          seconds: "1709164800",
          nanos: 0,
        },
        Plan: 77.15,
        Fact: 70.88,
        Deviation: 6.27,
      },
      {
        Date: {
          seconds: "1709251200",
          nanos: 0,
        },
        Plan: 77.34,
        Fact: 70.88,
        Deviation: 6.46,
      },
      {
        Date: {
          seconds: "1709510400",
          nanos: 0,
        },
        Plan: 77.9,
        Fact: 70.88,
        Deviation: 7.02,
      },
      {
        Date: {
          seconds: "1709596800",
          nanos: 0,
        },
        Plan: 78.08,
        Fact: 70.88,
        Deviation: 7.2,
      },
      {
        Date: {
          seconds: "1709683200",
          nanos: 0,
        },
        Plan: 78.27,
        Fact: 71.67,
        Deviation: 6.6,
      },
      {
        Date: {
          seconds: "1709769600",
          nanos: 0,
        },
        Plan: 78.46,
        Fact: 72.59,
        Deviation: 5.87,
      },
      {
        Date: {
          seconds: "1709856000",
          nanos: 0,
        },
        Plan: 78.65,
        Fact: 72.59,
        Deviation: 6.06,
      },
      {
        Date: {
          seconds: "1710115200",
          nanos: 0,
        },
        Plan: 79.21,
        Fact: 72.59,
        Deviation: 6.62,
      },
      {
        Date: {
          seconds: "1710201600",
          nanos: 0,
        },
        Plan: 79.4,
        Fact: 72.59,
        Deviation: 6.81,
      },
      {
        Date: {
          seconds: "1710288000",
          nanos: 0,
        },
        Plan: 79.58,
        Fact: 73.13,
        Deviation: 6.45,
      },
      {
        Date: {
          seconds: "1710374400",
          nanos: 0,
        },
        Plan: 79.77,
        Fact: 73.84,
        Deviation: 5.93,
      },
      {
        Date: {
          seconds: "1710460800",
          nanos: 0,
        },
        Plan: 79.96,
        Fact: 73.84,
        Deviation: 6.12,
      },
      {
        Date: {
          seconds: "1710720000",
          nanos: 0,
        },
        Plan: 80.52,
        Fact: 73.84,
        Deviation: 6.68,
      },
      {
        Date: {
          seconds: "1710806400",
          nanos: 0,
        },
        Plan: 80.71,
        Fact: 73.84,
        Deviation: 6.87,
      },
      {
        Date: {
          seconds: "1710892800",
          nanos: 0,
        },
        Plan: 80.89,
        Fact: 75.5,
        Deviation: 5.39,
      },
      {
        Date: {
          seconds: "1710979200",
          nanos: 0,
        },
        Plan: 81.08,
        Fact: 75.5,
        Deviation: 5.58,
      },
      {
        Date: {
          seconds: "1711065600",
          nanos: 0,
        },
        Plan: 81.27,
        Fact: 75.5,
        Deviation: 5.77,
      },
      {
        Date: {
          seconds: "1711324800",
          nanos: 0,
        },
        Plan: 81.83,
        Fact: 75.5,
        Deviation: 6.33,
      },
      {
        Date: {
          seconds: "1711411200",
          nanos: 0,
        },
        Plan: 82.02,
        Fact: 75.5,
        Deviation: 6.52,
      },
      {
        Date: {
          seconds: "1711497600",
          nanos: 0,
        },
        Plan: 82.2,
        Fact: 76.66,
        Deviation: 5.54,
      },
      {
        Date: {
          seconds: "1711584000",
          nanos: 0,
        },
        Plan: 82.39,
        Fact: 77.7,
        Deviation: 4.69,
      },
      {
        Date: {
          seconds: "1711670400",
          nanos: 0,
        },
        Plan: 82.58,
        Fact: 77.7,
        Deviation: 4.88,
      },
      {
        Date: {
          seconds: "1711929600",
          nanos: 0,
        },
        Plan: 83.14,
        Fact: 77.7,
        Deviation: 5.44,
      },
      {
        Date: {
          seconds: "1712016000",
          nanos: 0,
        },
        Plan: 83.33,
        Fact: 77.7,
        Deviation: 5.63,
      },
      {
        Date: {
          seconds: "1712102400",
          nanos: 0,
        },
        Plan: 83.52,
        Fact: 78.43,
        Deviation: 5.09,
      },
      {
        Date: {
          seconds: "1712188800",
          nanos: 0,
        },
        Plan: 83.7,
        Fact: 79.26,
        Deviation: 4.44,
      },
      {
        Date: {
          seconds: "1712275200",
          nanos: 0,
        },
        Plan: 83.89,
        Fact: 79.26,
        Deviation: 4.63,
      },
      {
        Date: {
          seconds: "1712534400",
          nanos: 0,
        },
        Plan: 84.45,
        Fact: 79.26,
        Deviation: 5.19,
      },
      {
        Date: {
          seconds: "1712620800",
          nanos: 0,
        },
        Plan: 84.64,
        Fact: 79.26,
        Deviation: 5.38,
      },
      {
        Date: {
          seconds: "1712707200",
          nanos: 0,
        },
        Plan: 84.83,
        Fact: 80.46,
        Deviation: 4.37,
      },
      {
        Date: {
          seconds: "1712793600",
          nanos: 0,
        },
        Plan: 82.69,
        Fact: 81.34,
        Deviation: 1.35,
      },
      {
        Date: {
          seconds: "1712880000",
          nanos: 0,
        },
        Plan: 82.87,
        Fact: 82.49,
        Deviation: 0.38,
      },
      {
        Date: {
          seconds: "1713225600",
          nanos: 0,
        },
        Plan: 83.6,
        Fact: 82.49,
        Deviation: 1.11,
      },
      {
        Date: {
          seconds: "1713312000",
          nanos: 0,
        },
        Plan: 83.78,
        Fact: 82.49,
        Deviation: 1.29,
      },
      {
        Date: {
          seconds: "1713398400",
          nanos: 0,
        },
        Plan: 83.97,
        Fact: 83.77,
        Deviation: 0.2,
      },
      {
        Date: {
          seconds: "1713484800",
          nanos: 0,
        },
        Plan: 84.15,
        Fact: 83.99,
        Deviation: 0.16,
      },
      {
        Date: {
          seconds: "1713916800",
          nanos: 0,
        },
        Plan: 85.06,
        Fact: 86.49,
        Deviation: -1.43,
      },
      {
        Date: {
          seconds: "1714003200",
          nanos: 0,
        },
        Plan: 85.24,
        Fact: 86.49,
        Deviation: -1.25,
      },
      {
        Date: {
          seconds: "1714089600",
          nanos: 0,
        },
        Plan: 85.42,
        Fact: 86.49,
        Deviation: -1.07,
      },
      {
        Date: {
          seconds: "1714608000",
          nanos: 0,
        },
        Plan: 86.52,
        Fact: 86.49,
        Deviation: 0.03,
      },
      {
        Date: {
          seconds: "1714694400",
          nanos: 0,
        },
        Plan: 86.7,
        Fact: 86.49,
        Deviation: 0.21,
      },
      {
        Date: {
          seconds: "1714953600",
          nanos: 0,
        },
        Plan: 87.24,
        Fact: 86.49,
        Deviation: 0.75,
      },
      {
        Date: {
          seconds: "1715040000",
          nanos: 0,
        },
        Plan: 87.43,
        Fact: 90.44,
        Deviation: -3.01,
      },
      {
        Date: {
          seconds: "1715126400",
          nanos: 0,
        },
        Plan: 87.61,
        Fact: 90.44,
        Deviation: -2.83,
      },
      {
        Date: {
          seconds: "1715644800",
          nanos: 0,
        },
        Plan: 88.72,
        Fact: 88.61,
        Deviation: 0.11,
      },
      {
        Date: {
          seconds: "1715731200",
          nanos: 0,
        },
        Plan: 88.9,
        Fact: 88.61,
        Deviation: 0.29,
      },
      {
        Date: {
          seconds: "1715817600",
          nanos: 0,
        },
        Plan: 97.02,
        Fact: 96.97,
        Deviation: 0.05,
      },
      {
        Date: {
          seconds: "1715904000",
          nanos: 0,
        },
        Plan: 97.22,
        Fact: 96.97,
        Deviation: 0.25,
      },
      {
        Date: {
          seconds: "1716163200",
          nanos: 0,
        },
        Plan: 97.82,
        Fact: 96.97,
        Deviation: 0.85,
      },
      {
        Date: {
          seconds: "1716249600",
          nanos: 0,
        },
        Plan: 98.01,
        Fact: 96.97,
        Deviation: 1.04,
      },
      {
        Date: {
          seconds: "1716336000",
          nanos: 0,
        },
        Plan: 98.21,
        Fact: 96.98,
        Deviation: 1.23,
      },
      {
        Date: {
          seconds: "1716422400",
          nanos: 0,
        },
        Plan: 98.41,
        Fact: 97.48,
        Deviation: 0.93,
      },
      {
        Date: {
          seconds: "1716508800",
          nanos: 0,
        },
        Plan: 98.61,
        Fact: 97.63,
        Deviation: 0.98,
      },
      {
        Date: {
          seconds: "1716768000",
          nanos: 0,
        },
        Plan: 99.2,
        Fact: 97.63,
        Deviation: 1.57,
      },
      {
        Date: {
          seconds: "1716854400",
          nanos: 0,
        },
        Plan: 99.4,
        Fact: 97.63,
        Deviation: 1.77,
      },
      {
        Date: {
          seconds: "1716940800",
          nanos: 0,
        },
        Plan: 99.6,
        Fact: 98.14,
        Deviation: 1.46,
      },
      {
        Date: {
          seconds: "1717027200",
          nanos: 0,
        },
        Plan: 99.8,
        Fact: 98.3,
        Deviation: 1.5,
      },
      {
        Date: {
          seconds: "1717113600",
          nanos: 0,
        },
        Plan: 100,
        Fact: 98.3,
        Deviation: 1.7,
      },
      {
        Date: {
          seconds: "1717372800",
          nanos: 0,
        },
        Plan: 95.84,
        Fact: 98.1,
        Deviation: -2.26,
      },
      {
        Date: {
          seconds: "1717459200",
          nanos: 0,
        },
        Plan: 96.03,
        Fact: 98.49,
        Deviation: -2.46,
      },
      {
        Date: {
          seconds: "1717545600",
          nanos: 0,
        },
        Plan: 96.22,
        Fact: 98.61,
        Deviation: -2.39,
      },
      {
        Date: {
          seconds: "1717632000",
          nanos: 0,
        },
        Plan: 96.41,
        Fact: 98.61,
        Deviation: -2.2,
      },
      {
        Date: {
          seconds: "1717718400",
          nanos: 0,
        },
        Plan: 96.6,
        Fact: 98.62,
        Deviation: -2.02,
      },
      {
        Date: {
          seconds: "1717977600",
          nanos: 0,
        },
        Plan: 97.16,
        Fact: 98.62,
        Deviation: -1.46,
      },
      {
        Date: {
          seconds: "1718064000",
          nanos: 0,
        },
        Plan: 97.35,
        Fact: 98.67,
        Deviation: -1.32,
      },
      {
        Date: {
          seconds: "1718150400",
          nanos: 0,
        },
        Plan: 97.54,
        Fact: 98.67,
        Deviation: -1.13,
      },
      {
        Date: {
          seconds: "1718236800",
          nanos: 0,
        },
        Plan: 97.73,
        Fact: 98.67,
        Deviation: -0.94,
      },
      {
        Date: {
          seconds: "1718323200",
          nanos: 0,
        },
        Plan: 97.92,
        Fact: 98.67,
        Deviation: -0.75,
      },
      {
        Date: {
          seconds: "1718582400",
          nanos: 0,
        },
        Plan: 98.49,
        Fact: 98.67,
        Deviation: -0.18,
      },
      {
        Date: {
          seconds: "1718668800",
          nanos: 0,
        },
        Plan: 98.67,
        Fact: 98.96,
        Deviation: -0.29,
      },
      {
        Date: {
          seconds: "1718755200",
          nanos: 0,
        },
        Plan: 98.86,
        Fact: 99.23,
        Deviation: -0.37,
      },
      {
        Date: {
          seconds: "1718841600",
          nanos: 0,
        },
        Plan: 81.64,
        Fact: 73.48,
        Deviation: 8.16,
      },
      {
        Date: {
          seconds: "1718928000",
          nanos: 0,
        },
        Plan: 81.8,
        Fact: 73.48,
        Deviation: 8.32,
      },
      {
        Date: {
          seconds: "1719273600",
          nanos: 0,
        },
        Plan: 82.42,
        Fact: 73.48,
        Deviation: 8.94,
      },
      {
        Date: {
          seconds: "1719360000",
          nanos: 0,
        },
        Plan: 82.58,
        Fact: 73.48,
        Deviation: 9.1,
      },
      {
        Date: {
          seconds: "1719446400",
          nanos: 0,
        },
        Plan: 82.73,
        Fact: 73.48,
        Deviation: 9.25,
      },
      {
        Date: {
          seconds: "1719532800",
          nanos: 0,
        },
        Plan: 82.89,
        Fact: 73.48,
        Deviation: 9.41,
      },
      {
        Date: {
          seconds: "1730073600",
          nanos: 0,
        },
        Plan: 100,
        Fact: 68.18,
        Deviation: 31.82,
      },
    ],
    TtkChart: [
      {
        Date: {
          seconds: "1693267200",
          nanos: 0,
        },
        Plan: 100,
        Fact: 0,
        Deviation: 100,
      },
      {
        Date: {
          seconds: "1693353600",
          nanos: 0,
        },
        Plan: 48.49,
        Fact: 0,
        Deviation: 48.49,
      },
      {
        Date: {
          seconds: "1693440000",
          nanos: 0,
        },
        Plan: 48.69,
        Fact: 3.97,
        Deviation: 44.72,
      },
      {
        Date: {
          seconds: "1693526400",
          nanos: 0,
        },
        Plan: 48.89,
        Fact: 10.13,
        Deviation: 38.76,
      },
      {
        Date: {
          seconds: "1693785600",
          nanos: 0,
        },
        Plan: 49.49,
        Fact: 10.9,
        Deviation: 38.59,
      },
      {
        Date: {
          seconds: "1693872000",
          nanos: 0,
        },
        Plan: 49.69,
        Fact: 10.9,
        Deviation: 38.79,
      },
      {
        Date: {
          seconds: "1694390400",
          nanos: 0,
        },
        Plan: 50.9,
        Fact: 10.91,
        Deviation: 39.99,
      },
      {
        Date: {
          seconds: "1694476800",
          nanos: 0,
        },
        Plan: 51.1,
        Fact: 10.9,
        Deviation: 40.2,
      },
      {
        Date: {
          seconds: "1694563200",
          nanos: 0,
        },
        Plan: 51.3,
        Fact: 8.05,
        Deviation: 43.25,
      },
      {
        Date: {
          seconds: "1694649600",
          nanos: 0,
        },
        Plan: 51.5,
        Fact: 20.38,
        Deviation: 31.12,
      },
      {
        Date: {
          seconds: "1694736000",
          nanos: 0,
        },
        Plan: 51.71,
        Fact: 22.65,
        Deviation: 29.06,
      },
      {
        Date: {
          seconds: "1694995200",
          nanos: 0,
        },
        Plan: 52.31,
        Fact: 22.65,
        Deviation: 29.66,
      },
      {
        Date: {
          seconds: "1695081600",
          nanos: 0,
        },
        Plan: 52.51,
        Fact: 22.65,
        Deviation: 29.86,
      },
      {
        Date: {
          seconds: "1695168000",
          nanos: 0,
        },
        Plan: 52.71,
        Fact: 22.65,
        Deviation: 30.06,
      },
      {
        Date: {
          seconds: "1695686400",
          nanos: 0,
        },
        Plan: 45.8,
        Fact: 16.08,
        Deviation: 29.72,
      },
      {
        Date: {
          seconds: "1695772800",
          nanos: 0,
        },
        Plan: 46,
        Fact: 30.15,
        Deviation: 15.85,
      },
      {
        Date: {
          seconds: "1695859200",
          nanos: 0,
        },
        Plan: 47.42,
        Fact: 28.83,
        Deviation: 18.59,
      },
      {
        Date: {
          seconds: "1695945600",
          nanos: 0,
        },
        Plan: 47.61,
        Fact: 28.83,
        Deviation: 18.78,
      },
      {
        Date: {
          seconds: "1696204800",
          nanos: 0,
        },
        Plan: 48.19,
        Fact: 28.83,
        Deviation: 19.36,
      },
      {
        Date: {
          seconds: "1696291200",
          nanos: 0,
        },
        Plan: 48.38,
        Fact: 28.83,
        Deviation: 19.55,
      },
      {
        Date: {
          seconds: "1696377600",
          nanos: 0,
        },
        Plan: 48.57,
        Fact: 28.83,
        Deviation: 19.74,
      },
      {
        Date: {
          seconds: "1696464000",
          nanos: 0,
        },
        Plan: 48.76,
        Fact: 28.83,
        Deviation: 19.93,
      },
      {
        Date: {
          seconds: "1696550400",
          nanos: 0,
        },
        Plan: 48.95,
        Fact: 30.46,
        Deviation: 18.49,
      },
      {
        Date: {
          seconds: "1696809600",
          nanos: 0,
        },
        Plan: 49.52,
        Fact: 31.75,
        Deviation: 17.77,
      },
      {
        Date: {
          seconds: "1696896000",
          nanos: 0,
        },
        Plan: 49.71,
        Fact: 32.66,
        Deviation: 17.05,
      },
      {
        Date: {
          seconds: "1696982400",
          nanos: 0,
        },
        Plan: 48.73,
        Fact: 32.91,
        Deviation: 15.82,
      },
      {
        Date: {
          seconds: "1697068800",
          nanos: 0,
        },
        Plan: 48.92,
        Fact: 32.93,
        Deviation: 15.99,
      },
      {
        Date: {
          seconds: "1697155200",
          nanos: 0,
        },
        Plan: 49.12,
        Fact: 32.93,
        Deviation: 16.19,
      },
      {
        Date: {
          seconds: "1697414400",
          nanos: 0,
        },
        Plan: 49.71,
        Fact: 34.55,
        Deviation: 15.16,
      },
      {
        Date: {
          seconds: "1697500800",
          nanos: 0,
        },
        Plan: 49.9,
        Fact: 34.55,
        Deviation: 15.35,
      },
      {
        Date: {
          seconds: "1697587200",
          nanos: 0,
        },
        Plan: 50.09,
        Fact: 33.7,
        Deviation: 16.39,
      },
      {
        Date: {
          seconds: "1697673600",
          nanos: 0,
        },
        Plan: 50.29,
        Fact: 33.7,
        Deviation: 16.59,
      },
      {
        Date: {
          seconds: "1697760000",
          nanos: 0,
        },
        Plan: 50.48,
        Fact: 34.21,
        Deviation: 16.27,
      },
      {
        Date: {
          seconds: "1698019200",
          nanos: 0,
        },
        Plan: 51.07,
        Fact: 35.1,
        Deviation: 15.97,
      },
      {
        Date: {
          seconds: "1698105600",
          nanos: 0,
        },
        Plan: 51.26,
        Fact: 35.1,
        Deviation: 16.16,
      },
      {
        Date: {
          seconds: "1698192000",
          nanos: 0,
        },
        Plan: 51.46,
        Fact: 34.62,
        Deviation: 16.84,
      },
      {
        Date: {
          seconds: "1698278400",
          nanos: 0,
        },
        Plan: 51.65,
        Fact: 34.62,
        Deviation: 17.03,
      },
      {
        Date: {
          seconds: "1698364800",
          nanos: 0,
        },
        Plan: 51.85,
        Fact: 34.54,
        Deviation: 17.31,
      },
      {
        Date: {
          seconds: "1698624000",
          nanos: 0,
        },
        Plan: 52.43,
        Fact: 35.7,
        Deviation: 16.73,
      },
      {
        Date: {
          seconds: "1698710400",
          nanos: 0,
        },
        Plan: 52.63,
        Fact: 35.7,
        Deviation: 16.93,
      },
      {
        Date: {
          seconds: "1698796800",
          nanos: 0,
        },
        Plan: 52.82,
        Fact: 35.7,
        Deviation: 17.12,
      },
      {
        Date: {
          seconds: "1698883200",
          nanos: 0,
        },
        Plan: 53.02,
        Fact: 36.35,
        Deviation: 16.67,
      },
      {
        Date: {
          seconds: "1698969600",
          nanos: 0,
        },
        Plan: 53.21,
        Fact: 37.03,
        Deviation: 16.18,
      },
      {
        Date: {
          seconds: "1699228800",
          nanos: 0,
        },
        Plan: 53.8,
        Fact: 37.64,
        Deviation: 16.16,
      },
      {
        Date: {
          seconds: "1699315200",
          nanos: 0,
        },
        Plan: 53.99,
        Fact: 37.64,
        Deviation: 16.35,
      },
      {
        Date: {
          seconds: "1699401600",
          nanos: 0,
        },
        Plan: 54.19,
        Fact: 37.64,
        Deviation: 16.55,
      },
      {
        Date: {
          seconds: "1699488000",
          nanos: 0,
        },
        Plan: 54.38,
        Fact: 39.01,
        Deviation: 15.37,
      },
      {
        Date: {
          seconds: "1699574400",
          nanos: 0,
        },
        Plan: 54.58,
        Fact: 39.07,
        Deviation: 15.51,
      },
      {
        Date: {
          seconds: "1699833600",
          nanos: 0,
        },
        Plan: 55.16,
        Fact: 39.66,
        Deviation: 15.5,
      },
      {
        Date: {
          seconds: "1699920000",
          nanos: 0,
        },
        Plan: 55.36,
        Fact: 39.66,
        Deviation: 15.7,
      },
      {
        Date: {
          seconds: "1700006400",
          nanos: 0,
        },
        Plan: 55.55,
        Fact: 39.66,
        Deviation: 15.89,
      },
      {
        Date: {
          seconds: "1700092800",
          nanos: 0,
        },
        Plan: 55.75,
        Fact: 39.66,
        Deviation: 16.09,
      },
      {
        Date: {
          seconds: "1700179200",
          nanos: 0,
        },
        Plan: 55.94,
        Fact: 40,
        Deviation: 15.94,
      },
      {
        Date: {
          seconds: "1700438400",
          nanos: 0,
        },
        Plan: 56.53,
        Fact: 40.76,
        Deviation: 15.77,
      },
      {
        Date: {
          seconds: "1700524800",
          nanos: 0,
        },
        Plan: 56.72,
        Fact: 40.76,
        Deviation: 15.96,
      },
      {
        Date: {
          seconds: "1700611200",
          nanos: 0,
        },
        Plan: 56.92,
        Fact: 40.76,
        Deviation: 16.16,
      },
      {
        Date: {
          seconds: "1700697600",
          nanos: 0,
        },
        Plan: 57.11,
        Fact: 40.76,
        Deviation: 16.35,
      },
      {
        Date: {
          seconds: "1700784000",
          nanos: 0,
        },
        Plan: 57.3,
        Fact: 40.76,
        Deviation: 16.54,
      },
      {
        Date: {
          seconds: "1701043200",
          nanos: 0,
        },
        Plan: 57.89,
        Fact: 40.76,
        Deviation: 17.13,
      },
      {
        Date: {
          seconds: "1701129600",
          nanos: 0,
        },
        Plan: 58.08,
        Fact: 40.76,
        Deviation: 17.32,
      },
      {
        Date: {
          seconds: "1701216000",
          nanos: 0,
        },
        Plan: 100,
        Fact: 34.29,
        Deviation: 65.71,
      },
      {
        Date: {
          seconds: "1701302400",
          nanos: 0,
        },
        Plan: 100,
        Fact: 35.23,
        Deviation: 64.77,
      },
      {
        Date: {
          seconds: "1701388800",
          nanos: 0,
        },
        Plan: 100,
        Fact: 35.48,
        Deviation: 64.52,
      },
      {
        Date: {
          seconds: "1701648000",
          nanos: 0,
        },
        Plan: 100,
        Fact: 40.99,
        Deviation: 59.01,
      },
      {
        Date: {
          seconds: "1701734400",
          nanos: 0,
        },
        Plan: 100,
        Fact: 40.99,
        Deviation: 59.01,
      },
      {
        Date: {
          seconds: "1701820800",
          nanos: 0,
        },
        Plan: 61.23,
        Fact: 41.04,
        Deviation: 20.19,
      },
      {
        Date: {
          seconds: "1701907200",
          nanos: 0,
        },
        Plan: 61.42,
        Fact: 41.06,
        Deviation: 20.36,
      },
      {
        Date: {
          seconds: "1701993600",
          nanos: 0,
        },
        Plan: 61.61,
        Fact: 42.42,
        Deviation: 19.19,
      },
      {
        Date: {
          seconds: "1702252800",
          nanos: 0,
        },
        Plan: 62.17,
        Fact: 42.42,
        Deviation: 19.75,
      },
      {
        Date: {
          seconds: "1702339200",
          nanos: 0,
        },
        Plan: 62.35,
        Fact: 43.21,
        Deviation: 19.14,
      },
      {
        Date: {
          seconds: "1702425600",
          nanos: 0,
        },
        Plan: 62.54,
        Fact: 49.71,
        Deviation: 12.83,
      },
      {
        Date: {
          seconds: "1702512000",
          nanos: 0,
        },
        Plan: 62.73,
        Fact: 49.71,
        Deviation: 13.02,
      },
      {
        Date: {
          seconds: "1702598400",
          nanos: 0,
        },
        Plan: 62.92,
        Fact: 49.71,
        Deviation: 13.21,
      },
      {
        Date: {
          seconds: "1702857600",
          nanos: 0,
        },
        Plan: 63.48,
        Fact: 49.71,
        Deviation: 13.77,
      },
      {
        Date: {
          seconds: "1702944000",
          nanos: 0,
        },
        Plan: 63.67,
        Fact: 49.71,
        Deviation: 13.96,
      },
      {
        Date: {
          seconds: "1703030400",
          nanos: 0,
        },
        Plan: 63.85,
        Fact: 49.71,
        Deviation: 14.14,
      },
      {
        Date: {
          seconds: "1703116800",
          nanos: 0,
        },
        Plan: 64.04,
        Fact: 52.29,
        Deviation: 11.75,
      },
      {
        Date: {
          seconds: "1703203200",
          nanos: 0,
        },
        Plan: 64.23,
        Fact: 53.16,
        Deviation: 11.07,
      },
      {
        Date: {
          seconds: "1703462400",
          nanos: 0,
        },
        Plan: 64.79,
        Fact: 53.16,
        Deviation: 11.63,
      },
      {
        Date: {
          seconds: "1703548800",
          nanos: 0,
        },
        Plan: 64.98,
        Fact: 53.91,
        Deviation: 11.07,
      },
      {
        Date: {
          seconds: "1703635200",
          nanos: 0,
        },
        Plan: 65.16,
        Fact: 54.04,
        Deviation: 11.12,
      },
      {
        Date: {
          seconds: "1703721600",
          nanos: 0,
        },
        Plan: 65.35,
        Fact: 54.79,
        Deviation: 10.56,
      },
      {
        Date: {
          seconds: "1703808000",
          nanos: 0,
        },
        Plan: 65.54,
        Fact: 54.79,
        Deviation: 10.75,
      },
      {
        Date: {
          seconds: "1704067200",
          nanos: 0,
        },
        Plan: 66.1,
        Fact: 54.79,
        Deviation: 11.31,
      },
      {
        Date: {
          seconds: "1704153600",
          nanos: 0,
        },
        Plan: 66.29,
        Fact: 54.79,
        Deviation: 11.5,
      },
      {
        Date: {
          seconds: "1704240000",
          nanos: 0,
        },
        Plan: 66.47,
        Fact: 54.79,
        Deviation: 11.68,
      },
      {
        Date: {
          seconds: "1704326400",
          nanos: 0,
        },
        Plan: 66.66,
        Fact: 54.79,
        Deviation: 11.87,
      },
      {
        Date: {
          seconds: "1704412800",
          nanos: 0,
        },
        Plan: 66.85,
        Fact: 54.79,
        Deviation: 12.06,
      },
      {
        Date: {
          seconds: "1704672000",
          nanos: 0,
        },
        Plan: 67.41,
        Fact: 55.43,
        Deviation: 11.98,
      },
      {
        Date: {
          seconds: "1704758400",
          nanos: 0,
        },
        Plan: 67.6,
        Fact: 55.43,
        Deviation: 12.17,
      },
      {
        Date: {
          seconds: "1704844800",
          nanos: 0,
        },
        Plan: 67.79,
        Fact: 55.63,
        Deviation: 12.16,
      },
      {
        Date: {
          seconds: "1704931200",
          nanos: 0,
        },
        Plan: 67.97,
        Fact: 56.39,
        Deviation: 11.58,
      },
      {
        Date: {
          seconds: "1705017600",
          nanos: 0,
        },
        Plan: 68.16,
        Fact: 56.39,
        Deviation: 11.77,
      },
      {
        Date: {
          seconds: "1705276800",
          nanos: 0,
        },
        Plan: 68.72,
        Fact: 56.39,
        Deviation: 12.33,
      },
      {
        Date: {
          seconds: "1705363200",
          nanos: 0,
        },
        Plan: 68.91,
        Fact: 56.39,
        Deviation: 12.52,
      },
      {
        Date: {
          seconds: "1705449600",
          nanos: 0,
        },
        Plan: 69.1,
        Fact: 57,
        Deviation: 12.1,
      },
      {
        Date: {
          seconds: "1705536000",
          nanos: 0,
        },
        Plan: 69.28,
        Fact: 58.28,
        Deviation: 11,
      },
      {
        Date: {
          seconds: "1705622400",
          nanos: 0,
        },
        Plan: 69.47,
        Fact: 57.65,
        Deviation: 11.82,
      },
      {
        Date: {
          seconds: "1705881600",
          nanos: 0,
        },
        Plan: 70.03,
        Fact: 57.65,
        Deviation: 12.38,
      },
      {
        Date: {
          seconds: "1705968000",
          nanos: 0,
        },
        Plan: 70.22,
        Fact: 57.65,
        Deviation: 12.57,
      },
      {
        Date: {
          seconds: "1706054400",
          nanos: 0,
        },
        Plan: 70.41,
        Fact: 58.77,
        Deviation: 11.64,
      },
      {
        Date: {
          seconds: "1706140800",
          nanos: 0,
        },
        Plan: 70.59,
        Fact: 59.63,
        Deviation: 10.96,
      },
      {
        Date: {
          seconds: "1706227200",
          nanos: 0,
        },
        Plan: 70.78,
        Fact: 59.63,
        Deviation: 11.15,
      },
      {
        Date: {
          seconds: "1706486400",
          nanos: 0,
        },
        Plan: 71.34,
        Fact: 59.63,
        Deviation: 11.71,
      },
      {
        Date: {
          seconds: "1706572800",
          nanos: 0,
        },
        Plan: 71.53,
        Fact: 59.63,
        Deviation: 11.9,
      },
      {
        Date: {
          seconds: "1706659200",
          nanos: 0,
        },
        Plan: 71.72,
        Fact: 60.97,
        Deviation: 10.75,
      },
      {
        Date: {
          seconds: "1706745600",
          nanos: 0,
        },
        Plan: 71.91,
        Fact: 62.2,
        Deviation: 9.71,
      },
      {
        Date: {
          seconds: "1706832000",
          nanos: 0,
        },
        Plan: 72.09,
        Fact: 62.2,
        Deviation: 9.89,
      },
      {
        Date: {
          seconds: "1707091200",
          nanos: 0,
        },
        Plan: 72.65,
        Fact: 62.2,
        Deviation: 10.45,
      },
      {
        Date: {
          seconds: "1707177600",
          nanos: 0,
        },
        Plan: 72.84,
        Fact: 62.2,
        Deviation: 10.64,
      },
      {
        Date: {
          seconds: "1707264000",
          nanos: 0,
        },
        Plan: 73.03,
        Fact: 63.46,
        Deviation: 9.57,
      },
      {
        Date: {
          seconds: "1707350400",
          nanos: 0,
        },
        Plan: 73.22,
        Fact: 65.24,
        Deviation: 7.98,
      },
      {
        Date: {
          seconds: "1707436800",
          nanos: 0,
        },
        Plan: 73.4,
        Fact: 65.24,
        Deviation: 8.16,
      },
      {
        Date: {
          seconds: "1707696000",
          nanos: 0,
        },
        Plan: 73.97,
        Fact: 65.24,
        Deviation: 8.73,
      },
      {
        Date: {
          seconds: "1707782400",
          nanos: 0,
        },
        Plan: 74.15,
        Fact: 65.24,
        Deviation: 8.91,
      },
      {
        Date: {
          seconds: "1707868800",
          nanos: 0,
        },
        Plan: 74.34,
        Fact: 66.29,
        Deviation: 8.05,
      },
      {
        Date: {
          seconds: "1707955200",
          nanos: 0,
        },
        Plan: 74.53,
        Fact: 67.36,
        Deviation: 7.17,
      },
      {
        Date: {
          seconds: "1708041600",
          nanos: 0,
        },
        Plan: 74.71,
        Fact: 67.36,
        Deviation: 7.35,
      },
      {
        Date: {
          seconds: "1708300800",
          nanos: 0,
        },
        Plan: 75.28,
        Fact: 67.36,
        Deviation: 7.92,
      },
      {
        Date: {
          seconds: "1708387200",
          nanos: 0,
        },
        Plan: 75.46,
        Fact: 67.36,
        Deviation: 8.1,
      },
      {
        Date: {
          seconds: "1708473600",
          nanos: 0,
        },
        Plan: 75.65,
        Fact: 68.62,
        Deviation: 7.03,
      },
      {
        Date: {
          seconds: "1708560000",
          nanos: 0,
        },
        Plan: 75.84,
        Fact: 69.58,
        Deviation: 6.26,
      },
      {
        Date: {
          seconds: "1708646400",
          nanos: 0,
        },
        Plan: 76.02,
        Fact: 69.58,
        Deviation: 6.44,
      },
      {
        Date: {
          seconds: "1708905600",
          nanos: 0,
        },
        Plan: 76.59,
        Fact: 69.58,
        Deviation: 7.01,
      },
      {
        Date: {
          seconds: "1708992000",
          nanos: 0,
        },
        Plan: 74.14,
        Fact: 69.41,
        Deviation: 4.73,
      },
      {
        Date: {
          seconds: "1709078400",
          nanos: 0,
        },
        Plan: 74.32,
        Fact: 69.86,
        Deviation: 4.46,
      },
      {
        Date: {
          seconds: "1709164800",
          nanos: 0,
        },
        Plan: 77.15,
        Fact: 70.88,
        Deviation: 6.27,
      },
      {
        Date: {
          seconds: "1709251200",
          nanos: 0,
        },
        Plan: 77.34,
        Fact: 70.88,
        Deviation: 6.46,
      },
      {
        Date: {
          seconds: "1709510400",
          nanos: 0,
        },
        Plan: 77.9,
        Fact: 70.88,
        Deviation: 7.02,
      },
      {
        Date: {
          seconds: "1709596800",
          nanos: 0,
        },
        Plan: 78.08,
        Fact: 70.88,
        Deviation: 7.2,
      },
      {
        Date: {
          seconds: "1709683200",
          nanos: 0,
        },
        Plan: 78.27,
        Fact: 71.67,
        Deviation: 6.6,
      },
      {
        Date: {
          seconds: "1709769600",
          nanos: 0,
        },
        Plan: 78.46,
        Fact: 72.59,
        Deviation: 5.87,
      },
      {
        Date: {
          seconds: "1709856000",
          nanos: 0,
        },
        Plan: 78.65,
        Fact: 72.59,
        Deviation: 6.06,
      },
      {
        Date: {
          seconds: "1710115200",
          nanos: 0,
        },
        Plan: 79.21,
        Fact: 72.59,
        Deviation: 6.62,
      },
      {
        Date: {
          seconds: "1710201600",
          nanos: 0,
        },
        Plan: 79.4,
        Fact: 72.59,
        Deviation: 6.81,
      },
      {
        Date: {
          seconds: "1710288000",
          nanos: 0,
        },
        Plan: 79.58,
        Fact: 73.13,
        Deviation: 6.45,
      },
      {
        Date: {
          seconds: "1710374400",
          nanos: 0,
        },
        Plan: 79.77,
        Fact: 73.84,
        Deviation: 5.93,
      },
      {
        Date: {
          seconds: "1710460800",
          nanos: 0,
        },
        Plan: 79.96,
        Fact: 73.84,
        Deviation: 6.12,
      },
      {
        Date: {
          seconds: "1710720000",
          nanos: 0,
        },
        Plan: 80.52,
        Fact: 73.84,
        Deviation: 6.68,
      },
      {
        Date: {
          seconds: "1710806400",
          nanos: 0,
        },
        Plan: 80.71,
        Fact: 73.84,
        Deviation: 6.87,
      },
      {
        Date: {
          seconds: "1710892800",
          nanos: 0,
        },
        Plan: 80.89,
        Fact: 75.5,
        Deviation: 5.39,
      },
      {
        Date: {
          seconds: "1710979200",
          nanos: 0,
        },
        Plan: 81.08,
        Fact: 75.5,
        Deviation: 5.58,
      },
      {
        Date: {
          seconds: "1711065600",
          nanos: 0,
        },
        Plan: 81.27,
        Fact: 75.5,
        Deviation: 5.77,
      },
      {
        Date: {
          seconds: "1711324800",
          nanos: 0,
        },
        Plan: 81.83,
        Fact: 75.5,
        Deviation: 6.33,
      },
      {
        Date: {
          seconds: "1711411200",
          nanos: 0,
        },
        Plan: 82.02,
        Fact: 75.5,
        Deviation: 6.52,
      },
      {
        Date: {
          seconds: "1711497600",
          nanos: 0,
        },
        Plan: 82.2,
        Fact: 76.66,
        Deviation: 5.54,
      },
      {
        Date: {
          seconds: "1711584000",
          nanos: 0,
        },
        Plan: 82.39,
        Fact: 77.7,
        Deviation: 4.69,
      },
      {
        Date: {
          seconds: "1711670400",
          nanos: 0,
        },
        Plan: 82.58,
        Fact: 77.7,
        Deviation: 4.88,
      },
      {
        Date: {
          seconds: "1711929600",
          nanos: 0,
        },
        Plan: 83.14,
        Fact: 77.7,
        Deviation: 5.44,
      },
      {
        Date: {
          seconds: "1712016000",
          nanos: 0,
        },
        Plan: 83.33,
        Fact: 77.7,
        Deviation: 5.63,
      },
      {
        Date: {
          seconds: "1712102400",
          nanos: 0,
        },
        Plan: 83.52,
        Fact: 78.43,
        Deviation: 5.09,
      },
      {
        Date: {
          seconds: "1712188800",
          nanos: 0,
        },
        Plan: 83.7,
        Fact: 79.26,
        Deviation: 4.44,
      },
      {
        Date: {
          seconds: "1712275200",
          nanos: 0,
        },
        Plan: 83.89,
        Fact: 79.26,
        Deviation: 4.63,
      },
      {
        Date: {
          seconds: "1712534400",
          nanos: 0,
        },
        Plan: 84.45,
        Fact: 79.26,
        Deviation: 5.19,
      },
      {
        Date: {
          seconds: "1712620800",
          nanos: 0,
        },
        Plan: 84.64,
        Fact: 79.26,
        Deviation: 5.38,
      },
      {
        Date: {
          seconds: "1712707200",
          nanos: 0,
        },
        Plan: 84.83,
        Fact: 80.46,
        Deviation: 4.37,
      },
      {
        Date: {
          seconds: "1712793600",
          nanos: 0,
        },
        Plan: 82.69,
        Fact: 81.34,
        Deviation: 1.35,
      },
      {
        Date: {
          seconds: "1712880000",
          nanos: 0,
        },
        Plan: 82.87,
        Fact: 82.49,
        Deviation: 0.38,
      },
      {
        Date: {
          seconds: "1713225600",
          nanos: 0,
        },
        Plan: 83.6,
        Fact: 82.49,
        Deviation: 1.11,
      },
      {
        Date: {
          seconds: "1713312000",
          nanos: 0,
        },
        Plan: 83.78,
        Fact: 82.49,
        Deviation: 1.29,
      },
      {
        Date: {
          seconds: "1713398400",
          nanos: 0,
        },
        Plan: 83.97,
        Fact: 83.77,
        Deviation: 0.2,
      },
      {
        Date: {
          seconds: "1713484800",
          nanos: 0,
        },
        Plan: 84.15,
        Fact: 83.99,
        Deviation: 0.16,
      },
      {
        Date: {
          seconds: "1713916800",
          nanos: 0,
        },
        Plan: 85.06,
        Fact: 86.49,
        Deviation: -1.43,
      },
      {
        Date: {
          seconds: "1714003200",
          nanos: 0,
        },
        Plan: 85.24,
        Fact: 86.49,
        Deviation: -1.25,
      },
      {
        Date: {
          seconds: "1714089600",
          nanos: 0,
        },
        Plan: 85.42,
        Fact: 86.49,
        Deviation: -1.07,
      },
      {
        Date: {
          seconds: "1714608000",
          nanos: 0,
        },
        Plan: 86.52,
        Fact: 86.49,
        Deviation: 0.03,
      },
      {
        Date: {
          seconds: "1714694400",
          nanos: 0,
        },
        Plan: 86.7,
        Fact: 86.49,
        Deviation: 0.21,
      },
      {
        Date: {
          seconds: "1714953600",
          nanos: 0,
        },
        Plan: 87.24,
        Fact: 86.49,
        Deviation: 0.75,
      },
      {
        Date: {
          seconds: "1715040000",
          nanos: 0,
        },
        Plan: 87.43,
        Fact: 90.44,
        Deviation: -3.01,
      },
      {
        Date: {
          seconds: "1715126400",
          nanos: 0,
        },
        Plan: 87.61,
        Fact: 90.44,
        Deviation: -2.83,
      },
      {
        Date: {
          seconds: "1715644800",
          nanos: 0,
        },
        Plan: 88.72,
        Fact: 88.61,
        Deviation: 0.11,
      },
      {
        Date: {
          seconds: "1715731200",
          nanos: 0,
        },
        Plan: 88.9,
        Fact: 88.61,
        Deviation: 0.29,
      },
      {
        Date: {
          seconds: "1715817600",
          nanos: 0,
        },
        Plan: 97.02,
        Fact: 96.97,
        Deviation: 0.05,
      },
      {
        Date: {
          seconds: "1715904000",
          nanos: 0,
        },
        Plan: 97.22,
        Fact: 96.97,
        Deviation: 0.25,
      },
      {
        Date: {
          seconds: "1716163200",
          nanos: 0,
        },
        Plan: 97.82,
        Fact: 96.97,
        Deviation: 0.85,
      },
      {
        Date: {
          seconds: "1716249600",
          nanos: 0,
        },
        Plan: 98.01,
        Fact: 96.97,
        Deviation: 1.04,
      },
      {
        Date: {
          seconds: "1716336000",
          nanos: 0,
        },
        Plan: 98.21,
        Fact: 96.98,
        Deviation: 1.23,
      },
      {
        Date: {
          seconds: "1716422400",
          nanos: 0,
        },
        Plan: 98.41,
        Fact: 97.48,
        Deviation: 0.93,
      },
      {
        Date: {
          seconds: "1716508800",
          nanos: 0,
        },
        Plan: 98.61,
        Fact: 97.63,
        Deviation: 0.98,
      },
      {
        Date: {
          seconds: "1716768000",
          nanos: 0,
        },
        Plan: 99.2,
        Fact: 97.63,
        Deviation: 1.57,
      },
      {
        Date: {
          seconds: "1716854400",
          nanos: 0,
        },
        Plan: 99.4,
        Fact: 97.63,
        Deviation: 1.77,
      },
      {
        Date: {
          seconds: "1716940800",
          nanos: 0,
        },
        Plan: 99.6,
        Fact: 98.14,
        Deviation: 1.46,
      },
      {
        Date: {
          seconds: "1717027200",
          nanos: 0,
        },
        Plan: 99.8,
        Fact: 98.3,
        Deviation: 1.5,
      },
      {
        Date: {
          seconds: "1717113600",
          nanos: 0,
        },
        Plan: 100,
        Fact: 98.3,
        Deviation: 1.7,
      },
      {
        Date: {
          seconds: "1717372800",
          nanos: 0,
        },
        Plan: 95.84,
        Fact: 98.1,
        Deviation: -2.26,
      },
      {
        Date: {
          seconds: "1717459200",
          nanos: 0,
        },
        Plan: 96.03,
        Fact: 98.49,
        Deviation: -2.46,
      },
      {
        Date: {
          seconds: "1717545600",
          nanos: 0,
        },
        Plan: 96.22,
        Fact: 98.61,
        Deviation: -2.39,
      },
      {
        Date: {
          seconds: "1717632000",
          nanos: 0,
        },
        Plan: 96.41,
        Fact: 98.61,
        Deviation: -2.2,
      },
      {
        Date: {
          seconds: "1717718400",
          nanos: 0,
        },
        Plan: 96.6,
        Fact: 98.62,
        Deviation: -2.02,
      },
      {
        Date: {
          seconds: "1717977600",
          nanos: 0,
        },
        Plan: 97.16,
        Fact: 98.62,
        Deviation: -1.46,
      },
      {
        Date: {
          seconds: "1718064000",
          nanos: 0,
        },
        Plan: 97.35,
        Fact: 98.67,
        Deviation: -1.32,
      },
      {
        Date: {
          seconds: "1718150400",
          nanos: 0,
        },
        Plan: 97.54,
        Fact: 98.67,
        Deviation: -1.13,
      },
      {
        Date: {
          seconds: "1718236800",
          nanos: 0,
        },
        Plan: 97.73,
        Fact: 98.67,
        Deviation: -0.94,
      },
      {
        Date: {
          seconds: "1718323200",
          nanos: 0,
        },
        Plan: 97.92,
        Fact: 98.67,
        Deviation: -0.75,
      },
      {
        Date: {
          seconds: "1718582400",
          nanos: 0,
        },
        Plan: 98.49,
        Fact: 98.67,
        Deviation: -0.18,
      },
      {
        Date: {
          seconds: "1718668800",
          nanos: 0,
        },
        Plan: 98.67,
        Fact: 98.96,
        Deviation: -0.29,
      },
      {
        Date: {
          seconds: "1718755200",
          nanos: 0,
        },
        Plan: 98.86,
        Fact: 99.23,
        Deviation: -0.37,
      },
      {
        Date: {
          seconds: "1718841600",
          nanos: 0,
        },
        Plan: 81.64,
        Fact: 73.48,
        Deviation: 8.16,
      },
      {
        Date: {
          seconds: "1718928000",
          nanos: 0,
        },
        Plan: 81.8,
        Fact: 73.48,
        Deviation: 8.32,
      },
      {
        Date: {
          seconds: "1719273600",
          nanos: 0,
        },
        Plan: 82.42,
        Fact: 73.48,
        Deviation: 8.94,
      },
      {
        Date: {
          seconds: "1719360000",
          nanos: 0,
        },
        Plan: 82.58,
        Fact: 73.48,
        Deviation: 9.1,
      },
      {
        Date: {
          seconds: "1719446400",
          nanos: 0,
        },
        Plan: 82.73,
        Fact: 73.48,
        Deviation: 9.25,
      },
      {
        Date: {
          seconds: "1719532800",
          nanos: 0,
        },
        Plan: 82.89,
        Fact: 73.48,
        Deviation: 9.41,
      },
      {
        Date: {
          seconds: "1730073600",
          nanos: 0,
        },
        Plan: 100,
        Fact: 68.18,
        Deviation: 31.82,
      },
    ],
    PackageChart: [],
    MaterialChart: [],
  },
};

const getters = {
  productivity: (state) => state.productivityData,
};

const actions = {
  async updateData({ commit }, id, data) {
    await commit("updateProductivityData", id, data);
  },
  async setData({ commit }, objects) {
    await commit("setProductivityData", objects);
  },
};

const mutations = {
  updateProductivityData(state, id, data) {
    state.productivityData[id] = data;
  },
  setProductivityData(state, objects) {
    state.productivityData = objects;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
