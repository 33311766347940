/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'


import { md3 } from 'vuetify/blueprints'
// import colors from 'vuetify/lib/util/colors'

// Translation Files
import en from '@/i18n/en.json'

// Тhemes
const asicTheme = {
  dark: true,
  colors: {
    surface: '#2f27ce',
    text_50: '#eae9fc',
    text_100: '#d6d2f9',
    text_200: '#ada5f3',
    text_300: '#8478ed',
    text_400: '#5b4be7',
    text_500: '#321fe0',
    text_600: '#2818b4',
    text_700: '#1e1287',
    text_800: '#140c5a',
    text_900: '#0a062d',
    text_950: '#050316',
    background_50: '#ebebfa',
    background_100: '#d6d6f5',
    background_200: '#adadeb',
    background_300: '#8585e0',
    background_400: '#5c5cd6',
    background_500: '#3333cc',
    background_600: '#2929a3',
    background_700: '#1f1f7a',
    background_800: '#141452',
    background_900: '#0a0a29',
    background_950: '#050514',
    primary_50: '#eaeafb',
    primary_100: '#d6d4f7',
    primary_200: '#ada9ef',
    primary_300: '#847ee7',
    primary_400: '#5b54de',
    primary_500: '#3129d6',
    primary_600: '#2821ab',
    primary_700: '#1e1881',
    primary_800: '#141056',
    primary_900: '#0a082b',
    primary_950: '#050415',
    secondary_50: '#e7e5ff',
    secondary_100: '#cfccff',
    secondary_200: '#9e99ff',
    secondary_300: '#6e66ff',
    secondary_400: '#3d33ff',
    secondary_500: '#0d00ff',
    secondary_600: '#0a00cc',
    secondary_700: '#080099',
    secondary_800: '#050066',
    secondary_900: '#030033',
    secondary_950: '#01001a',
    accent_50: '#e9fbf7',
    accent_100: '#d3f8f0',
    accent_200: '#a8f0e1',
    accent_300: '#7ce9d1',
    accent_400: '#51e1c2',
    accent_500: '#25dab3',
    accent_600: '#1eae8f',
    accent_700: '#16836b',
    accent_800: '#0f5748',
    accent_900: '#072c24',
    accent_950: '#041612',

    button_light_bg: "#F4F4F433",
    background: '#001825',
    form_background: '#ffffff',
    card_background: '#001825',
    card_background_transp: 'rgba(0,0,0,1)',
    primary_light: '#00A3FF',
    primary: '#006AA6',
    primary_gradient: '#004B76',
    secondary: '#FFFFFF',
    accent: '#188e74',
    text: '#050315',

  }
}

// Typography
// const typography = {
//   h1: { fontSize: '40px', fontWeight: 500, lineHeight: '26px', letterSpacing: '-0.015em' },
//   h2: { fontSize: '40px', fontWeight: 500, lineHeight: '48px', letterSpacing: '-0.008em' },
//   h3: { fontSize: '32px', fontWeight: 500, lineHeight: '40px', letterSpacing: '0' },
//   h4: { fontSize: '24px', fontWeight: 500, lineHeight: '32px', letterSpacing: '0.015em' },
//   h5: { fontSize: '20px', fontWeight: 500, lineHeight: '28px', letterSpacing: '0' },
//   h6: { fontSize: '18px', fontWeight: 500, lineHeight: '24px', letterSpacing: '0.031em' },
// }

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  ssr: true,
  locale: {
    locale: 'en',
    fallback: 'en',
    messages: { en }
  },
  defaults: {
    global: {
      ripple: false
    },
    VSheet: {
      elevation: 0
    }
  },
  blueprint: md3,
  theme: {
    defaultTheme: 'asicTheme',
    themes: {
      asicTheme
    },
    // typography: typography
  },
  components: {
    // VDataTable
  }
})
