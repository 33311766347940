<script setup>
import { useStore } from "vuex";
defineProps({
  icon: String,
  img: String,
  title: String,
  subtitle: String,
  data: Object,
  isLoading: Boolean,
});
const store = useStore();

const getArrowImage = (direction) => {
  return direction
    ? new URL("@/assets/icons/red_arrow.svg", import.meta.url).href
    : new URL("@/assets/icons/green_arrow.svg", import.meta.url).href;
};

const goToSubLocationDeviation = async (index) => {
  try {
    await store.dispatch("appData/setCurrentLocationId", index);
    await store.dispatch("clickedButton/updateClickedButton", "СРОКИ");
    await store.dispatch("currentIndex/updateCurrentIndex", 3);
  } catch (error) {
    console.error("Error occurred:", error);
  }
};
</script>

<template>
  <v-card color="transparent" class="card pr-5" rounded="lg" width="100%">
    <v-row align="center" position="fixed" class="title-wrapper pb-3">
      <!-- Icon -->
      <v-col cols="1 mr-5" class="pa-0 ma-0">
        <img :src="icon" alt="Icon" class="responsive-icon ma-3" />
      </v-col>

      <!-- Title -->
      <v-col cols="9" class="pl-0">
        <v-row>
          <v-card-text class="responsive-text">
            {{ title }}
            <span class="gray-text">{{ subtitle }}</span>
          </v-card-text>
        </v-row>
      </v-col>
    </v-row>

    <!-- Main -->
    <v-card
      class="overflow-y-auto scroll pr-5 responsive-main-card"
      color="transparent"
      height="20.9vh"
    >
      <v-progress-linear
        color="blue"
        indeterminate
        v-if="isLoading"
      ></v-progress-linear>
      <v-row
        v-else
        v-for="(item, index) in data"
        :key="index"
        align="center"
        justify="center"
        class="pa-0 ma-0 responsive-card"
        @click="goToSubLocationDeviation(item.id)"
      >
        <v-col class="col-1 pa-0">
          <span class="number" color="#00A3FF">{{ index + 1 }}</span>
        </v-col>
        <v-col class="col-2 pa-0">
          <v-img :src="img" width="100%" class="responsive-img" />
        </v-col>
        <v-col class="text-uppercase pa-0">
          <span class="main-card-text">{{
            item.name || item.contractorname
          }}</span>
        </v-col>
        <v-col class="col-4">
          <v-row align="center" justify="end">
            <span class="mr-1 main-card-text">
              {{ item.currentdynamics?.value }}
            </span>
            <img :src="getArrowImage(item.currentdynamics?.direction)" />
          </v-row>
        </v-col>

        <v-divider
          v-if="index !== data.length - 1"
          class="border-opacity-100 custom-divider"
          inset
        ></v-divider>
      </v-row>
    </v-card>
  </v-card>
</template>

<style scoped>
.card {
  border: 1px solid #00a3ff;
  padding: 10px;
  box-shadow: inset 0px 0px 15px 5px #00466e;
}
.text-title {
  font-size: 18px;
}
.number {
  background-color: #00466e;
  height: 2.5vw;
  font-size: 0.7em;
  color: #00a3ff;
  background-color: #00466e;
  padding: 0.85vw 0.2vw;
}
.custom-divider {
  margin-left: 6.5vw;
}
.responsive-card {
  padding: 0.5vw 0 !important;
  padding-left: 0.5vw !important;
  cursor: pointer;
}
.responsive-card:hover {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}
.responsive-icon {
  max-width: 100%;
  height: auto;
}
.responsive-img {
  height: 2.5vw;
}
.responsive-text {
  font-size: 1vw;
}
.gray-text {
  color: gray;
  font-size: 1vw;
}
.responsive-main-card {
  height: 100%;
}
.main-card-text {
  font-size: 0.85vw;
}
@media (min-width: 1920px) {
  .main-card-text {
    font-size: 0.7vw;
  }
  .responsive-img {
    height: 2vw;
  }
}
.col-1 {
  flex-basis: 2%;
  max-width: 2%;
}
.col-2 {
  flex-basis: 25%;
  max-width: 25%;
}
.col-3 {
  flex-basis: 30%;
  max-width: 30%;
}
.col-4 {
  flex-basis: 12%;
  max-width: 12%;
}
:deep(.v-card--variant-elevated) {
  box-shadow: none;
}
.scroll::-webkit-scrollbar-track {
  margin: 15px;
}
@media (max-width: 1485px) {
  .col-4 {
    flex-basis: 12%;
    max-width: 16%;
  }
}
@media (max-height: 710px) {
  .title-wrapper {
    padding-bottom: 0px !important;
  }
  .responsive-main-card {
    height: 19vh !important;
  }
}
@media (min-height: 710px) and (max-height: 760px) {
  .responsive-main-card {
    height: 17.5vh !important;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .responsive-main-card {
    height: 19.5vh !important;
  }
}
@media (min-height: 810px) and (max-height: 860px) {
  .responsive-main-card {
    height: 20vh !important;
  }
}
@media (min-height: 860px) and (max-height: 900px) {
  .responsive-main-card {
    height: 19.5vh !important;
  }
}
</style>
