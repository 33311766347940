// source: IMultiStatisticsService-v5.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    plan: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    fact: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    deviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlan(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFact(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlan();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFact();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp Date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double Plan = 2;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.getPlan = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.setPlan = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double Fact = 3;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.getFact = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.setFact = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double Deviation = 4;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.getDeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.prototype.setDeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    constructioninstance: jspb.Message.getFieldWithDefault(msg, 1, ""),
    structureid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstructioninstance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStructureid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConstructioninstance();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStructureid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string ConstructionInstance = 1;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.prototype.getConstructioninstance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.prototype.setConstructioninstance = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 StructureId = 2;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.prototype.getStructureid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionStructureRequest.prototype.setStructureid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractiorid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractorname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentdynamics: (f = msg.getCurrentdynamics()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject(includeInstance, f),
    currentexecution: (f = msg.getCurrentexecution()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject(includeInstance, f),
    chartList: jspb.Message.toObjectList(msg.getChartList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setContractiorid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractorname(value);
      break;
    case 3:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader);
      msg.setCurrentdynamics(value);
      break;
    case 4:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.setCurrentexecution(value);
      break;
    case 5:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.addChart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractiorid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getContractorname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentdynamics();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getCurrentexecution();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
  f = message.getChartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 ContractiorId = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.getContractiorid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.setContractiorid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ContractorName = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.getContractorname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.setContractorname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviationDynamicsResult CurrentDynamics = 3;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.getCurrentdynamics = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, 3));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.setCurrentdynamics = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.clearCurrentdynamics = function() {
  return this.setCurrentdynamics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.hasCurrentdynamics = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ChartItem CurrentExecution = 4;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.getCurrentexecution = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 4));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.setCurrentexecution = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.clearCurrentexecution = function() {
  return this.setCurrentexecution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.hasCurrentexecution = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated ChartItem Chart = 5;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.getChartList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 5));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.setChartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.addChart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.prototype.clearChartList = function() {
  return this.setChartList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    direction: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDirection();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 ItemId = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.getItemid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.setItemid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double Value = 2;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool Direction = 3;
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.getDirection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.setDirection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    num: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int32 Num = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.getNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.setNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 Count = 3;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double Percent = 4;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentagedeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    chartList: jspb.Message.toObjectList(msg.getChartList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentagedeviation(value);
      break;
    case 3:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.addChart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentagedeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getChartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional double PercentageDeviation = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.getPercentagedeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.setPercentagedeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * repeated ChartItem Chart = 3;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.getChartList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 3));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.setChartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.addChart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationsDynamicsResult.prototype.clearChartList = function() {
  return this.setChartList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    percentagedeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    daysdeviation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    chartList: jspb.Message.toObjectList(msg.getChartList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentagedeviation(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDaysdeviation(value);
      break;
    case 3:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.addChart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPercentagedeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDaysdeviation();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getChartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional double PercentageDeviation = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.getPercentagedeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.setPercentagedeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional int32 DaysDeviation = 2;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.getDaysdeviation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.setDaysdeviation = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ChartItem Chart = 3;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.getChartList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 3));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.setChartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.addChart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.GprDynamicsResult.prototype.clearChartList = function() {
  return this.setChartList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated ContractorDynamicsResult Values = 1;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult, 1));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ContractorDynamicsResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string Error = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_ContractorDynamicsResult.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated PlanFactTreeResult Values = 1;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult, 1));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string Error = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_PlanFactTreeResult.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated QuantityDoneResult Values = 1;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult, 1));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string Error = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_QuantityDoneResult.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated TechnadzorDeviationResult Values = 1;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult, 1));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string Error = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_TechnadzorDeviationResult.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    planpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    factpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currentdynamics: (f = msg.getCurrentdynamics()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject(includeInstance, f),
    subitemsList: jspb.Message.toObjectList(msg.getSubitemsList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlanpercentage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFactpercentage(value);
      break;
    case 5:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader);
      msg.setCurrentdynamics(value);
      break;
    case 6:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.deserializeBinaryFromReader);
      msg.addSubitems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlanpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFactpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrentdynamics();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getSubitemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double PlanPercentage = 3;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.getPlanpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.setPlanpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double FactPercentage = 4;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.getFactpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.setFactpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional DeviationDynamicsResult CurrentDynamics = 5;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.getCurrentdynamics = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, 5));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.setCurrentdynamics = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.clearCurrentdynamics = function() {
  return this.setCurrentdynamics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.hasCurrentdynamics = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated PlanFactTreeResult SubItems = 6;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.getSubitemsList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult, 6));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.setSubitemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.addSubitems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactTreeResult.prototype.clearSubitemsList = function() {
  return this.setSubitemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    productchartList: jspb.Message.toObjectList(msg.getProductchartList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject, includeInstance),
    ttkchartList: jspb.Message.toObjectList(msg.getTtkchartList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject, includeInstance),
    packagechartList: jspb.Message.toObjectList(msg.getPackagechartList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject, includeInstance),
    materialchartList: jspb.Message.toObjectList(msg.getMaterialchartList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.toObject, includeInstance),
    productpredictiondays: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ttkpredictiondays: jspb.Message.getFieldWithDefault(msg, 6, 0),
    packagepredictiondays: jspb.Message.getFieldWithDefault(msg, 7, 0),
    materialpredictiondays: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.addProductchart(value);
      break;
    case 2:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.addTtkchart(value);
      break;
    case 3:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.addPackagechart(value);
      break;
    case 4:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.deserializeBinaryFromReader);
      msg.addMaterialchart(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProductpredictiondays(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTtkpredictiondays(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackagepredictiondays(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaterialpredictiondays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductchartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
  f = message.getTtkchartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
  f = message.getPackagechartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
  f = message.getMaterialchartList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem.serializeBinaryToWriter
    );
  }
  f = message.getProductpredictiondays();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTtkpredictiondays();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPackagepredictiondays();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMaterialpredictiondays();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * repeated ChartItem ProductChart = 1;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getProductchartList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 1));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setProductchartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.addProductchart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.clearProductchartList = function() {
  return this.setProductchartList([]);
};


/**
 * repeated ChartItem TtkChart = 2;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getTtkchartList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 2));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setTtkchartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.addTtkchart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.clearTtkchartList = function() {
  return this.setTtkchartList([]);
};


/**
 * repeated ChartItem PackageChart = 3;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getPackagechartList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 3));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setPackagechartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.addPackagechart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.clearPackagechartList = function() {
  return this.setPackagechartList([]);
};


/**
 * repeated ChartItem MaterialChart = 4;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getMaterialchartList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, 4));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setMaterialchartList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.addMaterialchart = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ChartItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.clearMaterialchartList = function() {
  return this.setMaterialchartList([]);
};


/**
 * optional int32 ProductPredictionDays = 5;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getProductpredictiondays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setProductpredictiondays = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 TtkPredictionDays = 6;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getTtkpredictiondays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setTtkpredictiondays = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 PackagePredictionDays = 7;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getPackagepredictiondays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setPackagepredictiondays = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 MaterialPredictionDays = 8;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.getMaterialpredictiondays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ProductionCollapseResult.prototype.setMaterialpredictiondays = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    num: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lvl1name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lvl2name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lvl3name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ttkname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    controllername: jspb.Message.getFieldWithDefault(msg, 6, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fact: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl1name(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl2name(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl3name(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTtkname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setControllername(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLvl1name();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLvl2name();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLvl3name();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTtkname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getControllername();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFact();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int32 Num = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Lvl1Name = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getLvl1name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setLvl1name = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Lvl2Name = 3;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getLvl2name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setLvl2name = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Lvl3Name = 4;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getLvl3name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setLvl3name = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string TtkName = 5;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getTtkname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setTtkname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ControllerName = 6;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getControllername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setControllername = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp Date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.hasDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double Fact = 8;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.getFact = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.QuantityDoneResult.prototype.setFact = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    num: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractorname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isfixed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    createdatetime: (f = msg.getCreatedatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fixdatetime: (f = msg.getFixdatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lvl1name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lvl2name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lvl3name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    lvl4name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    lvl5name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    technologycardname: jspb.Message.getFieldWithDefault(msg, 11, ""),
    normativedocumentname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    note: jspb.Message.getFieldWithDefault(msg, 13, ""),
    photofilepath: jspb.Message.getFieldWithDefault(msg, 14, ""),
    schemefilepath: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractorname(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfixed(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedatetime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFixdatetime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl1name(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl2name(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl3name(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl4name(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvl5name(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTechnologycardname(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNormativedocumentname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotofilepath(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchemefilepath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNum();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getContractorname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsfixed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCreatedatetime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFixdatetime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLvl1name();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLvl2name();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLvl3name();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLvl4name();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLvl5name();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTechnologycardname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNormativedocumentname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPhotofilepath();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSchemefilepath();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int32 Num = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setNum = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ContractorName = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getContractorname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setContractorname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool IsFixed = 3;
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getIsfixed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setIsfixed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp CreateDateTime = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getCreatedatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setCreatedatetime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.clearCreatedatetime = function() {
  return this.setCreatedatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.hasCreatedatetime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp FixDateTime = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getFixdatetime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setFixdatetime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.clearFixdatetime = function() {
  return this.setFixdatetime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.hasFixdatetime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string Lvl1Name = 6;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getLvl1name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setLvl1name = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Lvl2Name = 7;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getLvl2name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setLvl2name = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Lvl3Name = 8;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getLvl3name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setLvl3name = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Lvl4Name = 9;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getLvl4name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setLvl4name = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string Lvl5Name = 10;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getLvl5name = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setLvl5name = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string TechnologyCardName = 11;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getTechnologycardname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setTechnologycardname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string NormativeDocumentName = 12;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getNormativedocumentname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setNormativedocumentname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string Note = 13;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string PhotoFilePath = 14;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getPhotofilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setPhotofilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string SchemeFilePath = 15;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.getSchemefilepath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TechnadzorDeviationResult.prototype.setSchemefilepath = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    topdeviationstechnologycardsexecutionList: jspb.Message.toObjectList(msg.getTopdeviationstechnologycardsexecutionList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject, includeInstance),
    topdeviationsstructuresexecutionList: jspb.Message.toObjectList(msg.getTopdeviationsstructuresexecutionList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject, includeInstance),
    topdeviationscontractorsexecutionList: jspb.Message.toObjectList(msg.getTopdeviationscontractorsexecutionList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject, includeInstance),
    topdeviationstechnologycardsqualityList: jspb.Message.toObjectList(msg.getTopdeviationstechnologycardsqualityList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject, includeInstance),
    topdeviationsstructuresqualityList: jspb.Message.toObjectList(msg.getTopdeviationsstructuresqualityList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject, includeInstance),
    topdeviationscontractorsqualityList: jspb.Message.toObjectList(msg.getTopdeviationscontractorsqualityList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader);
      msg.addTopdeviationstechnologycardsexecution(value);
      break;
    case 2:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader);
      msg.addTopdeviationsstructuresexecution(value);
      break;
    case 3:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader);
      msg.addTopdeviationscontractorsexecution(value);
      break;
    case 4:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader);
      msg.addTopdeviationstechnologycardsquality(value);
      break;
    case 5:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader);
      msg.addTopdeviationsstructuresquality(value);
      break;
    case 6:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.deserializeBinaryFromReader);
      msg.addTopdeviationscontractorsquality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopdeviationstechnologycardsexecutionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter
    );
  }
  f = message.getTopdeviationsstructuresexecutionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter
    );
  }
  f = message.getTopdeviationscontractorsexecutionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter
    );
  }
  f = message.getTopdeviationstechnologycardsqualityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter
    );
  }
  f = message.getTopdeviationsstructuresqualityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter
    );
  }
  f = message.getTopdeviationscontractorsqualityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeviationItem TopDeviationsTechnologyCardsExecution = 1;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.getTopdeviationstechnologycardsexecutionList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, 1));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.setTopdeviationstechnologycardsexecutionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.addTopdeviationstechnologycardsexecution = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.clearTopdeviationstechnologycardsexecutionList = function() {
  return this.setTopdeviationstechnologycardsexecutionList([]);
};


/**
 * repeated DeviationItem TopDeviationsStructuresExecution = 2;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.getTopdeviationsstructuresexecutionList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, 2));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.setTopdeviationsstructuresexecutionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.addTopdeviationsstructuresexecution = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.clearTopdeviationsstructuresexecutionList = function() {
  return this.setTopdeviationsstructuresexecutionList([]);
};


/**
 * repeated DeviationItem TopDeviationsContractorsExecution = 3;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.getTopdeviationscontractorsexecutionList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, 3));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.setTopdeviationscontractorsexecutionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.addTopdeviationscontractorsexecution = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.clearTopdeviationscontractorsexecutionList = function() {
  return this.setTopdeviationscontractorsexecutionList([]);
};


/**
 * repeated DeviationItem TopDeviationsTechnologyCardsQuality = 4;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.getTopdeviationstechnologycardsqualityList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, 4));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.setTopdeviationstechnologycardsqualityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.addTopdeviationstechnologycardsquality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.clearTopdeviationstechnologycardsqualityList = function() {
  return this.setTopdeviationstechnologycardsqualityList([]);
};


/**
 * repeated DeviationItem TopDeviationsStructuresQuality = 5;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.getTopdeviationsstructuresqualityList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, 5));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.setTopdeviationsstructuresqualityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.addTopdeviationsstructuresquality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.clearTopdeviationsstructuresqualityList = function() {
  return this.setTopdeviationsstructuresqualityList([]);
};


/**
 * repeated DeviationItem TopDeviationsContractorsQuality = 6;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.getTopdeviationscontractorsqualityList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, 6));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.setTopdeviationscontractorsqualityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.addTopdeviationscontractorsquality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.TopDeviationsResult.prototype.clearTopdeviationscontractorsqualityList = function() {
  return this.setTopdeviationscontractorsqualityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Value = 1;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer);
