<script setup>
import CardObject from "@/components/CardObject.vue";

const props = defineProps(["construction", "isLoading"]);

const cardObjectTitles = ["ВРЕМЯ", "СДАНО", "ОТКЛОНЕНИЕ"];

const generateItemImage = (multiply, itemSrc) => {
  if (itemSrc.startsWith("http")) {
    const base = 360;
    return itemSrc + `/S-${base * multiply}`;
  } else {
    return itemSrc;
  }
};
</script>

<template>
  <v-row>
    <v-col md="8" sm="6">
      <v-img
        :src="generateItemImage(2, props.construction.imagesource)"
        alt="Image"
        class="big-img"
        height="73vh"
        rounded="lg"
        cover
      >
        <CardObject
          v-if="props.construction"
          :item="props.construction"
          :isLoading="props.isLoading"
          :titles="cardObjectTitles"
        />
      </v-img>
    </v-col>
    <v-col md="4" sm="6" class="pa-0">
      <v-container style="padding-left: 1.5vw">
        <!-- Add Designer Section -->
        <v-card-text class="text-color pa-0 pb-2"
          >ДОБАВИТЬ ПРОЕКТИРОВЩИКА</v-card-text
        >
        <v-text-field
          variant="outlined"
          hide-details
          density="compact"
          class="mb-10 text-background custom-placeholder custom-text-field"
          placeholder="Новый проектировщик"
        ></v-text-field>

        <!-- Add Supplier Section -->
        <v-card-text class="text-color pa-0 pb-2"
          >ДОБАВИТЬ ПОСТАВЩИКА</v-card-text
        >
        <v-text-field
          outlined
          hide-details
          class="mb-8 text-background custom-placeholder custom-text-field"
          density="compact"
          variant="outlined"
          placeholder="Новый поставщик"
        ></v-text-field>

        <!-- Blue Button -->
        <v-btn
          class="custom-btn mb-5 blue-btn pa-0 pl-2"
          rounded="lg"
          height="100"
        >
          <img src="@/assets/icons/settings.svg" width="58" height="52" />
          <span class="button-text">
            Отправить действия по “оптимизации” в кратчайшие сроки</span
          >
        </v-btn>
        <!-- Orange Button -->
        <v-btn
          class="custom-btn mb-15 yellow-btn pa-0"
          rounded="lg"
          height="56"
        >
          <img src="@/assets/icons/free.svg" width="47" height="43" />
          <span class="button-text"> освободить исполнителя </span>
        </v-btn>
        <!-- Red Button -->
        <v-btn
          class="custom-btn red-btn mb-15 pa-0 pl-2"
          rounded="lg"
          height="100"
        >
          <img src="@/assets/icons/bell.svg" width="59.71" height="54.56" />
          <span class="button-text"> срочно организовать собрание </span>
        </v-btn>
        <!-- Green Button -->
        <v-btn class="custom-btn green-btn" rounded="lg" height="53">
          <span class="button-text"> OK </span>
          <img src="@/assets/icons/arrow_right.svg" width="31" height="31" />
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<style scoped>
.custom-btn {
  font-size: 0.8vw;
  width: 100%;
}
.blue-btn {
  background: linear-gradient(to right, #002940, #006aa6);
}
.yellow-btn {
  background: linear-gradient(to right, #ff8a00, #f49c18);
}
.red-btn {
  background: linear-gradient(#b80000, #b80000);
}
.green-btn {
  background: linear-gradient(to right, #1bc200, #0d5c00);
}
.button-text {
  white-space: normal;
  text-align: left;
}
.text-color {
  color: #00a3ff;
  font-size: 0.7vw;
}
.text-background {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.custom-placeholder :deep(input::placeholder) {
  color: #00507d;
  opacity: 1;
  font-size: 0.9vw;
}
@media (max-height: 810px) {
  .big-img {
    height: 70vh !important;
  }
  .custom-text-field {
    margin-bottom: 15px !important;
  }
  .blue-btn {
    height: 80px !important;
  }
  .blue-btn {
    margin-bottom: 15px !important;
  }
}
@media (max-height: 760px) {
  .yellow-btn {
    margin-bottom: 15px !important;
  }

  .red-btn {
    margin-bottom: 10px !important;
    height: 80px !important;
    }
}
@media (min-height: 760px) and (max-height: 860px) {
  .yellow-btn,
  .red-btn {
    margin-bottom: 2.5em !important;
  }
}
@media (min-height: 860px) and (max-height: 900px) {
  .yellow-btn,
  .red-btn {
    margin-bottom: 3.5em !important;
  }
}
</style>
