<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { useStore } from "vuex";
import { Line } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";

import {
  Chart,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

Chart.register(
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  ChartDataLabels,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
);

const props = defineProps({
  height: {
    type: Number,
    default: 35,
  },
  chartData: {
    type: Object,
    required: true,
  },
  chartOptions: {
    type: Object,
    required: true,
  },
  icons: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
  },
});

const numLabels = computed(() => props.chartData.labels.length);
const chartRef = ref(null);
const chartContainerRef = ref(null);
const store = useStore();

const changeChart = (index) => {
  store.dispatch("clickedButton/updateClickedChartButton", index);
};

const resizeChart = () => {
  if (chartRef.value?.chart) {
    chartRef.value.chart.resize();
  }
};

onMounted(() => {
  const observer = new ResizeObserver(() => {
    resizeChart();
  });

  if (chartContainerRef.value) {
    observer.observe(chartContainerRef.value);
  }

  window.addEventListener("resize", resizeChart);

  onBeforeUnmount(() => {
    window.removeEventListener("resize", resizeChart);
    if (chartContainerRef.value) {
      observer.unobserve(chartContainerRef.value);
    }
  });
});
</script>

<template>
  <div
    v-if="isLoading"
    class="line-chart"
    :style="{
      maxHeight: props.height / 2 + 'vw',
      display: 'flex',
      alignItems: 'stretch',
      position: 'relative',
    }"
  >
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="blue"
    ></v-progress-linear>
  </div>

  <div
    v-else
    class="line-chart"
    :style="{
      maxHeight: props.height / 2 + 'vw',
      display: 'flex',
      alignItems: 'stretch',
      position: 'relative',
    }"
  >
    <div class="icons-container" v-show="props.icons.length !== 0">
      <div v-for="(icon, index) in props.icons" :key="index" class="icon-item">
        <div
          @click="changeChart(index)"
          style="display: flex; justify-content: center; cursor: pointer"
        >
          <img :src="icon.src" width="65" height="65" class="img" />
        </div>
        <span class="text-img">{{ icon.label }}</span>
      </div>
    </div>

    <div ref="chartContainerRef" class="chart-scroll-container">
      <div class="chart-wrapper" :style="{ minWidth: `${numLabels * 100}px` }">
        <Line
          ref="chartRef"
          :data="props.chartData"
          :options="{
            ...props.chartOptions,
            responsive: true,
            maintainAspectRatio: false,
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.line-chart {
  background: black;
  box-shadow: 0px 0px 15px 2px white;
  border-radius: 15px;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 95%;
  overflow: hidden;
}

.icons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.icon-item {
  margin-bottom: 20px;
}

.text-img {
  font-size: 18px;
  color: #00a3ff;
  text-align: center;
  display: block;
}

.chart-scroll-container {
  flex-grow: 1;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.chart-wrapper {
  position: relative;
  min-width: calc(100px * var(--num-labels));
  width: auto;
  height: 100%;
}
</style>
