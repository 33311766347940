// source: protobuf-net/bcl.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.bcl.DateTime', null, global);
goog.exportSymbol('proto.bcl.DateTime.DateTimeKind', null, global);
goog.exportSymbol('proto.bcl.DateTime.TimeSpanScale', null, global);
goog.exportSymbol('proto.bcl.Decimal', null, global);
goog.exportSymbol('proto.bcl.Guid', null, global);
goog.exportSymbol('proto.bcl.NetObjectProxy', null, global);
goog.exportSymbol('proto.bcl.TimeSpan', null, global);
goog.exportSymbol('proto.bcl.TimeSpan.TimeSpanScale', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bcl.TimeSpan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bcl.TimeSpan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bcl.TimeSpan.displayName = 'proto.bcl.TimeSpan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bcl.DateTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bcl.DateTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bcl.DateTime.displayName = 'proto.bcl.DateTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bcl.NetObjectProxy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bcl.NetObjectProxy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bcl.NetObjectProxy.displayName = 'proto.bcl.NetObjectProxy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bcl.Guid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bcl.Guid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bcl.Guid.displayName = 'proto.bcl.Guid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bcl.Decimal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bcl.Decimal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bcl.Decimal.displayName = 'proto.bcl.Decimal';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bcl.TimeSpan.prototype.toObject = function(opt_includeInstance) {
  return proto.bcl.TimeSpan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bcl.TimeSpan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.TimeSpan.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFieldWithDefault(msg, 1, 0),
scale: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bcl.TimeSpan}
 */
proto.bcl.TimeSpan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bcl.TimeSpan;
  return proto.bcl.TimeSpan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bcl.TimeSpan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bcl.TimeSpan}
 */
proto.bcl.TimeSpan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.bcl.TimeSpan.TimeSpanScale} */ (reader.readEnum());
      msg.setScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bcl.TimeSpan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bcl.TimeSpan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bcl.TimeSpan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.TimeSpan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bcl.TimeSpan.TimeSpanScale = {
  DAYS: 0,
  HOURS: 1,
  MINUTES: 2,
  SECONDS: 3,
  MILLISECONDS: 4,
  TICKS: 5,
  MINMAX: 15
};

/**
 * optional sint64 value = 1;
 * @return {number}
 */
proto.bcl.TimeSpan.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.TimeSpan} returns this
 */
proto.bcl.TimeSpan.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TimeSpanScale scale = 2;
 * @return {!proto.bcl.TimeSpan.TimeSpanScale}
 */
proto.bcl.TimeSpan.prototype.getScale = function() {
  return /** @type {!proto.bcl.TimeSpan.TimeSpanScale} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.bcl.TimeSpan.TimeSpanScale} value
 * @return {!proto.bcl.TimeSpan} returns this
 */
proto.bcl.TimeSpan.prototype.setScale = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bcl.DateTime.prototype.toObject = function(opt_includeInstance) {
  return proto.bcl.DateTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bcl.DateTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.DateTime.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFieldWithDefault(msg, 1, 0),
scale: jspb.Message.getFieldWithDefault(msg, 2, 0),
kind: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bcl.DateTime}
 */
proto.bcl.DateTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bcl.DateTime;
  return proto.bcl.DateTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bcl.DateTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bcl.DateTime}
 */
proto.bcl.DateTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.bcl.DateTime.TimeSpanScale} */ (reader.readEnum());
      msg.setScale(value);
      break;
    case 3:
      var value = /** @type {!proto.bcl.DateTime.DateTimeKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bcl.DateTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bcl.DateTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bcl.DateTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.DateTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.bcl.DateTime.TimeSpanScale = {
  DAYS: 0,
  HOURS: 1,
  MINUTES: 2,
  SECONDS: 3,
  MILLISECONDS: 4,
  TICKS: 5,
  MINMAX: 15
};

/**
 * @enum {number}
 */
proto.bcl.DateTime.DateTimeKind = {
  UNSPECIFIED: 0,
  UTC: 1,
  LOCAL: 2
};

/**
 * optional sint64 value = 1;
 * @return {number}
 */
proto.bcl.DateTime.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.DateTime} returns this
 */
proto.bcl.DateTime.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional TimeSpanScale scale = 2;
 * @return {!proto.bcl.DateTime.TimeSpanScale}
 */
proto.bcl.DateTime.prototype.getScale = function() {
  return /** @type {!proto.bcl.DateTime.TimeSpanScale} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.bcl.DateTime.TimeSpanScale} value
 * @return {!proto.bcl.DateTime} returns this
 */
proto.bcl.DateTime.prototype.setScale = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional DateTimeKind kind = 3;
 * @return {!proto.bcl.DateTime.DateTimeKind}
 */
proto.bcl.DateTime.prototype.getKind = function() {
  return /** @type {!proto.bcl.DateTime.DateTimeKind} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.bcl.DateTime.DateTimeKind} value
 * @return {!proto.bcl.DateTime} returns this
 */
proto.bcl.DateTime.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bcl.NetObjectProxy.prototype.toObject = function(opt_includeInstance) {
  return proto.bcl.NetObjectProxy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bcl.NetObjectProxy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.NetObjectProxy.toObject = function(includeInstance, msg) {
  var f, obj = {
existingobjectkey: jspb.Message.getFieldWithDefault(msg, 1, 0),
newobjectkey: jspb.Message.getFieldWithDefault(msg, 2, 0),
existingtypekey: jspb.Message.getFieldWithDefault(msg, 3, 0),
newtypekey: jspb.Message.getFieldWithDefault(msg, 4, 0),
typename: jspb.Message.getFieldWithDefault(msg, 8, ""),
payload: msg.getPayload_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bcl.NetObjectProxy}
 */
proto.bcl.NetObjectProxy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bcl.NetObjectProxy;
  return proto.bcl.NetObjectProxy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bcl.NetObjectProxy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bcl.NetObjectProxy}
 */
proto.bcl.NetObjectProxy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExistingobjectkey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewobjectkey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExistingtypekey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNewtypekey(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypename(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bcl.NetObjectProxy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bcl.NetObjectProxy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bcl.NetObjectProxy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.NetObjectProxy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExistingobjectkey();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNewobjectkey();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getExistingtypekey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNewtypekey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTypename();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional int32 existingObjectKey = 1;
 * @return {number}
 */
proto.bcl.NetObjectProxy.prototype.getExistingobjectkey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.NetObjectProxy} returns this
 */
proto.bcl.NetObjectProxy.prototype.setExistingobjectkey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 newObjectKey = 2;
 * @return {number}
 */
proto.bcl.NetObjectProxy.prototype.getNewobjectkey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.NetObjectProxy} returns this
 */
proto.bcl.NetObjectProxy.prototype.setNewobjectkey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 existingTypeKey = 3;
 * @return {number}
 */
proto.bcl.NetObjectProxy.prototype.getExistingtypekey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.NetObjectProxy} returns this
 */
proto.bcl.NetObjectProxy.prototype.setExistingtypekey = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 newTypeKey = 4;
 * @return {number}
 */
proto.bcl.NetObjectProxy.prototype.getNewtypekey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.NetObjectProxy} returns this
 */
proto.bcl.NetObjectProxy.prototype.setNewtypekey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string typeName = 8;
 * @return {string}
 */
proto.bcl.NetObjectProxy.prototype.getTypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.bcl.NetObjectProxy} returns this
 */
proto.bcl.NetObjectProxy.prototype.setTypename = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bytes payload = 10;
 * @return {string}
 */
proto.bcl.NetObjectProxy.prototype.getPayload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes payload = 10;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.bcl.NetObjectProxy.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.bcl.NetObjectProxy.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.bcl.NetObjectProxy} returns this
 */
proto.bcl.NetObjectProxy.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bcl.Guid.prototype.toObject = function(opt_includeInstance) {
  return proto.bcl.Guid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bcl.Guid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.Guid.toObject = function(includeInstance, msg) {
  var f, obj = {
lo: jspb.Message.getFieldWithDefault(msg, 1, 0),
hi: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bcl.Guid}
 */
proto.bcl.Guid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bcl.Guid;
  return proto.bcl.Guid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bcl.Guid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bcl.Guid}
 */
proto.bcl.Guid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setLo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setHi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bcl.Guid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bcl.Guid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bcl.Guid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.Guid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLo();
  if (f !== 0) {
    writer.writeFixed64(
      1,
      f
    );
  }
  f = message.getHi();
  if (f !== 0) {
    writer.writeFixed64(
      2,
      f
    );
  }
};


/**
 * optional fixed64 lo = 1;
 * @return {number}
 */
proto.bcl.Guid.prototype.getLo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.Guid} returns this
 */
proto.bcl.Guid.prototype.setLo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional fixed64 hi = 2;
 * @return {number}
 */
proto.bcl.Guid.prototype.getHi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.Guid} returns this
 */
proto.bcl.Guid.prototype.setHi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bcl.Decimal.prototype.toObject = function(opt_includeInstance) {
  return proto.bcl.Decimal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bcl.Decimal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.Decimal.toObject = function(includeInstance, msg) {
  var f, obj = {
lo: jspb.Message.getFieldWithDefault(msg, 1, 0),
hi: jspb.Message.getFieldWithDefault(msg, 2, 0),
signscale: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bcl.Decimal}
 */
proto.bcl.Decimal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bcl.Decimal;
  return proto.bcl.Decimal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bcl.Decimal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bcl.Decimal}
 */
proto.bcl.Decimal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSignscale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bcl.Decimal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bcl.Decimal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bcl.Decimal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bcl.Decimal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLo();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getHi();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSignscale();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint64 lo = 1;
 * @return {number}
 */
proto.bcl.Decimal.prototype.getLo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.Decimal} returns this
 */
proto.bcl.Decimal.prototype.setLo = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 hi = 2;
 * @return {number}
 */
proto.bcl.Decimal.prototype.getHi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.Decimal} returns this
 */
proto.bcl.Decimal.prototype.setHi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 signScale = 3;
 * @return {number}
 */
proto.bcl.Decimal.prototype.getSignscale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bcl.Decimal} returns this
 */
proto.bcl.Decimal.prototype.setSignscale = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.bcl);
