const state = {
  propertyData: [],
};

const getters = {
  properties: (state) => state.propertyData,
};

const actions = {
  async updateData({ commit }, id, data) {
    await commit("updateProperty", id, data);
  },
  async setData({ commit }, objects) {
    await commit("setPropertyData", objects);
  },
};

const mutations = {
  updateProperty(state, id, data) {
    state.propertyData[id] = data;
  },
  setPropertyData(state, objects) {
    state.propertyData = objects;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
