const deadlineChartHeaders = [
  { title: "№", key: "num", align: "center" },
  { title: "Дата", key: "date", value: "date.seconds", align: "center" },
  { title: "План", key: "plan", align: "center" },
  { title: "Факт", key: "fact", align: "center" },
];

const deadlineHeaders = [
  { title: "№", align: "center", key: "num", sortable: false },
  { title: "УРОВЕНЬ\u00A01", align: "center", key: "lvl1name" },
  { title: "УРОВЕНЬ\u00A02", align: "center", key: "lvl2name" },
  { title: "УРОВЕНЬ\u00A03", align: "center", key: "lvl3name" },
  {
    title: "TTK",
    align: "center",
    key: "ttkname",
  },
  {
    title: "Сотрудник",
    align: "center",
    key: "controllername",
    sortable: false,
  },
  { title: "ДАТА", align: "center", key: "date", sortable: false },
  { title: "ФАКТ", align: "center", key: "fact", sortable: false },
];

const qualityHeaders = [
  { title: "№", key: "num", align: "center" },
  { title: "ПОДРЯДЧИК", key: "contractorname", align: "center" },
  { title: "СТАТУС", key: "isfixed", align: "center" },
  {
    title: "ДАТА ОТКЛОНЕНИЯ",
    key: "computedDate",
    align: "center",
    sortable: true,
  },
  {
    title: "ДАТА ИСПРАВЛЕНИЯ",
    key: "computedFixDate",
    align: "center",
    sortable: true,
  },
  { title: "УРОВЕНЬ\u00A01", key: "lvl1name" },
  { title: "УРОВЕНЬ\u00A02", key: "lvl2name" },
  { title: "УРОВЕНЬ\u00A03", key: "lvl3name" },
  { title: "УРОВЕНЬ\u00A04", key: "lvl4name" },
  { title: "УРОВЕНЬ\u00A05", key: "lvl5name" },
  { title: "ТТК", key: "technologycardname", align: "center" },
  {
    title: "ПЕРВОПРИЧИНА",
    key: "normativedocumentname",
    align: "center",
    sortable: false,
  },
  { title: "КОММЕНТАРИЙ", key: "note", align: "center", sortable: false },
  { title: "ФОТО", key: "photofilepath", align: "center", sortable: false },
  { title: "СХЕМА", key: "schemefilepath", align: "center", sortable: false },
];

export default {
  deadlineHeaders,
  deadlineChartHeaders,
  qualityHeaders,
};
