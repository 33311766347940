<script setup>
import { useStore } from "vuex";
import { computed } from "vue";
import { percentColor, progressBarColor } from "@/utils/bgColors.js";

const props = defineProps({
  title: {
    type: String,
  },
  percents: {
    type: Number,
  },
  size: {
    type: Number,
  },
  color: {
    type: Number,
    required: false,
    default: 1,
  },
});
const store = useStore();
let clickedButton = computed(
  () => store.getters["clickedButton/getClickedButton"],
);

const progressWidth = computed(() => {
  if (props.percents > 100) {
    return "100%";
  } else if (props.percents < 0) {
    return "0%";
  } else {
    return `${Math.min(props.percents, 100).toFixed(0)}%`;
  }
});

const text = computed(() => {
  return clickedButton.value === "КАЧЕСТВО" ? "шт." : "%";
});
</script>

<template>
  <v-col class="pa-0 ma-0" md="4" sm="4" xs="5">
    <v-card-text
      :style="{ fontSize: `${props.size}vw` }"
      class="pa-0 ma-0"
      align="end"
      >{{ props.title }}</v-card-text
    >
  </v-col>
  <v-col class="pa-0 ma-0 pl-1" md="5" sm="4" xs="4">
    <div class="progress-bar">
      <div
        class="progress-fill"
        :style="{
          width: progressWidth,
          ...progressBarColor(props.color),
        }"
      ></div>
    </div>
  </v-col>
  <v-col class="pa-0 ma-0" md="3" sm="3" xs="3">
    <v-card-text
      class="pa-1"
      :style="{
        fontSize: `${props.size * 1.5}vw`,
        ...percentColor(props.color),
      }"
      >{{ props.percents?.toFixed(0) }}
      <span
        :style="{
          fontSize: `${props.size}vw`,
          ...percentColor(props.color),
        }"
        >{{ text }}</span
      ></v-card-text
    >
  </v-col>
</template>

<style scoped>
.progress-bar {
  height: 10px;
  background-color: white;
  border-radius: 15px;
  border: 2px solid white;
}
.progress-fill {
  height: 100%;
  border-radius: 3px;
}
</style>
