<script setup>
</script>

<template>
  <v-footer app height="20" color="#001825">
    <v-col class="text-right white--text footer_copy" style="font-size: 10px">
      © Copyright {{ new Date().getFullYear() }} Interactive Business Partners
      Peterburg
    </v-col>
  </v-footer>
</template>

<style scoped>
.footer_copy {
  font-family: "NeoSansPro";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
}
</style>
