<script setup>
import { ref, onMounted, computed } from "vue";
import { VTreeview } from "vuetify/labs/VTreeview";
import { useStore } from "vuex";

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const prodoctionTreeData = ref({});
const isLoading = ref(true);

// Helper function to transform fetched data to VTreeview-compatible format
function transformTreeData(items) {
  return items.map((item) => ({
    id: item.id,
    name: item.name,
    currentdynamics: item.currentdynamics,
    children: transformTreeData(item.subitemsList || []), // Recursively transform subitems
  }));
}

const getRowClass = (index) => {
  return index % 2 === 0 ? "transperant-row" : "black-row";
};

const getArrowImage = (direction) => {
  return direction
    ? new URL("@/assets/icons/green_arrow.svg", import.meta.url).href
    : new URL("@/assets/icons/red_arrow.svg", import.meta.url).href;
};
const textColor = (direction) => {
  return direction ? "green-status" : "red-status";
};

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb.js");

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

let predictionDate = computed(
  () => store.getters["appData/getProductionPredictionDate"],
);
let lastLabelDate = computed(
  () => store.getters["appData/getProductionLastLabelDate"],
);

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const structuresExecutionTree = await new Promise((resolve, reject) => {
      client.getStructuresExecutionTree(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });
    console.log(structuresExecutionTree);
    prodoctionTreeData.value = transformTreeData(
      structuresExecutionTree.valuesList,
    );
  } catch (error) {
    console.error(error);
    // throw error;
  }
  isLoading.value = false;
};

onMounted(() => {
  fetchDataFromGrpc();
});
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row align="center" class="mx-auto" style="width: 50%">
      <v-col v-if="isLoading" cols="7">
        <v-card-text align="center" class="date">К ДАТЕ: </v-card-text>
      </v-col>
      <v-col v-else cols="7">
        <v-card-text align="center" class="date"
          >К ДАТЕ: {{ lastLabelDate }}</v-card-text
        >
      </v-col>
      <v-col v-if="!isLoading" cols="5">
        <v-card class="red-card" width="199">
          <span class="red-card-text">{{ predictionDate }} дн.</span>
          <span class="legend-text ml-3">ПРОГНОЗ</span>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text align="center" class="title mt-5 pa-2"
      >ПО ЛОКАЦИЯМ</v-card-text
    >
  </v-container>
  <v-progress-linear
    v-if="isLoading"
    indeterminate
    color="blue"
  ></v-progress-linear>
  <div v-else>
    <VTreeview
      :items="prodoctionTreeData"
      bg-color="transparent"
      item-key="id"
      open-on-click
      activatable
      color="success"
      height="55vh"
    >
      <template v-slot:prepend="{ item }">
        <div :class="getRowClass(item.id)">
          <img
            src="@/assets/bg/object6.png"
            class="tree-img"
            width="46"
            height="37"
          />
        </div>
      </template>
      <template v-slot:title="{ item }">
        <div :class="getRowClass(item.id)">
          <span style="font-size: 24px">{{ item.name }}</span>
        </div>
      </template>
      <template v-slot:append="{ item }">
        <div
          :class="[
            getRowClass(item.id),
            textColor(item.currentdynamics.direction),
          ]"
          class="d-flex align-center"
        >
          <span style="font-size: 18px"
            >{{ item.currentdynamics.value }} дн.</span
          >
          <img
            :src="getArrowImage(item.currentdynamics.direction)"
            style="padding-right: 8em; padding-left: 1em"
          />
        </div>
      </template>
    </VTreeview>
  </div>
</template>

<style scoped>
.date {
  font-size: 1.5vw;
  color: #00a3ff;
  font-weight: bold;
  padding: 0;
}
.title {
  color: white;
  background: linear-gradient(#006aa6 0%, #002940);
  font-size: 1.5vw;
  border-radius: 8px;
}
.legend-text {
  font-size: 0.7vw;
}
.red-card {
  background-color: red;
}
.red-card-text {
  font-size: 1.6vw;
}
.tree-img {
  margin-right: 10px;
  border-radius: 5px;
}
.green-status {
  margin-right: 1em;
  color: #1bc200;
}
.red-status {
  margin-right: 1em;
  color: #ff0000;
}
.blue-status {
  margin-right: 1em;
  color: #00a3ff;
}
.transperant-row {
  background-color: transparent;
  min-height: 2.8em;
  max-height: 2.8em;
}
.black-row {
  background-color: #00121c;
  padding: 5px 0;
  border-radius: 10px;
  min-height: 2.8em;
  max-height: 2.8em;
  /* border-radius: unset; */
}
</style>
