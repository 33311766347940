/**
 * @fileoverview gRPC-Web generated client stub for ASIC.Contracts.ServiceContracts.MulticaseLayer
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.0-rc1
// source: IMultiReportService-v2.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.ASIC = {};
proto.ASIC.Contracts = {};
proto.ASIC.Contracts.ServiceContracts = {};
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer = require('./IMultiReportService-v2_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult>}
 */
const methodDescriptor_MultiReportService_GetSpecialReport = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/GetSpecialReport',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServiceClient.prototype.getSpecialReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/GetSpecialReport',
      request,
      metadata || {},
      methodDescriptor_MultiReportService_GetSpecialReport,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServicePromiseClient.prototype.getSpecialReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/GetSpecialReport',
      request,
      metadata || {},
      methodDescriptor_MultiReportService_GetSpecialReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime>}
 */
const methodDescriptor_MultiReportService_GetSpecialReportDates = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/GetSpecialReportDates',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServiceClient.prototype.getSpecialReportDates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/GetSpecialReportDates',
      request,
      metadata || {},
      methodDescriptor_MultiReportService_GetSpecialReportDates,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServicePromiseClient.prototype.getSpecialReportDates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/GetSpecialReportDates',
      request,
      metadata || {},
      methodDescriptor_MultiReportService_GetSpecialReportDates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult,
 *   !proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult>}
 */
const methodDescriptor_MultiReportService_SaveSpecialReport = new grpc.web.MethodDescriptor(
  '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/SaveSpecialReport',
  grpc.web.MethodType.UNARY,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult,
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult,
  /**
   * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.deserializeBinary
);


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult>|undefined}
 *     The XHR Node Readable Stream
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServiceClient.prototype.saveSpecialReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/SaveSpecialReport',
      request,
      metadata || {},
      methodDescriptor_MultiReportService_SaveSpecialReport,
      callback);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult>}
 *     Promise that resolves to the response
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportServicePromiseClient.prototype.saveSpecialReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/ASIC.Contracts.ServiceContracts.MulticaseLayer.MultiReportService/SaveSpecialReport',
      request,
      metadata || {},
      methodDescriptor_MultiReportService_SaveSpecialReport);
};


module.exports = proto.ASIC.Contracts.ServiceContracts.MulticaseLayer;

