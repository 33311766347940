<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LocationCard from "@/components/cards/LocationCard.vue";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

const isLoading = ref(true);

const store = useStore();

const constructions = computed(() => {
  const data = store.getters["locations/constructions"] || [];
  return data;
});

let constructionItems = computed(() => {
  const data = constructions.value.length;
  return data || 0;
});

const {
  MultiConstructionServiceClient,
} = require("../proto-out/IMultiConstructionService-v4_grpc_web_pb");

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchDataFromGrpc = async () => {
  const client = new MultiConstructionServiceClient(serverUrl.value);
  const metadata = { Authorization: `Bearer ${authToken.value}` };

  return new Promise((resolve, reject) => {
    client.getAvailableConstructions(new Empty(), metadata, (err, response) => {
      if (err) {
        console.error("gRPC Error:", err.message);
        reject(new Error("Failed to fetch data from gRPC"));
      } else {
        const responseObject = response.toObject();
        store.dispatch("locations/setData", responseObject.valuesList);
        resolve(responseObject.valuesList);
        isLoading.value = false;
      }
    });
  });
};
onMounted(() => {
  // if (constructions.value.length !== 0)
  fetchDataFromGrpc();
});
</script>

<template>
  <v-responsive
    class="mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2 mx-xxl-2 my-xxl-2 locations"
  >
    <v-overlay :model-value="isLoading" class="align-center justify-center">
      <v-progress-circular
        style="margin: 10px"
        size="120"
        width="7"
        indeterminate
        color="primary"
      >
      </v-progress-circular>
    </v-overlay>
    <v-fade-transition>
      <v-row v-if="!isLoading && constructionItems" fluid class="pa-5" dense>
        <v-col
          v-for="(data, i) in constructions"
          :key="i"
          cols="12"
          sm="6"
          md="6"
          lg="3"
          xl="3"
          xxl="3"
          class="custom-card-width pa-2"
        >
          <LocationCard :data="data" />
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-responsive>
</template>

<style scoped>
.locations {
  min-height: 85vh;
}
@media (max-width: 1540px) {
  .custom-card-width {
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }
}
</style>
