<script setup>
const props = defineProps({
  camera: {
    type: Object,
    required: true,
  },
});
const defaultImage = "@/assets/video/camera_default.png";
const imageSrc = props.camera.img || defaultImage;
</script>

<template>
  <div>
    <v-img :src="imageSrc" class="image-with-text">
      <template v-slot:default>
        <div class="overlay">
          <img src="@/assets/bg/camera.png" height="18" class="ml-1" />
          <span class="ml-2" style="font-size: 12px">{{ camera.name }}</span>
        </div>
      </template>
    </v-img>
  </div>
</template>

<style scoped>
.image-with-text {
  position: relative;
}

.overlay {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), black);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  overflow: hidden;
}
</style>
