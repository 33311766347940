<script setup>
import { useMap } from "@/composables/useMap";
import PopupContainer from "@/components/PopupContainer.vue";
import iconPathGreen from "@/assets/bg/Group-117-green.png";
import iconPathRed from "@/assets/bg/Group-119-red.svg";
import iconPathGray from "@/assets/bg/Group-117-gray.svg";
import { onMounted } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const latitude = props.data[0]?.latitude;
const longitude = props.data[0]?.longitude;

const mapElementId = "map";

const emit = defineEmits(["mapMounted"]);

const { invalidateSize } = useMap(
  mapElementId,
  latitude,
  longitude,
  PopupContainer,
  iconPathGreen,
  iconPathRed,
  iconPathGray,
  props.data,
);

onMounted(() => {
  emit("mapMounted", invalidateSize);
});
</script>

<template>
  <div :id="mapElementId" class="map-container"></div>
</template>

<style>
.leaflet-popup-content-wrapper {
  padding: 0;
  background: radial-gradient(
    circle,
    rgba(0, 101, 156, 1) 0%,
    rgba(0, 35, 54, 1) 100%
  );
  color: white;
}
.leaflet-popup-tip {
  display: none;
}
.map-container {
  height: 85vh;
  border-radius: 10px;
  margin-right: 10px;
}
@media (max-height: 810px) {
  .map-container {
    height: 79vh;
  }
}
</style>
