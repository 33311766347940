<script setup>
import { formatDate } from "@/utils/formatDate";
import { ref, onMounted, computed } from "vue";
import tableHeaders from "@/utils/tableHeaders.js";
import * as XLSX from "xlsx";
import { useStore } from "vuex";

const store = useStore();

const { deadlineHeaders } = tableHeaders;

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});
const tableData = ref([]);
const isLoading = ref(true);
const percentge = ref(0);

let subLocationIndex = computed(
  () => store.getters["appData/getCurrentSubLocationId"],
);

const {
  ConstructionStructureRequest,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };

    const request = new ConstructionStructureRequest();
    request.setConstructioninstance(props.construction.actualinstance);
    request.setStructureid(subLocationIndex.value || 0);

    const getDetailedStructureExecutionData = await new Promise(
      (resolve, reject) => {
        client.getDetailedStructureExecution(
          request,
          metadata,
          (err, response) => {
            if (err) {
              console.error("gRPC Error:", err.message);
              reject(new Error("Failed to fetch data from gRPC"));
            } else {
              resolve(response.toObject());
            }
          },
        );
      },
    );
    tableData.value = getDetailedStructureExecutionData.valuesList;
    percentge.value = calculateAvgFact(
      getDetailedStructureExecutionData.valuesList,
    );
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
};
onMounted(() => {
  fetchDataFromGrpc();
});

const calculateAvgFact = (arr) => {
  const total = arr.reduce((sum, obj) => sum + obj.fact, 0);
  return arr.length > 0 ? total / arr.length : 0;
};

const formatDateToReal = (seconds) => {
  const date = new Date(seconds * 1000);
  return date.toLocaleDateString();
};

const exportToExcel = () => {
  const headers = deadlineHeaders.map((header) => header.title);
  const rows = tableData.value.map((item) => {
    return deadlineHeaders.map((header) => {
      if (header.key === "date") {
        return item.date && item.date.seconds
          ? formatDateToReal(item.date.seconds)
          : "";
      } else if (header.key === "fact") {
        return item.fact !== null ? `${item.fact}%` : "";
      } else {
        return item[header.key] ?? "";
      }
    });
  });
  const worksheetData = [headers, ...rows];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  headers.forEach((_, colIdx) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIdx });
    if (!worksheet[cellAddress]) return;
    worksheet[cellAddress].s = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "D9EAD3" } },
    };
  });

  worksheet["!cols"] = headers.map(() => ({ wpx: 150 }));

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Отчет");

  const date = new Date();
  const dateString = date.toLocaleDateString("en-GB").replace(/\//g, "_");
  const fileName = `${dateString}_мултикейс_срок_отчет.xlsx`;
  XLSX.writeFile(workbook, fileName);
};

// const exportToCSV = () => {
//   if (!deadlineHeaders || !deadlineHeaders.length || !tableData || !tableData.length) {
//     console.warn("Table headers or data are undefined or empty.");
//     return;
//   }

//   const headers = deadlineHeaders.map(header => header.title);
//   const rows = tableData.map(item => {
//     return deadlineHeaders.map(header => {
//       if (header.key === 'Date') {
//         return item.Date && item.Date.seconds ? formatDateToReal(item.Date.seconds) : '';
//       } else if (header.key === 'Fact') {
//         return item.Fact
//           ? `${calculatePercentageExport(item.Fact.lo, item.Fact.hi, item.Fact.signScale)}%`
//           : '';
//       } else {
//         return item[header.key] ?? '';
//       }
//     });
//   });

//   let csvContent = "data:text/csv;charset=utf-8,"
//     + [headers, ...rows].map(row => row.join(",")).join("\n");

//   const encodedUri = encodeURI(csvContent);
//   const link = document.createElement("a");
//   link.setAttribute("href", encodedUri);
//   link.setAttribute("download", "exported_data.csv");
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };
</script>

<template>
  <v-card class="custom-card" style="">
    <!-- Title Section -->
    <v-row>
      <v-col class="text-center pa-0" cols="12">
        <v-card-text class="title pa-0 pb-5">ВЫПОЛНЕНИЕ</v-card-text>
      </v-col>
    </v-row>
    <!-- Data Table -->
    <v-data-table
      :headers="deadlineHeaders"
      :items="tableData"
      item-value="num"
      height="65vh"
      fixed-header
      hide-default-footer
      show-select
      density="compact"
      :items-per-page="-1"
      :loading="isLoading"
      loading-text="Загрузка данных..."
    >
      <template v-slot:no-data>
        <div class="text-center">
          <p>Не найдено ни одного элемента</p>
        </div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>
          {{
            item.date && item.date.seconds ? formatDate(item.date.seconds) : ""
          }}
        </span>
      </template>
      <template v-slot:[`item.fact`]="{ item }">
        <span>
          {{ item.fact !== null ? `${item.fact}%` : "" }}
        </span>
      </template>
    </v-data-table>

    <!-- Footer Section -->
  </v-card>
  <v-row class="pa-0 ma-0">
    <v-col class="text-right">
      <span class="bottom-text mr-5 up-text"
        >средний процент выполнения: {{ percentge?.toFixed(0) }}%</span
      >
      <v-btn @click="exportToExcel" class="mr-5 up-text" rounded="lg"
        >экспорт в excel</v-btn
      >
      <v-btn class="up-text" rounded="lg">отправить данные</v-btn>
    </v-col>
  </v-row>
</template>

<style scoped>
.title {
  color: #00a3ff;
  font-size: 34px;
  font-weight: bold;
}
.custom-card {
  background-color: transparent;
  height: 92%;
}
.bottom-text {
  color: #006aa6;
  font-size: 12px;
}
.up-text {
  text-transform: uppercase;
}
.v-data-table {
  background-color: transparent;
}

.v-data-table :deep(table) {
  border-spacing: 0 0.9rem !important;
}
:deep(.v-data-table thead th) {
  background: linear-gradient(#006aa6, #002940) !important;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  text-transform: uppercase;
}
:deep(.v-data-table thead th:first-child) {
  margin-bottom: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
:deep(.v-data-table thead th:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
:deep(.v-data-table__td:first-child) {
  border-radius: 10px 0 0 10px !important;
}
:deep(.v-data-table__td:last-child) {
  border-radius: 0 10px 10px 0 !important;
}
:deep(.v-data-table tbody tr) {
  background-color: #ececec;
  color: #00507d;
  font-size: 12px;
  text-transform: uppercase;
}
:deep(.v-data-table tbody tr):hover {
  background-color: #d9d9d9;
}
.custom-card :deep(::-webkit-scrollbar-track) {
  margin-top: 4em !important ;
}
@media screen and (max-width: 1750px) {
  .custom-card {
    height: 90%;
  }
}
</style>
