<script setup>
import deadlineChart from "@/components/Charts/deadlineChart.js";
import LineChart from "@/components/Charts/LineChart.vue";
import { formatDate } from "@/utils/formatDate";
const { chartOptions } = deadlineChart;
import { ref, onMounted, computed } from "vue";

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const data = ref({});
const isLoading = ref(true);

const labels = computed(() =>
  data.value.chartList
    ? data.value.chartList.map((e) => formatDate(e.date.seconds))
    : [],
);
const plan = computed(() =>
  data.value.chartList ? data.value.chartList.map((e) => e.plan) : [],
);
const fact = computed(() =>
  data.value.chartList ? data.value.chartList.map((e) => e.fact) : [],
);
const deviation = computed(() =>
  data.value.chartList ? data.value.chartList.map((e) => e.deviation) : [],
);
const deviationColor = computed(() =>
  deviation.value.map((deviationItem) => {
    if (deviationItem >= 0) {
      return "#FF5252";
    } else {
      return "#1BC200";
    }
  }),
);
const chartData = computed(() => ({
  labels: [...new Set(labels.value)],
  datasets: [
    {
      label: "Plan",
      data: plan.value,
      borderColor: "#4285F4",
      backgroundColor: "#4285F4",
      pointBorderColor: "#4285F4",
      pointBackgroundColor: "#4285F4",
      fill: false,
      tension: 0.1,
      borderWidth: 2,
    },
    {
      label: "Data",
      data: fact.value,
      borderColor: "#FFA726",
      backgroundColor: "#FFA726",
      pointBorderColor: "#FFA726",
      pointBackgroundColor: "#FFA726",
      fill: false,
      tension: 0.1,
      borderWidth: 2,
    },
    {
      label: "Deviation",
      data: deviation.value,
      segment: {
        borderColor: (ctx) => {
          const index = ctx.p0DataIndex;
          return deviation.value[index] >= 0 ? "#FF0000" : "#1BC200";
        },
      },
      borderColor: deviationColor.value,
      backgroundColor: deviationColor.value,
      pointBorderColor: deviationColor.value,
      pointBackgroundColor: deviationColor.value,
      fill: false,
      tension: 0.1,
      borderWidth: 2,
    },
  ],
}));
const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

const store = useStore();
import { useStore } from "vuex";

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const getGprDynamicsData = await new Promise((resolve, reject) => {
      client.getGprDynamics(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });
    data.value = getGprDynamicsData;
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
};
onMounted(() => {
  fetchDataFromGrpc();
});

const exportChartDataToExcel = () => {
  const url = `${serverUrl.value}export/GprDynamicsChart/${props.construction.actualinstance}`;
  window.open(url, "_blank");
};
</script>

<template>
  <v-card-text align="center" class="title"
    >СРЕДНИЙ ПОКАЗАТЕЛЬ ПО ВЫПОЛНЕНИЮ ГПР</v-card-text
  >
  <LineChart
    class="chart"
    height="50"
    :chartData="chartData"
    :chartOptions="chartOptions"
    :isLoading="isLoading"
  />
  <v-container fluid class="legend-wrapper pt-10">
    <v-row align="center">
      <!-- Left Side Labels -->
      <v-col style="max-width: 19em" class="col-1 pa-0">
        <v-col class="pb-0">
          <v-icon size="10" color="#0093E5">mdi-circle</v-icon>
          <span class="legend-text ml-1">План</span>
        </v-col>

        <v-col>
          <v-icon size="10" color="#FFB800">mdi-circle</v-icon>
          <span class="legend-text ml-1">Факт</span>
        </v-col>
      </v-col>

      <v-col style="max-width: 180px" class="col-1 pa-0">
        <v-col class="pb-0">
          <v-icon size="10" color="#FF0000">mdi-circle</v-icon>
          <span class="legend-text ml-1">Отклонение</span>
        </v-col>
        <v-col>
          <v-icon size="10" color="#1BC200">mdi-circle</v-icon>
          <span class="legend-text ml-1">Опережение</span>
        </v-col>
      </v-col>

      <!-- Red Cards Section -->
      <v-col class="col-9">
        <v-row dense>
          <v-card class="red-card pa-2">
            <span class="red-card-text"
              >{{ data?.percentagedeviation?.toFixed(2) }}%</span
            >
            <span class="legend-text ml-3">ОТКЛОНЕНИЕ В ПРОЦЕНТАХ</span>
          </v-card>
          <v-card class="red-card ml-4 pa-2">
            <span class="red-card-text">{{ data?.daysdeviation }} дн.</span>
            <span class="legend-text ml-3">ОТКЛОНЕНИЕ В ДНЯХ</span>
          </v-card>
        </v-row>
      </v-col>

      <!-- Export Button -->
      <v-col class="col-2">
        <v-btn
          @click="exportChartDataToExcel"
          rounded="lg"
          height="3vw"
          style="font-size: 0.8vw"
        >
          ЭКСПОРТ</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.title {
  font-size: 1.5vw;
  color: #00a3ff;
  font-weight: bold;
}
.legend-text {
  font-size: 0.8vw;
}
.red-card {
  background-color: red;
  min-width: 15vw;
}
.red-card-text {
  font-size: 1.4vw;
}
.col-1 {
  flex-basis: 13%;
  max-width: 13%;
}
.col-2 {
  flex-basis: 13%;
  max-width: 13%;
}
.col-9 {
  flex-basis: 60%;
  max-width: 60%;
}
@media (max-height: 810px) {
  .chart {
    height: 47vh !important;
  }
}
</style>
