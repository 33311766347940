<script setup>
import { ref, onMounted, computed } from "vue";
import LineChart from "@/components/Charts/QualityLineChart.vue";
import { formatDate } from "@/utils/formatDate";
import qualityChart from "@/components/Charts/qualityChart.js";
import { useStore } from "vuex";
const { chartOptions } = qualityChart;

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const data = ref({});
const datapercentagedeviation = ref(0);
const isLoading = ref(true);

const labels = computed(() =>
  data.value.chartList
    ? data.value.chartList.map((e) => formatDate(e.date.seconds))
    : [],
);
const plan = computed(() =>
  data.value.chartList ? data.value.chartList.map((e) => e.plan) : [],
);
const fact = computed(() =>
  data.value.chartList ? data.value.chartList.map((e) => e.fact) : [],
);
const chartData = computed(() => ({
  labels: [...new Set(labels.value)],
  datasets: [
    {
      label: "Plan",
      data: plan.value,
      borderColor: "#FF0000",
      backgroundColor: "#FF0000",
      pointBorderColor: "#FF0000",
      pointBackgroundColor: "#FF0000",
      fill: false,
      tension: 0.1,
      borderWidth: 2,
    },
    {
      label: "Data",
      data: fact.value,
      borderColor: "#1BC200",
      backgroundColor: "#1BC200",
      pointBorderColor: "#1BC200",
      pointBackgroundColor: "#1BC200",
      fill: false,
      tension: 0.1,
      borderWidth: 2,
    },
  ],
}));

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const getDeviationsDynamicsData = await new Promise((resolve, reject) => {
      client.getDeviationsDynamics(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });
    data.value = getDeviationsDynamicsData;
    // console.log(getDeviationsDynamicsData)
    datapercentagedeviation.value =
      getDeviationsDynamicsData.percentagedeviation.toFixed(2);
    // console.log(datapercentagedeviation.value)
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
};
onMounted(() => {
  fetchDataFromGrpc();
});

const exportChartDataToExcel = () => {
  const url = `${serverUrl.value}export/DeviationsDynamicsChart/${props.construction.actualinstance}`;
  window.open(url, "_blank");
};
</script>

<template>
  <v-card-text align="center" class="title pb-5 pt-0"
    >ТЕНДЕНЦИИ ПО НАКОПЛЕНИЮ И ИСПРАВЛЕНИЮ ОТКЛОНЕНИЙ ПО КАЧЕСТВУ</v-card-text
  >
  <LineChart
    class="chart"
    height="50"
    :chartData="chartData"
    :chartOptions="chartOptions"
    :isLoading="isLoading"
  />
  <v-container fluid class="pt-10">
    <v-row align="center">
      <!-- Left Side Labels -->
      <v-col class="col-1 pa-0">
        <v-col class="pb-0">
          <v-icon size="10" color="#FF0000">mdi-circle</v-icon>
          <span class="text ml-1">Количество отклонений по качеству </span>
        </v-col>

        <v-col>
          <v-icon size="10" color="#1BC200">mdi-circle</v-icon>
          <span class="text ml-1">Количество исправлений</span>
        </v-col>
      </v-col>

      <!-- Red Cards Section -->
      <v-col v-if="!isLoading" class="col-9">
        <v-row dense>
          <v-card class="red-card" style="padding: 9px" height="53">
            <span class="red-card-text">{{ datapercentagedeviation }}%</span>
            <span class="legend-text ml-3">
              ОТ ОБЩЕГО ЧИСЛО ОТКЛОНЕНИЙ НЕ УСТРАНЕНО</span
            >
          </v-card>
        </v-row>
      </v-col>

      <!-- Export Button -->
      <v-col class="col-2" align="end">
        <v-btn
          @click="exportChartDataToExcel"
          rounded="lg"
          height="3vw"
          style="font-size: 0.8vw"
        >
          ЭКСПОРТ</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.title {
  font-size: 1.4vw;
  color: #00a3ff;
  font-weight: bold;
  width: 80%;
  margin: 0 auto;
}
.text {
  font-size: 0.9vw;
}
.legend-text {
  font-size: 0.7vw;
}
.red-card {
  background-color: red;
}
.red-card-text {
  font-size: 1.5vw;
}
.col-1 {
  flex-basis: 35%;
  max-width: 35%;
}
.col-2 {
  flex-basis: 15%;
  max-width: 15%;
}
.col-9 {
  flex-basis: 50%;
  max-width: 50%;
}
@media (max-height: 760px) {
  .chart {
    height: 42vh !important;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .chart {
    height: 44vh !important;
  }
}
</style>
