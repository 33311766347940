const state = {
  serverUrl: null,
  SubLocationId: 0,
  locationId: null,
  productionPredictionDate: null,
  productionLastLabelDate: null
};

const getters = {
  getServerUrl: (state) => state.serverUrl,
  getCurrentSubLocationId: (state) => state.SubLocationId,
  getCurrentLocationId: (state) => state.locationId,
  getProductionPredictionDate: (state) => state.productionPredictionDate,
  getProductionLastLabelDate: (state) => state.productionLastLabelDate
};

const actions = {
  async setServerUrl({ commit }, serverUrl) {
    await commit("setUrl", serverUrl);
  },
  async setCurrentSubLocationId({ commit }, index) {
    await commit("setSubLocationId", index);
  },
  async setCurrentLocationId({ commit }, index) {
    await commit("setLocationId", index);
  },
  async updateProductionPredictionDate({ commit }, index) {
    await commit("setProductionPredictionDate", index);
  },
  async updateProductionLastLabelDate({ commit }, index) {
    await commit("setProductionLastLabelDate", index);
  },
};

const mutations = {
  setUrl(state, serverUrl) {
    state.serverUrl = serverUrl;
  },
  setSubLocationId(state, index) {
    state.SubLocationId = index;
  },
  setLocationId(state, index) {
    state.locationId = index;
  },
  setProductionPredictionDate(state, index) {
    state.productionPredictionDate = index;
  },
  setProductionLastLabelDate(state, index) {
    state.productionLastLabelDate = index;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
