<script setup>
import { computed } from "vue";

import dummyData from "@/utils/dummyData";
import PropertyCameras from "@/components/PropertyCameras.vue";
import CardObject from "@/components/CardObject.vue";
const { cameras } = dummyData;

const props = defineProps([
  "showCards",
  "showCams",
  "construction",
  "isLoading",
]);
const cardObjectTitles = ["ВРЕМЯ", "СДАНО", "ОТКЛОНЕНИЕ"];
const arrowIconSrc = computed(() => {
  return !props.showCams
    ? new URL("@/assets/icons/arrow_left1.svg", import.meta.url).href
    : new URL("@/assets/icons/arrow_right1.svg", import.meta.url).href;
});
const generateItemImage = (multiply, itemSrc) => {
  if (itemSrc.startsWith("http")) {
    const base = 360;
    return itemSrc + `/S-${base * multiply}`;
  } else {
    return itemSrc;
  }
};
</script>

<template>
  <!-- Deadline Cameras  -->
  <v-row>
    <v-col
      :class="!props.showCards ? 'col-6' : props.showCams ? 'col-7' : 'cols-11'"
      xxl=""
    >
      <v-img
        :src="generateItemImage(2, props.construction.imagesource)"
        alt="Image"
        height="73vh"
        rounded="lg"
        class="big-img"
        cover
      >
        <CardObject
          v-if="props.construction"
          :item="props.construction"
          :isLoading="props.isLoading"
          :titles="cardObjectTitles"
        />
      </v-img>
    </v-col>
    <v-col class="col-1 pl-0 pr-0">
      <v-btn
        @click="$emit('toggle-cams')"
        rounded="sm"
        height="73vh"
        color="#2E414C"
        class="thin-btn pa-0"
        :disabled="!props.showCards"
      >
        <v-container class="d-flex flex-column">
          <img
            v-show="props.showCards"
            src="@/assets/icons/small-camera.svg"
            width="7"
          />
          <img :src="arrowIconSrc" width="7" />
        </v-container>
      </v-btn>
    </v-col>
    <v-col
      :class="!props.showCards ? 'col-5' : 'col-3'"
      v-show="props.showCams"
      class="mt-3 overflow-y-auto scroll imgs-wrapper"
      style="max-height: 76vh"
    >
      <v-row>
        <v-card
          v-for="(camera, index) in cameras"
          :key="index"
          height="100%"
          :width="!props.showCards ? '44%' : '100%'"
          style="background-color: transparent"
          class="gallery-imgs ma-3"
        >
          <PropertyCameras :key="index" :camera="camera" />
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>
.thin-btn {
  min-width: 16px;
  max-width: 16px;
}
.gallery-imgs:first-child {
  margin-top: 0 !important;
}
.col-1 {
  flex-basis: 2%;
  max-width: 2%;
}

.col-6 {
  flex-basis: 55%;
  max-width: 55%;
}
.col-5 {
  flex-basis: 42%;
  max-width: 42%;
}

.col-7 {
  flex-basis: 67%;
  max-width: 67%;
}
.col-3 {
  flex-basis: 30%;
  max-width: 30%;
}
.col-11 {
  flex-basis: 96%;
  max-width: 96%;
}
.scroll::-webkit-scrollbar-track {
  margin: 0px;
}
.v-card--variant-elevated {
  box-shadow: none;
}
@media (max-height: 760px) {
  .big-img,
  .thin-btn,
  .imgs-wrapper {
    height: 70vh !important;
  }
}
@media (min-height: 760px) {
  .big-img,
  .thin-btn,
  .imgs-wrapper {
    height: 73vh !important;
  }
}
</style>
