<template>
  <v-container>
    <v-row fluid align="center" justify="center">
      <v-col cols="12" sm="12" md="12" lg="9" xl="6" xxl="4">
        <v-img
          lazy-src="@/assets/bg/bg-2.0.png"
          src="@/assets/bg/bg-2.0.png"
          aspect-ratio="1/1"
          class="d-none d-sm-flex align-center justify-center img-shadow"
          :style="borderImgColor"
          contain
        >
          <!-- <v-sheet class="login-sheet"> -->
          <v-fade-transition>
            <v-card
              :loading="loading"
              class="mx-auto pa-lg-8 pa-md-4"
              elevation="0"
              color="transparent"
            >
              <template v-slot:loader="{ isActive }">
                <v-progress-linear
                  :active="isActive"
                  color="white"
                  height="0"
                  indeterminate
                ></v-progress-linear>
              </template>

              <form @submit.prevent="reserve" v-show="renderForm">
                <v-img
                  contain
                  height="97"
                  src="@/assets/logo/logo-asik.svg"
                  class="mb-8"
                ></v-img>

                <v-card-item class="mb-8">
                  <v-card-title
                    class="text-primary_light text_title text-center"
                    style="
                      white-space: normal;
                      word-wrap: break-word;
                      overflow-wrap: break-word;
                      font-size: 30px;
                    "
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Recover_form_title
                    }}
                  </v-card-title>

                  <v-card-subtitle
                    class="mt-1 text_subtitle text-center"
                    style="
                      white-space: normal;
                      word-wrap: break-word;
                      overflow-wrap: break-word;
                      font-size: 14px;
                    "
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Recover_form_subtitle
                    }}
                  </v-card-subtitle>
                </v-card-item>

                <v-card-item style="max-width: 50%; margin: 0 auto">
                  <!-- Recover -->
                  <v-text-field
                    :readonly="loading"
                    :loading="loading"
                    v-model="email.value.value"
                    :error-messages="email.errorMessage.value"
                    :label="
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Recover_form_email_label
                    "
                    :placeholder="
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Auth_form_email_placeholder
                    "
                    clearable
                    bg-color="form_background"
                    base-color="primary"
                    :color="colorElement"
                    variant="solo"
                    rounded="10"
                    density="compact"
                  >
                    <template v-slot:prepend-inner>
                      <v-fade-transition duration="500" mode="out-in">
                        <v-img
                          v-if="!alarm_status"
                          src="@/assets/icons/email.svg"
                          contain
                          width="24"
                          height="24"
                        />
                        <v-img
                          v-else
                          src="@/assets/icons/email.svg"
                          contain
                          width="24"
                          height="24"
                        />
                      </v-fade-transition>
                    </template>
                  </v-text-field>
                  <v-container class="ma-0 pa-0">
                    <!-- Login -->
                    <v-row>
                      <v-col>
                        <v-btn
                          :loading="loading"
                          rounded="10"
                          type="submit"
                          color="primary"
                          style="font-size: 12px"
                          :class="btn_gradient_control"
                          block
                        >
                          {{ loginLabel }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Recover User -->
                    <v-row class="mt-0">
                      <v-col>
                        <v-btn
                          :loading="loading"
                          rounded="10"
                          size="small"
                          color="secondary"
                          variant="text"
                          to="/"
                          block
                          style="font-size: 10px"
                        >
                          {{
                            $vuetify.locale.messages[$vuetify.locale.current]
                              .Recover_form_back_to_login
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-item>
              </form>

              <!-- Wrong Email -->
              <div v-show="renderValidEmail">
                <v-img
                  contain
                  height="100"
                  src="@/assets/icons/critical_warning.svg"
                  class="mb-8"
                ></v-img>
                <v-card-item class="mb-10">
                  <v-card-title
                    class="mb-7 text-h4 text-center responsive-txt text-shadow text-normal"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Recovery_form_failed_title
                    }}
                  </v-card-title>
                  <v-card-subtitle
                    class="text-h6 text-center responsive-txt text-shadow text-normal"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Recovery_form_failed_subtitle
                    }}
                  </v-card-subtitle>
                </v-card-item>
              </div>
              <!-- Valid Email -->
              <div v-show="renderInvalidEmail">
                <v-img
                  contain
                  height="100"
                  src="@/assets/icons/success.svg"
                  class="mb-8"
                ></v-img>
                <v-card-item class="mb-8">
                  <v-card-title
                    class="mb-4 text-h4 text-center responsive-txt text-shadow text-normal"
                  >
                    {{
                      $vuetify.locale.messages[$vuetify.locale.current]
                        .Recovery_form_succes_title
                    }}
                  </v-card-title>
                </v-card-item>
              </div>
            </v-card>
          </v-fade-transition>
        </v-img>

        <!-- Mobile -->
        <v-card
          :loading="loading"
          class="d-block d-sm-none mx-auto pa-4"
          elevation="0"
          color="transparent"
        >
          <template v-slot:loader="{ isActive }">
            <v-progress-linear
              :active="isActive"
              color="white"
              height="0"
              indeterminate
            ></v-progress-linear>
          </template>

          <form @submit.prevent="reserve">
            <v-img
              contain
              height="75"
              src="@/assets/logo/logo-asik.svg"
            ></v-img>

            <v-card-item>
              <v-card-title class="text-primary_light text-center">{{
                $vuetify.locale.messages[$vuetify.locale.current]
                  .Recover_form_title
              }}</v-card-title>
              <v-card-subtitle class="text-center">{{
                $vuetify.locale.messages[$vuetify.locale.current]
                  .Recover_form_subtitle
              }}</v-card-subtitle>
            </v-card-item>

            <v-card-text>
              <v-text-field
                :readonly="loading"
                :loading="loading"
                v-model="email.value.value"
                :error-messages="email.errorMessage.value"
                :label="
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_email_label
                "
                :placeholder="
                  $vuetify.locale.messages[$vuetify.locale.current]
                    .Auth_form_email_placeholder
                "
                persistent-placeholder
                clearable
                variant="filled"
                color="accent"
                prepend-icon="$vuetify"
              ></v-text-field>

              <v-container>
                <v-row>
                  <v-col>
                    <v-btn
                      :loading="loading"
                      type="submit"
                      color="accent"
                      append-icon="mdi-login-variant"
                      variant="outlined"
                      block
                    >
                      {{
                        $vuetify.locale.messages[$vuetify.locale.current]
                          .Recover_form_recover
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      :loading="loading"
                      color="primary"
                      append-icon="mdi-login-variant"
                      variant="outlined"
                      to="/"
                      block
                    >
                      {{
                        $vuetify.locale.messages[$vuetify.locale.current]
                          .Recover_form_back_to_login
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <!-- <v-fade-transition>
              <v-card-item v-show='alarm_status'>
                <v-alert :type="alarm_type"> 
                  {{ alarm_message }}
                </v-alert>
              </v-card-item>
            </v-fade-transition> -->
          </form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { ref } from 'vue'
import { useField, useForm } from "vee-validate";

export default {
  name: "RecoverLogin",

  components: {},

  data: () => ({
    loading: false,
    show1: false,
    alarm_status: false,
    alarm_message: "",
    alarm_type: "",
    tos_confirm: false,
    password_confirm: "",
    showForm: true,
    showValidEmail: false,
    showInvalidEmail: false,
  }),

  setup() {
    const { handleSubmit, handleReset } = useForm({
      validationSchema: {
        email(value) {
          if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true;

          return "Must be a valid e-mail.";
        },
      },
    });
    const email = useField("email");

    const submit = handleSubmit((values) => {
      // console.log(values)
      alert(JSON.stringify(values, null, 2));
    });
    return { email, submit, handleReset };
  },

  computed: {
    loginLabel() {
      const currentLocale = this.$vuetify.locale.current;
      const messages = this.$vuetify.locale.messages[currentLocale];
      return this.alarm_status
        ? messages.Recover_form_recover_failed
        : messages.Recover_form_recover;
    },
    btn_gradient_control() {
      return this.alarm_status ? "gradient-btn-error" : "gradient-btn";
    },
    login_icon_control() {
      return this.alarm_status
        ? "@/assets/icons/login_failed.svg"
        : "@/assets/icons/login.svg";
    },
    colorElement() {
      return this.alarm_status ? "error" : "primary";
    },
    borderImgColor() {
      if (!this.showValidEmail && !this.showInvalidEmail) {
        return "outline: 7px solid #72BADE";
      } else if (this.showValidEmail) {
        return "outline: 7px solid green";
      } else {
        return "outline: 7px solid darkred";
      }
    },
    renderForm() {
      return this.showForm;
    },
    renderValidEmail() {
      return this.showValidEmail;
    },
    renderInvalidEmail() {
      return this.showInvalidEmail;
    },
  },

  methods: {
    async reserve() {
      // function getRandomInt(max) {
      //   return Math.floor(Math.random() * max);
      // }
      this.loading = true;
      // let status = getRandomInt(2);

      // const payload = {
      //   email: this.email.value.value,
      // }

      // const thisResponse = {
      //   data: {
      //     token: "Bearer 21344141w3rfdsafdsfe",
      //     user: {
      //         id: 123,
      //         email: "test@test.com",
      //         disabled: false,
      //         dateCreated: "1723456468"
      //     }
      // }
      // }

      setTimeout(() => this.pick_event(), 500);
    },
    pick_event() {
      function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }
      let status = getRandomInt(2);
      console.log(status);
      this.loading = false;
      this.showForm = false;
      if (status == 0) {
        this.alarm_status = false;
        this.showValidEmail = true;
        this.showInvalidEmail = false;
      } else {
        this.alarm_status = true;
        this.showInvalidEmail = true;
        this.showValidEmail = false;
      }

      setTimeout(() => this.reset_form(), 5000);
    },
    reset_form() {
      this.alarm_status = false;
      this.showValidEmail = false;
      this.showInvalidEmail = false;
      this.showForm = true;
    },
  },
};
</script>
<style scoped>
.gradient-btn {
  background: linear-gradient(to right, #006aa6, #004b76);
  color: white; /* Ensure the text color is visible on the gradient */
}

/* Remove the default background color applied by Vuetify */
.gradient-btn.v-btn {
  background-color: transparent !important;
}

.gradient-btn-error {
  background: linear-gradient(to right, #ff0000, #990000);
  color: white; /* Ensure the text color is visible on the gradient */
}

/* Remove the default background color applied by Vuetify */
.gradient-btn-error.v-btn {
  background-color: transparent !important;
}

.img-shadow {
  outline-offset: -7em;
  border-radius: 50%;
  box-shadow:
    0 0 7em #72bade,
    20em 0px 100px rgba(0, 153, 255, 0.2),
    -20em 0px 100px rgba(0, 153, 255, 0.2);
}

@media (min-width: 1920px) {
  .img-shadow {
    outline-offset: -7.5em; /* example of changing the offset */
    box-shadow:
      0 0 10em #72bade,
      30em 0px 150px rgba(0, 153, 255, 0.3),
      -30em 0px 150px rgba(0, 153, 255, 0.3);
  }
}

@media (min-width: 2560px) {
  .img-shadow {
    outline-offset: -8em; /* example of changing the offset */
    box-shadow:
      0 0 10em #72bade,
      30em 0px 150px rgba(0, 153, 255, 0.3),
      -30em 0px 150px rgba(0, 153, 255, 0.3);
  }
}

.responsive-txt {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.text-title {
  font-size: 26px;
}

.text-shadow {
  text-shadow: 0px 0px 10px black;
}
.circle-img {
  outline-offset: -6em;
  border-radius: 50%;
  box-shadow:
    0px 0px 90px 0px rgba(189, 234, 255),
    15em 0px 100px rgba(83, 201, 255, 0.1),
    -18em 0px 100px rgb(83, 201, 255, 0.1);
}
</style>
