<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();
const clickedButton = computed(
  () => store.getters["clickedButton/getClickedButton"],
);

const redirectToHome = () => {
  router.push("/home");
};

const logOut = () => {
  router.push("/");
};

const handleButtonClick = (buttonName) => {
  store.dispatch("clickedButton/updateClickedButton", buttonName);
  store.dispatch("currentIndex/updateCurrentIndex", 0);
};

const btnStyle = (buttonName) => {
  return clickedButton.value === buttonName ? "img-btn-clicked" : "img-btn";
};
</script>

<template>
  <v-app-bar
    app
    dark
    image="@/assets/bg/interface.png"
    position="fixed"
    height="124"
  >
    <v-img
      @click="redirectToHome"
      class="cursor-pointer mr-5"
      contain
      height="76"
      src="@/assets/logo/asic-ins.png"
      width="20%"
    ></v-img>
    <v-row align="center" class="mr-1 d-none d-md-flex">
      <v-col cols="10">
        <v-btn
          @click="handleButtonClick('СРОКИ')"
          :class="btnStyle('СРОКИ')"
          rounded="lg"
          width="7vw"
          height="3.5vw"
        >
          <v-img
            src="@/assets/icons/term.svg"
            width="2vw"
            height="3vw"
            class="mr-2"
          ></v-img>
          <span class="text-btn">СРОКИ</span>
        </v-btn>
        <v-btn
          @click="handleButtonClick('КАЧЕСТВО')"
          :class="btnStyle('КАЧЕСТВО')"
          rounded="lg"
          width="7vw"
          height="3.5vw"
        >
          <v-img
            src="@/assets/icons/quality.svg"
            width="2vw"
            height="1.5vw"
          ></v-img>
          <span class="text-btn">КАЧЕСТВО</span>
        </v-btn>
        <v-btn
          @click="handleButtonClick('ПРОИЗВОДСТВО')"
          :class="btnStyle('ПРОИЗВОДСТВО')"
          rounded="lg"
          width="7vw"
          height="3.5vw"
        >
          <v-img
            src="@/assets/icons/production.svg"
            width="2vw"
            height="1.5vw"
            class="mr-1"
          ></v-img>
          <span class="text-btn">ПРОИЗ-ВО</span>
        </v-btn>
        <v-btn
          @click="handleButtonClick('ТОП10')"
          :class="btnStyle('ТОП10')"
          rounded="lg"
          width="7vw"
          height="3.5vw"
        >
          <v-img
            src="@/assets/icons/top10.svg"
            width="2vw"
            height="3vw"
            class="mr-1"
          ></v-img>
          <span class="text-btn">ТОП-10</span>
        </v-btn>
        <v-btn
          @click="handleButtonClick('СПЕЦОТЧЕТ')"
          :class="btnStyle('СПЕЦОТЧЕТ')"
          rounded="lg"
          width="7vw"
          height="3.5vw"
        >
          <v-img
            src="@/assets/icons/special.svg"
            width="2vw"
            height="3vw"
          ></v-img>
          <span class="text-btn">СПЕЦ ОТЧ</span>
        </v-btn>
        <v-btn
          @click="handleButtonClick('ДЕЙСТВИЯ')"
          :class="btnStyle('ДЕЙСТВИЯ')"
          rounded="lg"
          width="7vw"
          height="3.5vw"
        >
          <v-img
            src="@/assets/icons/actions.svg"
            width="2vw"
            height="2vw"
          ></v-img>
          <span class="text-btn">ДЕЙСТВИЯ</span>
        </v-btn>
        <v-btn
          @click="redirectToHome()"
          :class="btnStyle('МЕСТОПОЛОЖЕНИЕ')"
          rounded="lg"
          width="3vw"
          height="3.5vw"
        >
          <v-img
            src="@/assets/icons/locations.svg"
            width="2vw"
            height="3vw"
          ></v-img>
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-img
          contain
          src="@/assets/icons/logout.svg"
          width="2vw"
          height="1.5vw"
          class="img-logout cursor-pointer"
          @click="logOut"
        ></v-img>
        <v-img
          contain
          height="3vw"
          width="9vw"
          src="@/assets/logo/logo-asik.svg"
        ></v-img>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<style scoped>
.text-btn {
  font-size: 0.7vw;
}
.img-logout {
  position: absolute;
  top: 1em;
  right: 1em;
}
.cursor-pointer {
  cursor: pointer;
}
.img-btn {
  background: radial-gradient(closest-side, #106595 20%, #001825 90%);
  margin-right: 1.4vw;
}
.img-btn-clicked {
  background: radial-gradient(closest-side, #106595 20%, #001825 90%);
  box-shadow: 0px 0px 5px 5px #106595;
  margin-right: 1.4vw;
}
.img-btn:hover {
  box-shadow: 0px 0px 5px 5px #106595;
}
</style>
