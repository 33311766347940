/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from './plugins'
import store from './store'; // Adjust the path if needed

// Components
import App from './App.vue'
import { loadFonts } from './plugins/webfontloader.js'

// Composables
import { createApp } from 'vue'

loadFonts()

const app = createApp(App)
app.use(store);

registerPlugins(app)
app.mount('#app')

