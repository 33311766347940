export function formatDate(seconds, isMiliSeconds=false) {
  if (isMiliSeconds) {
    const date = new Date(parseInt(seconds));

    const formattedDate = date.toLocaleDateString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    // console.log(formattedDate)
    return formattedDate;
  } else {
    if (isNaN(seconds)) {
      // console.log("Already formatted:", seconds);/
      return seconds;
    }
  
  // console.log("Unix timestamp:", seconds);
  const date = new Date(parseInt(seconds) * 1000);
  // console.log(date);
  
  const formattedDate = date.toLocaleDateString("ru-RU", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
  });
  
  // console.log("Formatted date:", formattedDate);
  return formattedDate;
  }

}

