import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

// router setup
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// Global beforeEach route guard
router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  // console.log(requiresAuth)

  const isAuthenticated = store.getters["users/isAuthenticated"];
  // console.log(isAuthenticated)

  if (requiresAuth && !isAuthenticated) {
    next({ name: "Login" });
  } else {
    next();
  }
});

router.afterEach(() => {
  store.commit("currentIndex/setCurrentIndex", 0);
  store.commit("clickedButton/setClickedButton", "СРОКИ");
});

export default router;
