<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import FloorProgressBar from "@/components/data/FloorProgressBar.vue";
import MainFloorProgressBar from "@/components/data/MainFloorProgressBar.vue";

const store = useStore();
const isLoading = ref(true);
const allBuildings = ref([]);
const building = ref({});
const buildingDynamics = ref(0);
const buildingDynamicsDirection = ref(false);
const currentIndex = ref(0);
const locationIndex = computed(
  () => store.getters["appData/getCurrentLocationId"],
);

const backgroundColors = [
  `linear-gradient(121deg, rgba(151, 73, 75,1) 4%, rgba(117, 56, 58,1) 33%, rgba(0, 24, 37,1) 100%)`,
  `linear-gradient(121deg, rgba(72,187,55,1) 4%, rgba(52,135,39,1) 33%, rgba(0,24,37,1) 100%)`,
  `linear-gradient(121deg, rgba(25,91,182,1) 4%, rgba(16,75,157,1) 33%, rgba(0,24,37,1) 100%)`,
  `linear-gradient(121deg, rgba(192,18,128,1) 4%, rgba(169,5,109,1) 33%, rgba(0,24,37,1) 100%)`,
  `linear-gradient(121deg, rgba(127,147,73,1) 4%, rgba(108,126,59,1) 33%, rgba(0,24,37,1) 100%)`,
];
const showNextBuilding = () => {
  currentIndex.value =
    (currentIndex.value + 1) % allBuildings.value.subitemsList.length;
  building.value = allBuildings.value.subitemsList[currentIndex.value];
  buildingDynamics.value =
    allBuildings.value.subitemsList[currentIndex.value].currentdynamics.value;
  buildingDynamicsDirection.value =
    allBuildings.value.subitemsList[
      currentIndex.value
    ].currentdynamics.direction;
};

const showPreviousBuilding = () => {
  currentIndex.value =
    (currentIndex.value - 1 + allBuildings.value.subitemsList.length) %
    allBuildings.value.subitemsList.length;
  building.value = allBuildings.value.subitemsList[currentIndex.value];
  buildingDynamics.value =
    allBuildings.value.subitemsList[currentIndex.value].currentdynamics.value;
  buildingDynamicsDirection.value =
    allBuildings.value.subitemsList[
      currentIndex.value
    ].currentdynamics.direction;
};

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb.js");

const fetchDataFromGrpc = async () => {
  try {
    isLoading.value = true;
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };

    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const structuresExecutionTree = await new Promise((resolve, reject) => {
      client.getStructuresExecutionTree(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject().valuesList);
        }
      });
    });

    allBuildings.value = locationIndex.value
      ? structuresExecutionTree.find((item) => item.id === locationIndex.value)
      : structuresExecutionTree[0];
    building.value = allBuildings.value.subitemsList[0];
    buildingDynamics.value =
      allBuildings.value.subitemsList[0].currentdynamics.value;
    buildingDynamicsDirection.value =
      allBuildings.value.subitemsList[0].currentdynamics.direction;
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

watch(locationIndex, async () => {
  await fetchDataFromGrpc();
});

onMounted(async () => {
  await fetchDataFromGrpc();
});

const goToTimeTable = async (index) => {
  try {
    await store.dispatch("appData/setCurrentSubLocationId", index);
    await store.dispatch("clickedButton/updateClickedButton", "СРОКИ");
    await store.dispatch("currentIndex/updateCurrentIndex", 4);
  } catch (error) {
    console.error("Error occurred:", error);
  }
};
const goToQualityTable = async (index) => {
  try {
    await store.dispatch("appData/setCurrentSubLocationId", index);
    await store.dispatch("clickedButton/updateClickedButton", "КАЧЕСТВО");
    await store.dispatch("currentIndex/updateCurrentIndex", 3);
  } catch (error) {
    console.error("Error occurred:", error);
  }
};

const getImage = (index) => {
  if (index === 0) {
    return require("../../../assets/ladder/ladder-top.png"); // First element image
  } else if (index === building.value.subitemsList.length - 1) {
    return require("../../../assets/ladder/ladder-bot.png"); // Last element image
  } else {
    return require("../../../assets/ladder/ladder-mid.png"); // In-between elements
  }
};
</script>

<template>
  <v-row class="wrapper align-center justify-center" style="gap: 12px">
    <img
      src="@/assets/icons/left_line.svg"
      @click="showPreviousBuilding"
      style="cursor: pointer"
    />
    <div class="custom-btn-2 pa-4">
      <v-row class="align-center justify-space-between">
        <v-col cols="10" class="custom-btn">
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="white"
          ></v-progress-linear>
          <span v-else class="label-text">
            {{ building?.name }}
          </span>
        </v-col>
        <v-col
          cols="2"
          class="pa-0 ma-0"
          style="
            background-color: #00466e;
            border-radius: 0 10px 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <!-- TODO days -->
          <span class="mr-1" style="font-size: 0.9vw"
            >{{ buildingDynamics }} дн.</span
          >
          <img
            v-if="buildingDynamicsDirection"
            src="@/assets/icons/arrow.svg"
            height="20"
          />
          <img v-else src="@/assets/icons/red_arrow.svg" height="20" />
        </v-col>
      </v-row>
    </div>

    <img
      src="@/assets/icons/right_line.svg"
      @click="showNextBuilding"
      style="cursor: pointer"
    />
  </v-row>

  <v-container class="d-flex mb-1" style="width: 60%">
    <v-row align="center" class="ma-0">
      <MainFloorProgressBar
        title="ВРЕМЯ"
        :percents="building?.planpercentage || 0"
        :size="0.8"
        :color="1"
      />
    </v-row>
    <v-row align="center" class="ma-0">
      <MainFloorProgressBar
        title="СДАНО"
        :percents="building?.factpercentage || 0"
        :size="0.8"
        :color="2"
      />
    </v-row>
  </v-container>

  <v-progress-linear
    color="blue"
    indeterminate
    v-if="isLoading"
  ></v-progress-linear>
  <v-container v-else class="floors-wrapper pb-8">
    <v-row
      style="z-index: 0 ma-0 pa-0"
      v-for="(floorItem, index) in building?.subitemsList"
      :key="floorItem.id"
    >
      <v-card
        :class="{
          'first-card': index === 0,
          'last-card': index === building.subitemsList.length - 1,
        }"
        class="floor-card"
        width="100%"
        :style="{
          background: backgroundColors[index % backgroundColors.length],
        }"
      >
        <v-img
          :src="getImage(index)"
          style="z-index: 10"
          width="100%"
          class="ma-0 pa-0"
          cover
        >
          <v-row class="d-flex">
            <v-col cols="2" class="text-center pl-10 pt-8">
              {{ floorItem.name }}
            </v-col>
            <v-col class="border" cols="5"></v-col>

            <!-- Floors progress bar section -->
            <v-col class="pa-12" cols="5">
              <v-row>
                <v-row dense align="center" class="ma-0">
                  <FloorProgressBar
                    title="ВРЕМЯ"
                    :percents="floorItem.planpercentage || 0"
                    :size="0.8"
                    :color="1"
                  />
                </v-row>
                <v-col class="d-flex justify-space-between align-center px-0">
                  <div class="d-flex wrap-nowrap align-center">
                    <v-icon size="35" class="mr-2"
                      >mdi-clock-time-three-outline</v-icon
                    >
                    <span class="mr-2"> Выполнение </span>
                  </div>
                  <v-btn rounded="lg" class="mr-1">
                    <v-icon @click="goToTimeTable(floorItem.id)" size="35"
                      >mdi-menu-right</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-row align="center" class="ma-0">
                  <FloorProgressBar
                    title="СДАНО"
                    :percents="floorItem.factpercentage || 0"
                    :size="0.8"
                    :color="2"
                  />
                </v-row>
                <v-col class="d-flex justify-space-between align-center px-0">
                  <div class="d-flex wrap-nowrap align-center justify-start">
                    <v-icon size="35" class="mr-2">mdi-set-square </v-icon>
                    <span class="mr-2"> Качество </span>
                  </div>
                  <v-btn rounded="lg" class="mr-1">
                    <v-icon @click="goToQualityTable(floorItem.id)" size="35"
                      >mdi-menu-right</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
    </v-row>
  </v-container>
</template>

<style scoped>
.wrapper {
  width: 100%;
  padding-bottom: 1em;
  margin: 0 auto;
}
.floors-wrapper {
  max-height: 57vh;
  overflow-y: auto;
  border-radius: 8px !important;
}
.custom-btn {
  background-color: #00a3ff;
  color: white;
  padding: 6px;
  border-radius: 10px;
  width: 90%;
  text-align: center;
  min-height: 40px;
}
.custom-btn-2 {
  background-color: #00466e;
  color: white;
  padding: 6px;
  border-radius: 10px;
  width: 50%;
  text-align: center;
  min-height: 40px;
}
.floor-card :deep(.v-btn--size-default) {
  min-width: 40px !important;
  padding: 0 !important;
}

.floor-card {
  border-bottom: 1px solid white;
  border-radius: unset !important;
}

.first-card {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.last-card {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.border {
  border-right: 1px solid white !important;
}
.label-text {
  font-weight: bold;
  font-size: 0.9vw;
}
:deep(::-webkit-scrollbar-track) {
  margin: 0 !important;
}
@media (max-height: 710px) {
  .wrapper {
    padding-bottom: 0px;
  }
}
</style>
