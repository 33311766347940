<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { formatDate } from "@/utils/formatDate";
import * as XLSX from "xlsx";
import { useStore } from "vuex";

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiReportService-v2_pb");

const {
  ConstructionDateRequest,
} = require("../../../proto-out/IMultiReportService-v2_pb");

const {
  MultiReportServiceClient,
} = require("../../../proto-out/IMultiReportService-v2_grpc_web_pb");

const { Timestamp } = require("google-protobuf/google/protobuf/timestamp_pb");

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const store = useStore();
const selectedItem = ref(null);
const specialReportDates = ref([]);
const specialReportTableData = ref({
  Items: [],
});
const specialReportDatesRaw = computed(() =>
  specialReportDates.value.map((item) => ({
    ...item,
    displayDate: formatDate(item.seconds),
  })),
);
const specialReportData = ref({});

const selectedFIO = ref(null);
const specialReportFIO = ref([]);

const isLoading = ref(true);

const createHeader = (title, key, maxWidth, img, additionalProps = {}) => ({
  title,
  key,
  maxWidth,
  img,
  sortable: false,
  align: "center",
  ...additionalProps,
});

const specialReportHeaders = ref([
  createHeader(
    "№ п/п",
    "num",
    "4em",
    new URL("@/assets/icons/report/clipboard-1.svg", import.meta.url).href,
  ),
  createHeader(
    "Наименование",
    "name",
    "12em",
    new URL("@/assets/icons/report/clipboard-2.svg", import.meta.url).href,
  ),
  createHeader(
    "Подрядная организация",
    "contractingorganizationname",
    "11em",
    new URL("@/assets/icons/report/clipboard-3.svg", import.meta.url).href,
  ),
  createHeader(
    "Локация Блок-Секция",
    "location",
    "7em",
    new URL("@/assets/icons/report/clipboard-4.svg", import.meta.url).href,
  ),
  createHeader(
    "Ед. изм.",
    "measure",
    "4em",
    new URL("@/assets/icons/report/clipboard-5.svg", import.meta.url).href,
  ),
  createHeader(
    "Объем",
    "volume",
    "4em",
    new URL("@/assets/icons/report/clipboard-6.svg", import.meta.url).href,
  ),
  createHeader(
    "Оста-лось",
    "notcompletedvalue",
    "4em",
    new URL("@/assets/icons/report/bullet-perc.svg", import.meta.url).href,
  ),
  createHeader(
    "Примечание",
    "note",
    "9em",
    new URL("@/assets/icons/report/clipboard-7.svg", import.meta.url).href,
  ),

  createHeader(
    "Дата начала работ",
    "startdate",
    "4em",
    new URL("@/assets/icons/report/clipboard-8.svg", import.meta.url).href,
  ),
  createHeader(
    "Дата завер-шения работ",
    "enddate",
    "4em",
    new URL("@/assets/icons/report/clipboard-9.svg", import.meta.url).href,
  ),
]);

const smallText = (header) => {
  return header === "enddate" || header === "startdate"
    ? "height-span-small"
    : "height-span";
};

const expandIcon = (item) => {
  return expanded.value.includes(item.id)
    ? new URL("@/assets/bg/arrow_up.png", import.meta.url).href
    : new URL("@/assets/bg/arrow_down.png", import.meta.url).href;
};

const expanded = ref([]);
const isOpen = ref(false);

const openDialog = () => {
  isOpen.value = true;
};

const closeDialog = () => {
  isOpen.value = false;
};

const toggleExpand = (item) => {
  const index = expanded.value.indexOf(item.id);
  if (index > -1) {
    expanded.value.splice(index, 1);
  } else {
    expanded.value.push(item.id);
  }
};

watch(selectedItem, async (newValue) => {
  if (newValue) {
    await fetchTableDataFromGrpc(newValue);
  }
});

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchTableDataFromGrpc = async (item) => {
  const data = typeof item === "string" ? specialReportDates?.value[0] : item;
  try {
    isLoading.value = true;
    const client = new MultiReportServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };

    const timestamp = new Timestamp();
    timestamp.setSeconds(Number(data.seconds || data));
    timestamp.setNanos(Number(0));

    const request = new ConstructionDateRequest();
    request.setConstructioninstance(props.construction.actualinstance);
    request.setDate(timestamp);

    const getSpecialReportData = await new Promise((resolve, reject) => {
      client.getSpecialReport(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });
    specialReportTableData.value = [];
    specialReportTableData.value.Items = formatData(
      getSpecialReportData.itemsList,
    );
    // console.log(getSpecialReportData);
    specialReportFIO.value = getSpecialReportData.editedbyusersList;
    selectedFIO.value = getSpecialReportData.editedbyusersList[0];
    specialReportData.value = getSpecialReportData;
    specialReportHeaders.value = specialReportHeaders.value.filter((header) => {
      const isNumericDateKey = /^date\d+$/.test(header.key);
      return !isNumericDateKey;
    });
    const volumeIndex =
      specialReportHeaders.value.findIndex(
        (header) => header.key === "volume",
      ) + 1;
    const revertedDates = getSpecialReportData.reportdatesList.sort(
      (a, b) => a.seconds - b.seconds,
    );

    const headersData = revertedDates.map((dateObj, index) => {
      const imagePath = require(
        `@/assets/icons/report/bullet-${index + 1}.svg`,
      );

      const dateHeaders = {
        title: `${formatDate(dateObj.seconds).replace(
          /(\d{2}\.\d{2}\.)(\d{4})/,
          "$1 $2",
        )}`,
        key: `date${index + 1}`,
        maxWidth: "4em",
        img: imagePath,
        sortable: false,
        align: "center",
      };
      return dateHeaders;
    });
    specialReportHeaders.value.splice(volumeIndex, 0, ...headersData);
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const fetchDataFromGrpc = async () => {
  try {
    isLoading.value = true;
    const client = new MultiReportServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const getSpecialReportDates = await new Promise((resolve, reject) => {
      client.getSpecialReportDates(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });

    specialReportDates.value = getSpecialReportDates.valuesList;
    const sortedDates = getSpecialReportDates.valuesList.sort(
      (a, b) => b.seconds - a.seconds,
    );

    const lastDate = Array.isArray(sortedDates) ? sortedDates[0] : sortedDates;
    selectedItem.value = formatDate(lastDate?.seconds);
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchTableDataFromGrpc(selectedItem.value);
  fetchDataFromGrpc();
});

const formatData = (rawData) => {
  const groupedData = {};

  rawData.forEach((item) => {
    const mainNum = item.num.split(".")[0];

    if (!groupedData[mainNum]) {
      groupedData[mainNum] = {
        ...item,
        children: [],
      };
    }

    if (item.num.includes(".")) {
      groupedData[mainNum].children.push(item);
    }
  });

  return Object.values(groupedData);
};

// Exporting data to our xlxs
// const formatDateToReal = (seconds) => {
//   const date = new Date(seconds * 1000);
//   return date.toLocaleDateString();
// };

const exportToExcel = () => {
  if (
    !specialReportTableData.value.Items ||
    specialReportTableData.value.Items.length === 0
  ) {
    console.error("Нет данных для экспорта!");
    return;
  }

  // Process main and child data
  const transformedData = specialReportTableData.value.Items.map((item) => {
    const mainData = {
      num: item.num,
      name: item.name,
      contractingorganizationname: item.contractingorganizationname,
      location: item.location,
      measure: item.measure,
      volume: item.volume,
      notcompletedvalue: item.notcompletedvalue,
      note: item.note, // Комментарий field
      startdate: formatDate(item.startdate.seconds),
      enddate: formatDate(item.enddate.seconds),
      reportvalues: item.reportvaluesList, // Keep as array for mapping dynamic headers
    };

    const childrenData = item.children
      ? item.children.map((child) => ({
          num: child.num,
          name: child.name,
          contractingorganizationname: child.contractingorganizationname,
          location: child.location,
          measure: child.measure,
          volume: child.volume,
          notcompletedvalue: child.notcompletedvalue,
          note: child.note, // Комментарий field
          startdate: formatDate(child.startdate.seconds),
          enddate: formatDate(child.enddate.seconds),
          reportvalues: child.reportvaluesList, // Keep as array for mapping dynamic headers
        }))
      : [];

    return [mainData, ...childrenData];
  });
  const flatData = transformedData.flat();
  // console.log(flatData)

  // Static headers
  const staticHeaders = [
    "№ п/п",
    "Наименование",
    "Подрядная организация",
    "Локация Блок-Секция",
    "Ед. изм.",
    "Объем",
  ];

  const staticHeaders_2 = [
    "Осталось",
    "Примечание",
    "Дата начала работ",
    "Дата завер-шения работ",
  ];

  // Dynamic date headers
  const dynamicHeaders = specialReportHeaders.value
    .filter(
      (header) =>
        header.key.includes("date") &&
        !header.key.includes("start") &&
        !header.key.includes("end"),
    )
    .map((header) => header.title); // Use date titles dynamically
  // Combine all headers
  const headers = [...staticHeaders, ...dynamicHeaders, ...staticHeaders_2];
  // console.log(headers)

  // Map data to rows
  const rows = flatData.map((item) => {
    const staticRow = [
      item.num,
      item.name,
      item.contractingorganizationname,
      item.location,
      item.measure,
      item.volume,
    ];

    const staticRow2 = [
      item.notcompletedvalue,
      item.note,
      formatDate(item.startdate),
      formatDate(item.enddate),
    ];

    // Map dynamic date values from reportvalues
    const dynamicValues = dynamicHeaders.map(
      (_, index) => item.reportvalues[index] || "",
    );

    return [...staticRow, ...dynamicValues, ...staticRow2];
  });
  // Prepare worksheet data
  const worksheetData = [headers, ...rows];

  // Create worksheet and apply styles
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  headers.forEach((_, colIdx) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIdx });
    if (!worksheet[cellAddress]) return;
    worksheet[cellAddress].s = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "D9EAD3" } },
    };
  });

  // Set column widths
  worksheet["!cols"] = headers.map(() => ({ wpx: 150 }));

  // Create workbook and export
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Отчет");

  const date = new Date();
  const dateString = date.toLocaleDateString("en-GB").replace(/\//g, "_");
  const fileName = `${dateString}_мултикейс_спец_отчет.xlsx`;
  XLSX.writeFile(workbook, fileName);
};
</script>

<template>
  <v-progress-linear
    v-if="isLoading"
    indeterminate
    color="blue"
  ></v-progress-linear>
  <v-container v-else fluid class="pa-0">
    <v-row align="center">
      <v-col>
        <v-autocomplete
          label="ОТЧЕТ"
          class="autocomplete autocomplete-dropdown"
          bg-color="white"
          color="black"
          variant="solo-filled"
          density="compact"
          hide-details
          rounded="lg"
          :items="specialReportDatesRaw"
          item-title="displayDate"
          item-value="seconds"
          v-model="selectedItem"
          :style="{ border: '2px solid #00A3FF', borderRadius: '10px' }"
          menu-icon="mdi-chevron-down"
        >
          <template v-slot:no-data>
            <div class="text-center">
              <p>Не найдено ни одного элемента</p>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col>
        <v-autocomplete
          label="ФИО"
          :items="specialReportFIO"
          v-model="selectedFIO"
          class="autocomplete"
          bg-color="white"
          variant="solo"
          density="compact"
          hide-details
          rounded="lg"
          :style="{ border: '2px solid #00A3FF', borderRadius: '10px' }"
          menu-icon="mdi-chevron-down"
        >
          <template v-slot:no-data>
            <div class="text-center">
              <p>Не найдено ни одного элемента</p>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-card-text align="center" class="title mt-5 pa-2">
      {{ specialReportData.title }}
    </v-card-text>

    <v-data-table
      :headers="specialReportHeaders"
      :items="specialReportTableData.Items"
      item-value="id"
      class="custom-data-table"
      height="45vh"
      fixed-header
      v-model:expanded="expanded"
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:no-data>
        <div class="text-center">
          <p>Не найдено ни одного элемента</p>
        </div>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <tr v-for="(child, childIndex) in item.children" :key="childIndex">
          <td
            v-for="(column, indexCell) in columns"
            :key="indexCell"
            class="expand-table"
            :style="{ width: specialReportHeaders[indexCell].maxWidth }"
          >
            <template v-if="column.key === 'startdate'">
              {{ formatDate(child.startdate.seconds) }}
            </template>
            <template v-else-if="column.key === 'enddate'">
              {{ formatDate(child.enddate.seconds) }}
            </template>
            <template v-else-if="column.key === 'date1'">
              {{ child["reportvaluesList"][0] }}
            </template>
            <template v-else-if="column.key === 'date2'">
              {{ child["reportvaluesList"][1] }}
            </template>
            <template v-else-if="column.key === 'date3'">
              {{ child["reportvaluesList"][2] }}
            </template>
            <template v-else-if="column.key === 'date4'">
              {{ child["reportvaluesList"][3] }}
            </template>
            <template v-else-if="column.key === 'date5'">
              {{ child["reportvaluesList"][4] }}
            </template>
            <template v-else-if="column.key === 'date6'">
              {{ child["reportvaluesList"][5] }}
            </template>
            <template v-else>
              {{ child[column.key] }}
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar
          flat
          style="background-color: transparent; border: 2px solid #006aa6"
        >
          <v-toolbar-title align="center">
            {{ specialReportData.subtitle }}
          </v-toolbar-title>
        </v-toolbar>
      </template>

      <template
        v-for="header in specialReportHeaders"
        v-slot:[`header.${header.key}`]="{ column }"
        :key="header.key"
      >
        <div class="header-with-image">
          <img :src="column.img" alt="icon" class="mb-3" />
          <span :class="smallText(header.key)">{{ column.title }}</span>
        </div>
      </template>

      <template v-slot:[`item.notcompletedvalue`]="{ item }">
        <span
          :class="{
            'actions-red': item.notcompletedvalue <= 0,
            'actions-green': item.notcompletedvalue >= 0.1,
          }"
        >
          {{ item.notcompletedvalue?.toFixed(2) }}%
        </span>
      </template>

      <template v-slot:[`item.date1`]="{ item }">
        <span class="center-text"> {{ item.reportvaluesList[0] }}</span>
      </template>
      <template v-slot:[`item.date2`]="{ item }">
        <span class="center-text"> {{ item.reportvaluesList[1] }}</span>
      </template>
      <template v-slot:[`item.date3`]="{ item }">
        <span class="center-text"> {{ item.reportvaluesList[2] }}</span>
      </template>
      <template v-slot:[`item.date4`]="{ item }">
        <span class="center-text">{{ item.reportvaluesList[3] }}</span>
      </template>
      <template v-slot:[`item.date5`]="{ item }">
        <span class="center-text">{{ item.reportvaluesList[4] }}</span>
      </template>
      <template v-slot:[`item.date6`]="{ item }">
        <span class="center-text"> {{ item.reportvaluesList[5] }}</span>
      </template>

      <template v-slot:[`item.note`]="{ item }">
        <span v-if="item.note && item.note.length < 20">
          {{ item.note }}
        </span>

        <div v-else-if="item.note" class="note">
          <span class="notes-size">{{ item.note }}</span>

          <div class="text-end">
            <img
              src="@/assets/bg/plus.png"
              @click="openDialog"
              style="cursor: pointer"
            />
          </div>

          <v-dialog
            v-model="isOpen"
            :scrim="false"
            absolute
            class="custom-dialog"
          >
            <v-container class="note-container">
              <div class="d-flex">
                <img src="@/assets/bg/note1.png" />
                <v-card-title class="mr-auto">Примечание:</v-card-title>
                <img
                  src="@/assets/icons/minus.svg"
                  @click="closeDialog"
                  style="cursor: pointer"
                />
              </div>
              <v-card-text class="note-text">
                {{ item.note }}
              </v-card-text>
            </v-container>
          </v-dialog>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="item-name-cell">
          <span>{{ item.name }}</span>
          <img
            @click="toggleExpand(item)"
            :src="expandIcon(item)"
            alt="icon"
            class="expand-table-btn"
            v-if="item.children && item.children.length > 0"
          />
        </div>
      </template>

      <template v-slot:[`item.startdate`]="{ item }">
        <span>
          {{ formatDate(item.startdate.seconds) }}
        </span>
      </template>

      <template v-slot:[`item.enddate`]="{ item }">
        <span>
          {{ formatDate(item.enddate.seconds) }}
        </span>
      </template>
    </v-data-table>

    <v-col align="end" class="pa-0 pt-5">
      <v-btn
        @click="exportToExcel"
        rounded="lg"
        width="250"
        style="font-size: 0.8vw"
      >
        ЭКСПОРТ</v-btn
      >
    </v-col>
  </v-container>
</template>

<style scoped>
.header-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.height-span {
  height: 55px;
  font-size: 0.75vw;
  line-height: 1.2;
}
.height-span-small {
  height: 55px;
  font-size: 0.55vw;
  line-height: 1.2;
}
.custom-data-table {
  background: url("@/assets/bg/background.png") no-repeat center center;
  background-size: cover;
}
.custom-data-table :deep(::-webkit-scrollbar-track) {
  margin: 0;
}
.v-data-table :deep(table) {
  border: 1px solid #006aa6;
}
:deep(tbody .v-data-table__td) {
  border: 1px solid #006aa6;
  border-bottom: 1px solid #006aa6 !important;
  padding: 8px 2px !important;
}
.expand-table {
  background-color: #353b3f;
  border: 1px solid #006aa6;
  padding: 8px 2px !important;
  text-align: center;
}
:deep(thead .v-data-table__th) {
  border: 1px solid #006aa6;
  border-bottom: 1px solid #006aa6 !important;
}
:deep(.v-data-table thead th) {
  background: linear-gradient(
    180deg,
    rgba(0, 106, 166, 1) 0%,
    rgba(0, 41, 64, 1) 100%
  ) !important;
  padding: 10px 10px !important;
}
.title {
  color: white;
  background: linear-gradient(#006aa6 0%, #002940);
  font-size: 1.15vw;
}
.autocomplete :deep(input::placeholder) {
  color: #00507d;
  opacity: 1;
}
.autocomplete .v-input__control {
  border: none !important;
  box-shadow: none !important;
}
.actions-red {
  color: red;
}
.actions-green {
  color: #1bc200;
}
.notes-size {
  font-size: 9px;
  line-height: 1;
}
.note {
  background: #444444;
  border-radius: 5px;
  line-height: 1.2;
  text-align: left;
}
.note-container {
  background: #001825;
  box-shadow: 0px 0px 5px 2px white;
  border-radius: 7px;
}
.note-text {
  background: white;
  color: #006aa6;
  border-radius: 7px;
  height: 15vh;
  margin-top: 2em;
}
.custom-dialog {
  max-width: 30em;
  top: 25em;
  left: 35em;
}
.item-name-cell {
  position: relative;
  padding: 10px;
}
.expand-table-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.center-text {
  text-align: center;
}
/* TODO */
.autocomplete-wrapper :deep(.v-list) {
  background-color: white !important;
}
</style>
