<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: [String, Number],
    required: true,
    default: 0,
  },
  title: {
    type: String,
    required: true,
  },
  titleDimension: {
    type: String,
    default: "16px",
  },
  data: {
    type: Object,
    required: false,
    default: () => ({}), // Default to an empty object if no data is provided
  },
});

function goToDetails(id) {
  router.push({ name: "PropertyDetails", params: { id } });
}

const generateItemImage = (multiply, itemSrc) => {
  const base = 50;
  return itemSrc + `/S-${base * multiply}`;
};

function getDynamicValue(item, iconType) {
  if (iconType === "3") {
    return item?.budgetdynamics?.value;
  } else if (iconType === "2") {
    return item?.qualitydynamics?.value;
  } else {
    return item?.executiondynamics?.value;
  }
}
</script>

<template>
  <v-card
    :loading="loading"
    class="param-card pa-0 mx-auto transition-swing card_border"
    color="card_background"
    max-width="100%"
  >
    <!-- Title Section -->
    <v-card-item class="title-section">
      <v-row class="pa-0 no-gutters" align="center" justify="start">
        <v-col cols="auto" class="fill-height d-flex align-center pr-1">
          <v-img
            v-if="icon == 1"
            src="@/assets/icons/worker.svg"
            contain
            width="34"
            height="34"
          />
          <v-img
            v-else-if="icon == 2"
            src="@/assets/icons/bricks.svg"
            contain
            width="34"
            height="34"
          />
          <v-img
            v-else-if="icon == 3"
            src="@/assets/icons/money.svg"
            contain
            width="34"
            height="34"
          />
          <v-img
            v-else
            src="@/assets/icons/success.svg"
            contain
            width="34"
            height="34"
          />
        </v-col>
        <v-col class="fill-height d-flex align-center pl-1">
          <div>
            {{ title }}
            <span class="text_dimension">{{ titleDimension }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-item>

    <!-- Scrollable List Section -->
    <v-card-item class="scrollable-list">
      <v-list class="pa-0 ma-0" bg-color="transparent" dense>
        <v-list-item
          v-for="(item, i) in props.data"
          class="ma-0 px-0 pb-1"
          :key="item.id"
          :value="item"
          rounded="10"
        >
          <template v-slot:prepend>
            <!-- Counter -->
            <v-avatar size="50" rounded="sm" class="my-auto mr-2 counter">
              <span class="counter_text">{{ i + 1 }}</span>
            </v-avatar>

            <!-- Avatar -->
            <v-avatar rounded="0" size="54" class="my-auto">
              <v-img
                :src="generateItemImage(3, item.imagesource)"
                cover
                rounded="0"
                width="50"
                height="50"
              />
            </v-avatar>
          </template>

          <!-- Content -->
          <v-row
            class="pa-0 no-gutters align-center"
            justify="start"
            @click="goToDetails(item.id)"
          >
            <v-col cols="10" sm="8">
              <div
                class="marquee-container"
                style="overflow: hidden; white-space: nowrap"
              >
                <div>
                  <v-list-item-title class="marquee pr-7">
                    {{ `${item.name}` }}
                  </v-list-item-title>
                  <v-list-item-title class="marquee pr-7">
                    {{ `${item.name}` }}
                  </v-list-item-title>
                </div>
              </div>
            </v-col>

            <v-col cols="2" sm="4" class="text-right pr-5">
              <v-list-item-title
                :class="{
                  'text-green': getDynamicValue(item, props.icon) < 10,
                  'text-yellow':
                    getDynamicValue(item, props.icon) >= 10 &&
                    getDynamicValue(item, props.icon) < 20,
                  'text-red': getDynamicValue(item, props.icon) >= 20,
                }"
                class="pr-1"
                >{{ getDynamicValue(item, props.icon) }}</v-list-item-title
              >
            </v-col>
          </v-row>

          <!-- Divider -->
          <v-divider
            class="border-opacity-100 pt-0"
            v-if="i < data.length - 1"
            inset
          ></v-divider>
        </v-list-item>
      </v-list>
    </v-card-item>
  </v-card>
</template>

<style scoped>
.param-card {
  box-shadow: 0px 0px 23px 1px #00a3ff80 inset;
  transition: box-shadow 0.3s ease;
  max-height: 260px;
}

.card_border {
  border: 1px solid #00a3ff;
}

.title-section {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--v-surface);
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.scrollable-list {
  overflow-y: auto;
  max-height: 200px; /* Adjust height as needed */
  padding: 8px;
}

.position-line {
  top: 0px;
  align-items: end;
}
/* .param-card {
  box-shadow: 0px 0px 23px 1px #00a3ff80 inset;
  transition: box-shadow 0.3s ease;
  overflow-y: scroll;
  max-height: 255px;
} */

.card_border {
  border: 1px solid #00a3ff;
}

.hovered-card {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Custom hover shadow */
  background-color: #f0f0f0; /* Custom hover background color */
}

/* Ensure the card fills 100% width */
.v-card {
  width: 100% !important;
}

:deep(.v-list-item-title) {
  /* font-family: Neo Sans Pro; */
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 21.6px;
  text-align: left;
}
.text_dimension {
  /* font-family: Neo Sans Pro; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}
.counter {
  margin-right: 1rem;
  background-color: #00466e;
  padding: 0px;
  height: 100%;
  width: 13px !important;
}
.counter_text {
  /* font-family: Neo Sans Pro; */
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: #00a3ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-green {
  color: #1bc200; /* Green */
  font-size: 1rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: right;
}

.text-yellow {
  color: #ffb800; /* Yellow */
  font-size: 1rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: right;
}

.text-red {
  color: #ff0000; /* Red */
  font-size: 1rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: right;
}

/*  */
.arrow-icon {
  margin-left: auto; /* Adjust positioning of the icon */
  margin-right: auto; /* Center the icon */
  display: block; /* Ensure block display for centering */
  color: currentColor; /* Inherit the text color */
}

/* Adjust the padding/margin for dividers */
.v-divider {
  margin: 0; /* Reset default margin */
}

.marquee-container {
  overflow: hidden; /* Ensure overflow is hidden */
  width: 100%; /* Fill the width of the parent */
}

.marquee {
  display: inline-block; /* Allow horizontal scrolling */
  animation: marquee 15s linear infinite; /* Adjust duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start position */
  }
  100% {
    transform: translateX(-100%); /* End of scroll */
  }
}
@media (max-height: 710px) {
  .param-card {
    max-height: 174px;
  }
  .title-section {
    padding: 5px;
  }
  .scrollable-list {
    max-height: 120px;
  }
  :deep(.v-list-item-title) {
    font-size: 0.8rem;
  }
}
@media (min-height: 710px) and (max-height: 760px) {
  .param-card {
    max-height: 183px;
  }
  .title-section {
    padding: 5px;
  }
  .scrollable-list {
    max-height: 145px;
  }
}
@media (min-height: 760px) and (max-height: 810px) {
  .param-card {
    max-height: 200px;
  }
  .title-section {
    padding: 5px;
  }
  .scrollable-list {
    max-height: 145px;
  }
}
@media (min-height: 810px) and (max-height: 860px) {
  .param-card {
    max-height: 225px;
  }
  .title-section {
    padding: 5px;
  }
  .scrollable-list {
    max-height: 170px;
  }
}
@media (min-height: 860px) and (max-height: 900px) {
  .param-card {
    max-height: 255px;
  }
  .title-section {
    padding: 5px;
  }
  .scrollable-list {
    max-height: 180px;
  }
}
</style>
