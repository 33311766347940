<script setup>
import { percentColor, progressBarColor } from "@/utils/bgColors.js";
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
  },
  percents: {
    type: Number,
  },
  size: {
    type: Number,
  },
  color: {
    type: Number,
    required: false,
    default: 1,
  },
});
const progressWidth = computed(() => {
  return props.percents > 0 ? `${props.percents}%` : "0%";
});
</script>

<template>
  <v-col class="pa-0 ma-0" md="2" sm="3" xs="4">
    <v-card-text
      :style="{ fontSize: `${props.size}vw` }"
      class="pa-0 ma-0"
      align="start"
      >{{ props.title }}</v-card-text
    >
  </v-col>
  <v-col class="pa-0 ma-0 pl-1" md="7" sm="6" xs="4">
    <div class="progress-bar">
      <div
        class="progress-fill"
        :style="{
          width: progressWidth,
          ...progressBarColor(props.color),
        }"
      ></div>
    </div>
  </v-col>
  <v-col class="pa-0 ma-0" md="3" sm="3" xs="4">
    <v-card-text
      class="pa-1"
      :style="{ fontSize: `${props.size}vw`, ...percentColor(props.color) }"
      >{{ props.percents?.toFixed(0) }}%</v-card-text
    >
  </v-col>
</template>

<style scoped>
.progress-bar {
  height: 10px;
  background-color: white;
  border-radius: 15px;
  border: 2px solid white;
}
.progress-fill {
  height: 100%;
  border-radius: 3px;
}
</style>
