const percentColor = (type) => {
  return type == 1
    ? { color: "#0093E5" }
    : type == 2
      ? { color: "#FFB800" }
      : type == 3
        ? { color: "#FF0000" }
        : { color: "#1BC200" };
};

const progressBarColor = (type) => {
  return type == 1
    ? { backgroundColor: "#0093E5" }
    : type == 2
      ? { backgroundColor: "#FFB800" }
      : type == 3
        ? { backgroundColor: "#FF0000" }
        : { backgroundColor: "#1BC200" };
};

export { percentColor, progressBarColor };
