<script setup>
import { useRouter, useRoute } from "vue-router";
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const route = useRoute();

let selectedId = ref();

const isHome = computed(() => route.path === "/home");
const isLocations = computed(() => route.path === "/locations");

const constructions = computed(() => {
  const data = store.getters["locations/constructions"] || [];
  return data;
});

const contractors = computed(() => {
  const data = store.getters["locations/constructions"] || [];
  return data.flatMap((item) => item.contractorsList || []); // Combine all contractors
});

const searchItems = computed(() => {
  const data_Cont = store.getters["locations/constructions"] || [];
  const contractors = data_Cont.flatMap((item) =>
    (item.contractorsList || []).map((contractor) => ({
      ...contractor,
      redirect: true,
    })),
  );
  const data_Const = store.getters["locations/constructions"] || [];
  return [...data_Const, ...contractors];
});

const redirectToHome = () => {
  router.push("/home");
};

const redirectProperties = () => {
  router.push("/locations");
};

const logOut = () => {
  router.push("/");
};

const goToDetails = (item) => {
  const id = item.id;
  if (id) {
    console.log("Navigating to PropertyDetails with ID:", id);
    router.push({ name: "PropertyDetails", params: { id } });
  } else {
    console.error("No ID selected!");
  }
};

const goToDetailsSearch = (item) => {
  if (!item) {
    console.error("No item selected!");
    return;
  }

  if (item.redirect) {
    // console.log("Redirect is true. Navigation skipped.");
    return;
  }
  if (item.id) {
    console.log("Navigating to PropertyDetails with ID:", item.id);
    router.push({ name: "PropertyDetails", params: { id: item.id } });
  } else {
    console.error("Item has no ID!");
  }
};
</script>

<template>
  <v-app-bar
    app
    dark
    image="@/assets/bg/interface.png"
    height="124"
    position="fixed"
  >
    <v-img
      @click="redirectToHome"
      class="cursor-pointer"
      contain
      height="76"
      lazy-src="@/assets/bg/bg-1.png"
      src="@/assets/logo/asic-ins.png"
      max-width="550"
    ></v-img>
    <v-row align="center" class="mr-1">
      <v-col v-if="isLocations" cols="1" class="pl-10">
        <v-img
          src="@/assets/icons/arrow_left.svg"
          contain
          width="45"
          height="45"
          @click="redirectToHome"
          class="cursor-pointer"
        />
      </v-col>
      <v-col class="pl-0 pr-0">
        <v-select
          v-model="selectedId"
          bg-color="white"
          label="Все"
          :items="constructions"
          item-title="name"
          item-value="id"
          variant="solo"
          density="compact"
          rounded="lg"
          class="custom-select"
          menu-icon="mdi-chevron-down"
          return-object
          :menu-props="{ maxWidth: '300px' }"
          @update:modelValue="goToDetails"
        >
          <template v-slot:no-data>
            <div class="text-center">
              <p>Не найдено ни одного элемента</p>
            </div>
          </template>
          <template v-slot:prepend>
            <v-img
              src="@/assets/icons/crane.svg"
              contain
              width="45"
              height="45"
              class="icon-position"
            ></v-img>
          </template>
        </v-select>
      </v-col>
      <v-col class="pl-0 pr-0">
        <v-select
          bg-color="white"
          label="Все"
          :items="contractors"
          item-title="name"
          item-value="id"
          variant="solo"
          density="compact"
          rounded="lg"
          class="custom-select"
          menu-icon="mdi-chevron-down"
        >
          <template v-slot:no-data>
            <div class="text-center">
              <p>Не найдено ни одного элемента</p>
            </div>
          </template>
          <template v-slot:prepend>
            <v-img
              src="@/assets/icons/hat.svg"
              contain
              width="55"
              height="55"
              class="icon-position"
            ></v-img>
          </template>
        </v-select>
      </v-col>
      <v-col md="3" lg="4" class="pl-10 pr-10">
        <v-autocomplete
          class="search-bar"
          label="Поиск"
          :items="searchItems"
          item-title="name"
          return-object
          hide-details
          hide-hint
          variant="solo"
          color="primary"
          bg-color="transparent"
          density="compact"
          :menu-props="{ maxWidth: '300px' }"
          @update:modelValue="goToDetailsSearch"
        >
          <template v-slot:no-data>
            <div class="text-center">
              <p>Не найдено ни одного элемента</p>
            </div>
          </template>
          <template v-slot:append-inner>
            <v-icon color="#00A3FF" size="large">mdi-magnify</v-icon>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col v-if="isHome" cols="1">
        <v-img
          src="@/assets/icons/arrow_right.svg"
          contain
          width="45"
          height="45"
          @click="redirectProperties"
          class="cursor-pointer"
        />
      </v-col>
      <v-col cols="2">
        <v-img
          contain
          height="1.5vw"
          width="1.5vw"
          src="@/assets/icons/logout.svg"
          class="img-logout cursor-pointer"
          @click="logOut"
        ></v-img>

        <v-img
          contain
          height="7vw"
          width="9vw"
          src="@/assets/logo/logo-asik.svg"
        ></v-img>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<style scoped>
.img {
  position: absolute;
  top: 2em;
  z-index: 1;
}
.img-logout {
  position: absolute;
  top: 1em;
  right: 1em;
}
.cursor-pointer {
  cursor: pointer;
}
.icon-position {
  left: 2.4em;
  bottom: 0.2em;
  z-index: 10;
}
.search-bar {
  border: 2px solid #00a3ff;
  border-radius: 10px;
}
:deep(.v-input__control),
:deep(.v-input) {
  height: 45px;
}
</style>

<style>
.custom-select .v-input__control {
  border: 2px solid #00a3ff !important;
  border-radius: 10px;
}
.custom-select .v-field-label {
  color: #00507d;
  font-weight: bold;
  padding-left: 10px;
}
</style>
