<template>
  <v-responsive
    class="align-center fill-height mx-auto"
  >
    <v-row align="center" justify="center" no-gutters>
        <v-img
          class="mx-auto"
          width="100%"
          height="100vh"
          lazy-src="@/assets/bg/bg-1.png"
          src="@/assets/bg/bg-1.png"
          aspect-ratio="1/1"
          cover
        >
          <v-container
            class="d-flex align-center justify-center fill-height"
          >
              <router-view v-slot="{ Component }">
                <v-fade-transition duration="500" mode="out-in">
                  <component :is="Component"></component>
                </v-fade-transition>
              </router-view>
          </v-container>
        </v-img>
    </v-row>
  </v-responsive>
</template>


<script>
import { defineComponent } from 'vue'

export default defineComponent({
name: 'ExternalView',

components: {
}
})
</script>

<style scoped>

</style>
