<script setup>
import { ref, onMounted, computed } from "vue";
import { formatDate } from "@/utils/formatDate";
import deadlineChartTable from "@/components/Charts/deadlineChartTable.js";
import ProgressBar from "@/components/data/ProgressBar.vue";
import LineChart from "@/components/Charts/LineChart.vue";
import ChartTable from "@/components/ChartTable.vue";
import * as XLSX from "xlsx";
const { chartOptions } = deadlineChartTable;

const props = defineProps({
  construction: {
    type: Object,
    required: true,
  },
});

const isLoading = ref(true);
const allContractors = ref([]);
const contractor = ref({});
// const contractorChart = ref({});
const currentIndex = ref(0);
const labels = computed(() =>
  contractor.value.chartList
    ? contractor.value.chartList.map((e) => formatDate(e.date.seconds))
    : [],
);
const plan = computed(() =>
  contractor.value.chartList
    ? contractor.value.chartList.map((e) => e.plan)
    : [],
);
const fact = computed(() =>
  contractor.value.chartList
    ? contractor.value.chartList.map((e) => e.fact)
    : [],
);
const deviation = computed(() =>
  contractor.value.chartList
    ? contractor.value.chartList.map((e) => e.deviation)
    : [],
);

const chartData = computed(() => ({
  labels: [...new Set(labels.value)],
  datasets: [
    {
      label: "Plan",
      data: plan.value,
      borderColor: "#0093E5",
      backgroundColor: "#0093E5",
      pointBorderColor: "#0093E5",
      pointBackgroundColor: "#0093E5",
      fill: false,
      tension: 0.1,
    },
    {
      label: "Data",
      data: fact.value,
      borderColor: "#FFB800",
      backgroundColor: "#FFB800",
      pointBorderColor: "#FFB800",
      pointBackgroundColor: "#FFB800",
      fill: false,
      tension: 0.1,
    },
    {
      label: "Deviation",
      data: deviation.value,
      borderColor: "#FF0000",
      backgroundColor: "#FF0000",
      pointBorderColor: "#FF0000",
      pointBackgroundColor: "#FF0000",
      fill: false,
      tension: 0.1,
    },
  ],
}));

const showNextContractor = () => {
  currentIndex.value = (currentIndex.value + 1) % allContractors.value.length;
  contractor.value = allContractors.value[currentIndex.value];
  // contractorChart.value = sortData(allContractors.value[currentIndex.value].chartList);
};

const showPreviousContractor = () => {
  currentIndex.value =
    (currentIndex.value - 1 + allContractors.value.length) %
    allContractors.value.length;
  contractor.value = allContractors.value[currentIndex.value];
  // contractorChart.value = sortData(allContractors.value[currentIndex.value].chartList);
};

const {
  ValueRequest_String,
} = require("../../../proto-out/IMultiStatisticsService-v5_pb");

const {
  MultiStatisticsServiceClient,
} = require("../../../proto-out/IMultiStatisticsService-v5_grpc_web_pb");

const store = useStore();
import { useStore } from "vuex";

let authToken = computed(() => store.getters["users/tokenUser"]);
let serverUrl = computed(() => store.getters["appData/getServerUrl"]);

const fetchDataFromGrpc = async () => {
  try {
    const client = new MultiStatisticsServiceClient(serverUrl.value);
    const metadata = { Authorization: `Bearer ${authToken.value}` };
    const request = new ValueRequest_String();
    request.setValue(props.construction.actualinstance);

    const getContractorsDynamicsData = await new Promise((resolve, reject) => {
      client.getContractorsDynamics(request, metadata, (err, response) => {
        if (err) {
          console.error("gRPC Error:", err.message);
          reject(new Error("Failed to fetch data from gRPC"));
        } else {
          resolve(response.toObject());
        }
      });
    });

    allContractors.value = getContractorsDynamicsData.valuesList;
    contractor.value = getContractorsDynamicsData.valuesList[0];
    // contractorChart.value = sortData(getContractorsDynamicsData.valuesList[0].chartList);
    isLoading.value = false;
  } catch (error) {
    console.error(error);
    // throw error;
  }
  isLoading.value = false;
};
onMounted(() => {
  fetchDataFromGrpc();
});

// Sort logic
// const sortData = (tabledata) => {
//   console.log("SORTING", tabledata);
//   return tabledata.map((item) => {
//     if (item.date && item.date.seconds) {
//       item.computedDate = new Date(item.date.seconds * 1000);
//       item.formattedDate = formatDate(item.date.seconds);
//     } else {
//       item.computedDate = null;
//       item.formattedDate = "N/A";
//     }
//     return item;
//   });
// };

const exportChartDataToExcel = () => {
  if (!contractor.value.chartList || contractor.value.chartList.length === 0) {
    console.error("No chart data available for export.");
    return;
  }

  // Define headers
  const headersTitle = ["", "Подрядчик", contractor.value.contractorname, ""];

  // Define headers
  const headers = ["Date", "Plan", "Fact", "Deviation"];

  // Prepare rows with data transformation
  const rows = contractor.value.chartList.map((item) => {
    return [
      item.date && item.date.seconds
        ? new Date(item.date.seconds * 1000).toLocaleDateString("en-GB")
        : "",
      item.plan ?? "",
      item.fact ?? "",
      item.deviation ?? "",
    ];
  });

  // Combine headers and data rows
  const worksheetData = [headersTitle, headers, ...rows];

  // Create worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Style the headers
  headers.forEach((_, colIdx) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIdx });
    if (!worksheet[cellAddress]) return;
    worksheet[cellAddress].s = {
      font: { bold: true },
      alignment: { horizontal: "center" },
      fill: { fgColor: { rgb: "D9EAD3" } },
    };
  });

  // Set column widths
  worksheet["!cols"] = headers.map(() => ({ wpx: 150 }));

  // Create a workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Отчет");

  // Generate filename and save
  const date = new Date();
  const dateString = date.toLocaleDateString("en-GB").replace(/\//g, "_");
  const fileName = `${dateString}_мултикейс_${contractor.value.contractorname}.xlsx`;
  XLSX.writeFile(workbook, fileName);
};
</script>

<template>
  <v-row class="wrapper align-center justify-center" style="gap: 12px">
    <img
      src="@/assets/icons/left_line.svg"
      @click="showPreviousContractor"
      style="cursor: pointer"
    />

    <div class="custom-btn">
      <v-row class="align-center justify-space-between pa-1">
        <v-col cols="10">
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="white"
          ></v-progress-linear>
          <span v-else class="label-text">
            {{ contractor?.contractorname }}
          </span>
        </v-col>
        <v-col cols="2" class="pa-0 ma-0">
          <span class="mr-1" style="font-size: 0.9vw">
            {{ contractor?.currentdynamics?.value }} дн.</span
          >
          <img
            v-if="buildingDynamicsDirection"
            src="@/assets/icons/arrow.svg"
            height="20"
          />
          <img v-else src="@/assets/icons/red_arrow.svg" height="20" />
        </v-col>
      </v-row>
    </div>

    <img
      src="@/assets/icons/right_line.svg"
      @click="showNextContractor"
      style="cursor: pointer"
    />
  </v-row>

  <v-container class="d-flex mb-2">
    <v-row align="center" class="ma-0">
      <ProgressBar
        title="ВРЕМЯ"
        :percents="contractor?.currentexecution?.plan || 0"
        :size="0.8"
        :color="1"
      />
    </v-row>
    <v-row align="center" class="ma-0">
      <ProgressBar
        title="СДАНО"
        :percents="contractor?.currentexecution?.fact || 0"
        :size="0.8"
        :color="2"
      />
    </v-row>
    <v-row align="center" class="ma-0">
      <ProgressBar
        title="ОТКЛОНЕНИЕ"
        :percents="contractor?.currentexecution?.deviation || 0"
        :size="0.8"
        :color="3"
      />
    </v-row>
  </v-container>

  <LineChart
    height="37"
    class="chart"
    :chartData="chartData"
    :chartOptions="chartOptions"
    :isLoading="isLoading"
  />
  <v-container fluid class="pb-8">
    <v-row>
      <v-col cols="2" style="padding-left: 40px; padding-top: 50px">
        <img src="@/assets/icons/Corps.svg" height="70vw" />
        <v-card-text style="color: #00a3ff; font-size: 0.9vw" class="pa-0">
          Корпус 1
        </v-card-text>
      </v-col>
      <v-col cols="1">
        <v-container class="vertical-line-container">
          <div class="glowing-line"></div>
        </v-container>
      </v-col>
      <v-col cols="6">
        <v-row class="mt-5">
          <ChartTable :data="contractor" :isLoading="isLoading" />
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-container class="vertical-line-container">
          <div class="glowing-line"></div>
        </v-container>
      </v-col>
      <v-col cols="2">
        <v-row align="center" class="mt-5">
          <v-col style="max-width: 180px" class="pa-0">
            <v-col class="pb-0">
              <v-icon size="10" color="#0093E5">mdi-circle</v-icon>
              <span class="legend-text ml-1">План</span>
            </v-col>
            <v-col>
              <v-icon size="10" color="#FFB800">mdi-circle</v-icon>
              <span class="legend-text ml-1">Факт</span>
            </v-col>
            <v-col align="end" class="ml-0">
              <v-btn
                @click="exportChartDataToExcel"
                rounded="lg"
                height="3vw"
                style="font-size: 0.8vw"
              >
                ЭКСПОРТ</v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.wrapper {
  width: 100%;
  padding-bottom: 1em;
  margin: 0 auto;
}
.custom-btn {
  background-color: #00a3ff;
  color: white;
  padding: 6px;
  border-radius: 10px;
  width: 50%;
  text-align: center;
  min-height: 40px;
}
.vertical-line-container {
  display: flex;
  width: 100%;
  height: 90%;
  margin-top: 20px;
}
.glowing-line {
  width: 3px;
  background: radial-gradient(
    closest-side,
    #00a3ff,
    white,
    #6ebfef,
    transparent
  );
}
.label-text {
  font-weight: bold;
  font-size: 0.9vw;
}
.legend-text {
  font-size: 0.8vw;
}
@media (max-height: 810px) {
  .wrapper {
    padding-bottom: 15px;
  }
  .chart {
    height: 35vh !important;
  }
  .v-container {
    padding-top: 0;
  }
}
</style>
