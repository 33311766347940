const state = {
  token: null
}

const getters = {
  isAuthenticated: state => !!state.token,
  tokenUser: state => state.token
}

const actions = {
  async token_login ({ commit }, token) {
    // console.log("stting token", token);
    await commit('setToken', token)
  },
  // eslint-disable-next-line no-empty-pattern
  async logOut ({ commit }) {
    const user = null
    commit('logout', user)
  }
}

const mutations = {
  setToken (state, token) {
    state.token = token
  },  
  logout (state, user) {
    state.token = user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
