const state = {
  currentIndex: 0,
};

const getters = {
  getCurrentIndex: (state) => state.currentIndex,
};

const actions = {
  updateCurrentIndex({ commit }, value) {
    commit("setCurrentIndex", value);
  },
};

const mutations = {
  setCurrentIndex(state, value) {
    state.currentIndex = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
