import { createStore } from "vuex";
import locations from "./modules/locations";
import users from "./modules/users";
import properties from "./modules/properties";
import productivity from "./modules/productivity";
import clickedButton from "./modules/clickedButton";
import currentIndex from "./modules/currentIndex";
import appData from "./modules/appData";

const store = createStore({
  modules: {
    appData,
    locations,
    users,
    properties,
    clickedButton,
    currentIndex,
    productivity,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});

export default store;
