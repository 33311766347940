// source: protos/IMultiConstructionService-v4.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.repeatedFields_ = [12,16,17,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, 0),
actualinstance: jspb.Message.getFieldWithDefault(msg, 2, ""),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
address: jspb.Message.getFieldWithDefault(msg, 4, ""),
city: jspb.Message.getFieldWithDefault(msg, 5, ""),
imagesource: jspb.Message.getFieldWithDefault(msg, 6, ""),
latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
isgprapproved: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
isfactadded: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
constructionplanfact: (f = msg.getConstructionplanfact()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.toObject(includeInstance, f),
sectionsplanfactList: jspb.Message.toObjectList(msg.getSectionsplanfactList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.toObject, includeInstance),
executiondynamics: (f = msg.getExecutiondynamics()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject(includeInstance, f),
qualitydynamics: (f = msg.getQualitydynamics()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject(includeInstance, f),
budgetdynamics: (f = msg.getBudgetdynamics()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject(includeInstance, f),
sectionsdeviationList: jspb.Message.toObjectList(msg.getSectionsdeviationList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject, includeInstance),
contractorsdeviationList: jspb.Message.toObjectList(msg.getContractorsdeviationList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject, includeInstance),
cameraurlsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
contractorsList: jspb.Message.toObjectList(msg.getContractorsList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.toObject, includeInstance),
qualityplanfact: (f = msg.getQualityplanfact()) && proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActualinstance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImagesource(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsgprapproved(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfactadded(value);
      break;
    case 11:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.deserializeBinaryFromReader);
      msg.setConstructionplanfact(value);
      break;
    case 12:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.deserializeBinaryFromReader);
      msg.addSectionsplanfact(value);
      break;
    case 13:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader);
      msg.setExecutiondynamics(value);
      break;
    case 14:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader);
      msg.setQualitydynamics(value);
      break;
    case 15:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader);
      msg.setBudgetdynamics(value);
      break;
    case 16:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader);
      msg.addSectionsdeviation(value);
      break;
    case 17:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader);
      msg.addContractorsdeviation(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addCameraurls(value);
      break;
    case 19:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.deserializeBinaryFromReader);
      msg.addContractors(value);
      break;
    case 20:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.deserializeBinaryFromReader);
      msg.setQualityplanfact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActualinstance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImagesource();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getIsgprapproved();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsfactadded();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getConstructionplanfact();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.serializeBinaryToWriter
    );
  }
  f = message.getSectionsplanfactList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.serializeBinaryToWriter
    );
  }
  f = message.getExecutiondynamics();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getQualitydynamics();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getBudgetdynamics();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getSectionsdeviationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getContractorsdeviationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter
    );
  }
  f = message.getCameraurlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getContractorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.serializeBinaryToWriter
    );
  }
  f = message.getQualityplanfact();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ActualInstance = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getActualinstance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setActualinstance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Address = 4;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string City = 5;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ImageSource = 6;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getImagesource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setImagesource = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double Latitude = 7;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double Longitude = 8;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool IsGprApproved = 9;
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getIsgprapproved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setIsgprapproved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool IsFactAdded = 10;
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getIsfactadded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setIsfactadded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional PlanFactResult ConstructionPlanFact = 11;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getConstructionplanfact = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult, 11));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setConstructionplanfact = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearConstructionplanfact = function() {
  return this.setConstructionplanfact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.hasConstructionplanfact = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated PlanFactResult SectionsPlanFact = 12;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getSectionsplanfactList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult, 12));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setSectionsplanfactList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.addSectionsplanfact = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearSectionsplanfactList = function() {
  return this.setSectionsplanfactList([]);
};


/**
 * optional DeviationDynamicsResult ExecutionDynamics = 13;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getExecutiondynamics = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, 13));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setExecutiondynamics = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearExecutiondynamics = function() {
  return this.setExecutiondynamics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.hasExecutiondynamics = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DeviationDynamicsResult QualityDynamics = 14;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getQualitydynamics = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, 14));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setQualitydynamics = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearQualitydynamics = function() {
  return this.setQualitydynamics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.hasQualitydynamics = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional DeviationDynamicsResult BudgetDynamics = 15;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getBudgetdynamics = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, 15));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setBudgetdynamics = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearBudgetdynamics = function() {
  return this.setBudgetdynamics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.hasBudgetdynamics = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated DeviationDynamicsResult SectionsDeviation = 16;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getSectionsdeviationList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, 16));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setSectionsdeviationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.addSectionsdeviation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearSectionsdeviationList = function() {
  return this.setSectionsdeviationList([]);
};


/**
 * repeated DeviationDynamicsResult ContractorsDeviation = 17;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getContractorsdeviationList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, 17));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setContractorsdeviationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.addContractorsdeviation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearContractorsdeviationList = function() {
  return this.setContractorsdeviationList([]);
};


/**
 * repeated string CameraURLs = 18;
 * @return {!Array<string>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getCameraurlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setCameraurlsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.addCameraurls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearCameraurlsList = function() {
  return this.setCameraurlsList([]);
};


/**
 * repeated NameResult Contractors = 19;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getContractorsList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult, 19));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setContractorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.addContractors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearContractorsList = function() {
  return this.setContractorsList([]);
};


/**
 * optional PlanFactResult QualityPlanFact = 20;
 * @return {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.getQualityplanfact = function() {
  return /** @type{?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} */ (
    jspb.Message.getWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult, 20));
};


/**
 * @param {?proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.setQualityplanfact = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.clearQualityplanfact = function() {
  return this.setQualityplanfact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.prototype.hasQualityplanfact = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.toObject = function(includeInstance, msg) {
  var f, obj = {
itemid: jspb.Message.getFieldWithDefault(msg, 1, 0),
value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
direction: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDirection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDirection();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 ItemId = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.getItemid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.setItemid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double Value = 2;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool Direction = 3;
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.getDirection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DeviationDynamicsResult.prototype.setDirection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.toObject = function(includeInstance, msg) {
  var f, obj = {
valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.toObject, includeInstance),
error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated DetailedConstructionResult Values = 1;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult, 1));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.DetailedConstructionResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string Error = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DetailedConstructionResult.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, 0),
name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.NameResult.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.toObject = function(includeInstance, msg) {
  var f, obj = {
name: jspb.Message.getFieldWithDefault(msg, 1, ""),
startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
planpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
factpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
deviationpercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlanpercentage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFactpercentage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeviationpercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPlanpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getFactpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDeviationpercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string Name = 1;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp StartDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double PlanPercentage = 3;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.getPlanpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.setPlanpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double FactPercentage = 4;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.getFactpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.setFactpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double DeviationPercentage = 5;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.getDeviationpercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.PlanFactResult.prototype.setDeviationpercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 Value = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_Int32.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer);
