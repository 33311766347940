// source: IMultiReportService-v2.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SaveResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String', null, global);
goog.exportSymbol('proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.repeatedFields_, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.displayName = 'proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    constructioninstance: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConstructioninstance(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConstructioninstance();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ConstructionInstance = 1;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.getConstructioninstance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.setConstructioninstance = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp Date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ConstructionDateRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated google.protobuf.Timestamp Values = 1;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {!Array<!proto.google.protobuf.Timestamp>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional string Error = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ListResult_DateTime.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    istechnology: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    num: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contractingorganizationname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    locationid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    location: jspb.Message.getFieldWithDefault(msg, 7, ""),
    measure: jspb.Message.getFieldWithDefault(msg, 8, ""),
    volume: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    reportvaluesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f,
    notcompletedvalue: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    note: jspb.Message.getFieldWithDefault(msg, 12, ""),
    startdate: (f = msg.getStartdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIstechnology(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractingorganizationname(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMeasure(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReportvalues(values[i]);
      }
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNotcompletedvalue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIstechnology();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNum();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContractingorganizationname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLocationid();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMeasure();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getReportvaluesList();
  if (f.length > 0) {
    writer.writeRepeatedDouble(
      10,
      f
    );
  }
  f = message.getNotcompletedvalue();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool IsTechnology = 2;
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getIstechnology = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setIstechnology = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string Num = 3;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getNum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setNum = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Name = 4;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ContractingOrganizationName = 5;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getContractingorganizationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setContractingorganizationname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 LocationId = 6;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getLocationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setLocationid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string Location = 7;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Measure = 8;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getMeasure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setMeasure = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional double Volume = 9;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setVolume = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated double ReportValues = 10;
 * @return {!Array<number>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getReportvaluesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setReportvaluesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.addReportvalues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.clearReportvaluesList = function() {
  return this.setReportvaluesList([]);
};


/**
 * optional double NotCompletedValue = 11;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getNotcompletedvalue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setNotcompletedvalue = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional string Note = 12;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp StartDate = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getStartdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setStartdate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.clearStartdate = function() {
  return this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp EndDate = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.getEnddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.setEnddate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.clearEnddate = function() {
  return this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    constructionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reportdatesList: jspb.Message.toObjectList(msg.getReportdatesList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.toObject, includeInstance),
    editedbyusersList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConstructionid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addReportdates(value);
      break;
    case 6:
      var value = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem;
      reader.readMessage(value,proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addEditedbyusers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConstructionid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReportdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem.serializeBinaryToWriter
    );
  }
  f = message.getEditedbyusersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional int32 ConstructionId = 1;
 * @return {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.getConstructionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.setConstructionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp Date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.hasDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string Title = 3;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string SubTitle = 4;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated google.protobuf.Timestamp ReportDates = 5;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.getReportdatesList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.Timestamp>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.setReportdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.addReportdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.clearReportdatesList = function() {
  return this.setReportdatesList([]);
};


/**
 * repeated SpecialReportItem Items = 6;
 * @return {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem, 6));
};


/**
 * @param {!Array<!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
*/
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated string EditedByUsers = 7;
 * @return {!Array<string>}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.getEditedbyusersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.setEditedbyusersList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.addEditedbyusers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SpecialReportResult.prototype.clearEditedbyusersList = function() {
  return this.setEditedbyusersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Value = 1;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueRequest_String.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    error: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult;
  return proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SaveResult} */ (reader.readEnum());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional SaveResult Value = 1;
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SaveResult}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.prototype.getValue = function() {
  return /** @type {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SaveResult} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SaveResult} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string Error = 2;
 * @return {string}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult} returns this
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.ValueResult_SaveResult.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.ASIC.Contracts.ServiceContracts.MulticaseLayer.SaveResult = {
  FAILURE: 0,
  SUCCESS: 1
};

goog.object.extend(exports, proto.ASIC.Contracts.ServiceContracts.MulticaseLayer);
